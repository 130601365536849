import Panzoom from '@panzoom/panzoom'
import useScroll from '@/composable/useScroll'
import { ref } from 'vue'

export default function usePanzoom() {
    const instance = ref({})
    const { getRef, resetScroll } = useScroll()

    const createInstance = (element) => {
        const panzoom = Panzoom(element, {
            maxScale: 5,
            canvas: true,
            overflow: "scroll",
            // origin:'50% 50%',
            focal:{x: 1200, y: 1300},
            cursor: 'default'
            // disablePan: true
            // setTransform: (element, event) => {
            //     console.log(event)
            //     panzoom.setStyle('transform', `scale(${event.scale}) translate(0, 0)`)
            // }
        })
        const parent = element.parentElement

        // parent.addEventListener('wheel', panzoom.zoomWithWheel)

        // parent.addEventListener('wheel', function(event) {
        //     if (!event.altKey) return
        //     panzoom.zoomWithWheel(event)
        //   })

        element.parentElement.addEventListener('wheel', function (event) {
            if (!event.altKey) return
            // Panzoom will automatically use `deltaX` here instead
            // of `deltaY`. On a mac, the shift modifier usually
            // translates to horizontal scrolling, but Panzoom assumes
            // the desired behavior is zooming.
            panzoom.zoomWithWheel(event)
        })

        instance.value = panzoom
    }



    const resetZoom = () => {
        instance.value.reset()
        resetScroll()
        const target = document.getElementById('xxx-1')
        target.scrollTo(-125,-120)
    }


    return {
        instance,
        resetZoom,
        createInstance,
        getRef
    }
}