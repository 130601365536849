<template>
    <div class="relative">
        <slot name="trigger" :colorCode="localColorCode" :openColorPanel="openColorPanel">
            <div class="w-10 h-5 cursor-pointer" @click="openColorPanel" :style="{backgroundColor: localColorCode}"></div>
        </slot>
        <input
            class="invisible absolute -top-1"
            v-model="localColorCode"
            @change="$emit('change')"
            ref="colorPicker"
            type="color"
        >
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    const colorPicker = ref(null);
    const props = defineProps({
        value: {
            type: String
        }
    });
    const emit = defineEmits(['change', 'input']);
    const localColorCode = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value)
        }
    });
    const openColorPanel = () => {
        colorPicker.value.click();
    }
</script>