<template>
    <div style="width: 680px">
        <header class="px-6 flex gap-8 items-center border-b border-secondary-four bg-primary-three text-secondary-two" style="height: 50px">
            <div class="uppercase fs-14 font-semibold relative cursor-pointer text-primary-one">Create {{ title }}</div>
        </header>
        <main class="p-6">
            <div>
                <h4 class="font-poppins text-primary-one fs-14">Task name</h4>
                <input type="text" placeholder="Task Title" class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 mt-2 border border-secondary-five rounded" v-model="form.title">
            </div>
            <div class="mt-5">
                <h4 class="font-poppins text-secondary-one fs-14">Note</h4>
                <textarea placeholder="Task description" class="font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 pt-4 h-20 mt-2 border border-secondary-five rounded" v-model="form.description"></textarea>
            </div>
            <div class="flex mt-5 gap-x-3">
                <div class="w-full">
                    <h4 class="font-poppins text-primary-one fs-14 font-normal">Start Date</h4>
                    <Datepicker
                        input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                        placeholder="dd-mm-yyyy"
                        format="dd-MM-yyyy"
                        v-model="timeline.startDate"
                        @input="value => form.start_date = $lodash.format(value, 'yyyy-MM-dd')"
                    />
                </div>
                <div class="w-full">
                    <h4 class="font-poppins text-primary-one fs-14 font-normal">End Date</h4>
                    <Datepicker
                        input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                        placeholder="dd-mm-yyyy"
                        format="dd-MM-yyyy"
                        v-model="timeline.endDate"
                        @input="value => form.end_date = $lodash.format(value, 'yyyy-MM-dd')"
                    />
                </div>
                <div class="flex-shrink-0" :class="{'pointer-events-none' : !form.start_date}" style="width: 180px">
                    <h4 class="font-poppins text-primary-one fs-14 font-normal">Time Period</h4>
                    <div class="flex items-center px-4 h-10 mt-2 border border-secondary-five rounded">
                        <input
                            @input="updateAdditionalDay"
                            v-model="additionalDay"
                            class="w-full text-secondary-two"
                            type="number"
                        >
                        <span class="pl-1 w-full mr-10 text-secondary-two fs-14">days</span>
                        <svg @click="decrement" class="mr-2 flex-shrink-0 hover:fill-black" width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 1H12.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                        <svg class="flex-shrink-0" @click="increment" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 8H13.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M8 2.5V13.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div
                v-if="item.i_am == 'level' && item.level != 4"
                class="mt-5 flex items-center gap-x-4"
            >
                <div
                    v-for="(option, index) in options"
                    :key="index"
                    class="inline-flex items-center" @click="toggleTaskConfirmation(option)"
                >
                    <CheckboxCheckedIcon v-if="option.active" class="w-4 h-4 i-mr-7 cursor-pointer" />
                    <CheckboxIcon v-else class="w-4  h-4 i-mr-7 opacity-50 cursor-pointer" />
                    <span>{{ option.title }}</span>
                </div>
            </div>
            <div class="flex mt-5">
                <button class="fs-14 fw-600 font-poppins rounded bg-secondary-one text-primary-three h-10 w-28 shadow-one" @click="saveTask">Add Task</button>
                <button class="focus:outline-none fs-14 fw-600 font-poppins i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-32" @click="$emit('close')">Cancel</button>
            </div>
        </main>
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { ref, watch, computed, watchEffect } from 'vue';
    import { find } from 'lodash'
    import Datepicker from 'vuejs-datepicker';
    import { differenceInDays, addDays, format } from 'date-fns'
    const props = defineProps({
        item: {
            type: Object,
            required: true
        }
    });
    const emits = defineEmits(['close', 'done']);
    const options = ref([
        {
            title: 'Task',
            active: true
        },
        {
            title: 'Level',
            active: false
        }
    ]);
    const title = computed(() => find(options.value, 'active').title )
    const form = ref({
        title: '',
        description: '',
        start_date: null,
        end_date: null,
        parent_id: props.item.id,
        scenario_id: props.item.scenario_id,
        module_id: props.item.module_id,
        is_sub_task: 1
    });
    const timeline = ref({
        startDate: null,
        endDate: null
    });
    const additionalDay = ref(0);

    watchEffect(() => {
        additionalDay.value = differenceInDays(timeline.value.endDate, timeline.value.startDate) || 0;
    });
    const updateAdditionalDay = () => {
        timeline.value.endDate = addDays(timeline.value.startDate, additionalDay.value);
        form.value.end_date = format(timeline.value.endDate, 'yyyy-MM-dd')
    }
    const increment = () => {
        additionalDay.value++;
        timeline.value.endDate = addDays(timeline.value.startDate, additionalDay.value);
        form.value.end_date = format(timeline.value.endDate, 'yyyy-MM-dd')
    }
    const toggleTaskConfirmation = (item) => {
        options.value.forEach(item => item.active = false);
        item.active = true;
    }
    const decrement = () => {
        if(additionalDay.value <= 0) {
            return;
        }
        additionalDay.value--;
        timeline.value.endDate = addDays(timeline.value.startDate, additionalDay.value);
    }
    const saveTask = async () => {
        if(find(options.value, 'active').title == 'Level') {
            form.value.is_sub_task = 0
        }
        await axios.post(`planning/projects/modules/levels/store`, form.value).then(({data}) => data.data)
        emits('done');
    }
</script>
