<template>
    <div class="flex items-center gap-x-3 w-full">
        <svg v-if="collapsable" @click="collapseItem(item)" class="cursor-pointer flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" stroke="#1C1C1D"/>
            <path v-if="item.isChildHidden" d="M10.0683 7.076H7.5123V9.668H6.4923V7.076H3.9483V6.152H6.4923V3.548H7.5123V6.152H10.0683V7.076Z" fill="currentColor"/>
            <path v-else d="M9.17931 6.14V7.064H4.39131V6.14H9.17931Z" fill="currentColor"/>
        </svg>
        <svg
            v-if="removable"
            class="absolute remove-icon top-1/2 transform -translate-y-1/2 -left-6 cursor-pointer"
            :style="{left: left}"
            width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
            @click="$emit('remove')"
        >
            <path d="M4.25 7H9.75" stroke="#8D8D8D" stroke-linecap="square" stroke-linejoin="round"/>
            <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="#8D8D8D"/>
        </svg>
        <div class="flex items-center gap-3 w-full">
            <img class="w-8 h-8 rounded-full object-cover" :src="item.img_path || '/images/profile.png'" alt="">
            <p
                v-if="useFor == 'plan'"
                class="truncate text-left fs-12 fw-500"
                :title="item.title"
                :style="{width: adjustedWidth}"
            >{{ item.title }}</p>
            <div class="flex items-center w-full" v-if="showAssignee">
                <EditableColumn 
                    v-if="useFor == 'team'"
                    :item="item"
                    item-key="title"
                    class="fs-12 fw-50"
                />
                <DropdownCell @input="handleUpdateMember" idKey="w_user_id" v-model="item.user_id" :items="systemUsers" v-slot="{ option, onSelect }" style="width: 40px">
                    <div @click="onSelect" class="flex options-center items-center justify-between cursor-pointer px-2">
                        <div class="flex justify-center">
                            <img :src="option.image || '/images/profile.png'" alt="" class="w-8 h-8 rounded-full object-cover">
                            <span class="text-secondary-six hover:text-primary-one  block px-4 py-2 text-sm">{{ option.w_user_first_name || 'Default user'}}</span>
                        </div>
                        <RightIcon v-if="item.user_id == option.w_user_id" class="w-3 h-3 text-green-500" />
                    </div>
                </DropdownCell>
            </div>
        </div>
    </div>
</template>

<script setup>
    import EditableColumn from '@/views/screens/projectManagement/fragments/teams/table/EditableColumn.vue'
    import { set, inject, computed } from 'vue'
    import router from "@/routes/routes.js";
    import DropdownCell from '@/views/screens/projectManagement/components/DropdownCell.vue'

    const props = defineProps({
        item: Object,
        useFor: {
            type: String,
            default: () => 'plan',
            validator: value => ['plan', 'team', 'resource'].includes(value)
        },
        removable: {
            type: Boolean,
            default: () => false
        },
        collapsable: {
            type: Boolean,
            default: () => false
        },
        left: {
            type: String,
            default: () => '0'
        },
        offset: Number,
        showAssignee: Boolean,
        titleWidth: {
            type: Number,
            default: () => 250
        }
    });

    const route = router.currentRoute;
    const systemUsers= inject('systemUsers')
    const updateTeamMember= inject('updateTeamMember') //provided from index
    const getTeamsByProject= inject('getTeamsByProject')
    const handleUpdateMember = async (systemUserId) => {
        props.item.user_id = systemUserId
        await updateTeamMember(props.item)
        getTeamsByProject(route.params.projectId)
    }
    const adjustedWidth = computed(() => {
        if(props.offset) return `calc(${props.titleWidth}px - ${props.offset}px)`
        return `${props.titleWidth}px`
    })
    const collapseItem = (item) => set(item, 'childVisibility', !item.childVisibility)
</script>