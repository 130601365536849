import { cloneDeep, get } from 'lodash'
import axios from 'axios'
import { ref } from 'vue'
import router from '@/routes/routes.js';

const form = ref({
    project_id: '',
    jira_url: '',
    username: '',
    api_token: '',
    jira_connection_info: {
        projectId: null,
        boardId: null
    },
    
    jira_mapping: [],
    synchronization: false
})

export default function useJira(switchModalComponent) 
{
    // const { instrat360Dropdown, jiraDropdownOptions } = useMapping()
    const route = router.currentRoute;
    const loader = ref(false)
    const sprints = ref(null)
    const versions = ref([])
    let hasConfig = false
    const jiraProjects = ref(null)
    const jiraBoards = ref(null)

    const handleJiraConfig = () => {
        const { api_token, jira_url, username } = form.value
        if(!api_token || !jira_url || !username) {
            alert("Please provide all information.")
            return
        }
        //removing the if any slash after the link
        form.value.jira_url = form.value.jira_url.replace(/\/$/, "");
        if(hasConfig) {
            updateJiraConfig()
        }else {
            storeJiraConfig()
        }
    }

    const storeJiraConfig = async () => {
        try {
            loader.value = true
            form.value.project_id = route.params.projectId
            const { data} = await axios.post('/external-services/configuration/jira/store', form.value)
            form.value = data.data
            switchModalComponent('SelectJiraProject')
        }catch (err) {
            console.log(err)
        }
        loader.value = false
    }
    
    const updateJiraConfig = async (nextComponentName = 'SelectJiraProject') => {
        try {
            loader.value = true
            const { data} = await axios.post(`/external-services/configuration/jira/${form.value.id}/update`, form.value)
            form.value = data.data
            switchModalComponent(nextComponentName)
        }catch (err) {
            console.log(err)
        }
        loader.value = false
    }

    const getJiraConfig = async (_projectId) => {
        let projectId = _projectId || route.params.projectId
        try{
            const { data } = await axios.get(`/external-services/configuration/jira/${projectId}`)
            if(data && data.data) {
                form.value = data.data
                hasConfig = true
            }
        } catch(err) {
            console.log(err)
        }
    }
    
    const getSprints = async () => {
        let boardId = get(form.value, 'jira_connection_info.boardId')
        console.log({boardId})
        if(!boardId) return 
        try{
            const { data } = await axios.get(`/services/jira/boards/sprints?project_id=${route.params.projectId}&jira_board_id=${boardId}`)
           if(data.data) {
                sprints.value = data.data.values.filter(({state}) => state != 'closed')
           }
        } catch(err) {
            console.log(err)
        }
    }

    const handleSprintChange = async () => {
        // console.log('dfdf')
    }

    const getJiraProjects = async () => {
        try{
            const { data } = await axios.get(`/services/jira/projects?project_id=${route.params.projectId}`)
            jiraProjects.value = data.data
        } catch(err) {
            console.log(err)
        }
    }
    
    const getJiraBoards = async () => {
        try{
            const { data } = await axios.get(`/services/jira/boards?project_id=${route.params.projectId}`)
            console.log(data.data)
            jiraBoards.value = data.data
        } catch(err) {
            console.log(err)
        }
    }
    
    const getJiraVersions = async () => {
        try{
            const { data } = await axios.get(`/services/jira/projects/versions?project_id=${form.value.project_id}&jira_project_key=${form.value.jira_connection_info?.projectId}`)
            versions.value = data.data
        } catch(err) {
            console.log(err)
        }
    }
    
    const createJiraBulkIssue = async (payload, redirectComponent=false) => {
        try{
            loader.value = true
            await axios.post(`services/jira/issues/bulk-create?project_id=${route.params.projectId}`, payload)
            if(redirectComponent) {
                switchModalComponent(redirectComponent)
            }
        } catch(err) {
            console.log(err)
        }
        loader.value = false
    }

    const getAllProject = async () => {
        try {
            loader.value = true
            const res = await axios.get('https://asbjorn-levring.atlassian.net/rest/api/3/project', { 
                auth: {"Authorization" : {
                    username: 'mahmudshakkhor@gmail.com',
                    password: 'gK21ktKykVwO4bS5AZjUD31C'
                }} 
            })
            console.log(res)
        }catch (err) {
            console.log(err)
        }
        loader.value = false
    }

    return {
        storeJiraConfig,
        jiraLoader:loader,
        jiraForm:form,
        getJiraConfig,
        getJiraProjects,
        jiraProjects,
        getJiraBoards,
        jiraBoards,
        handleJiraConfig,
        updateJiraConfig,
        getAllProject,
        handleSprintChange,
        getSprints,
        sprints,
        createJiraBulkIssue,
        loader,
        versions,
        getJiraVersions
    }
}