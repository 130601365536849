<template>
    <div class="flex items-center w-full">
        <div class="flex bg-primary-three border-b border-t border-secondary-three i-h-42">
            <TableCell title="Work Breakdown Structure - WBS" style="width: 350px" class="pl-10 fs-11 fw-600" />
            <TableCell title="Est. Resource budget" class="i-w-85 fs-10 fw-500" />
            <TableCell title="Planned Res. Budget" class="i-w-85 fs-10 fw-500" />
            <TableCell title="Estimated Budget" class="i-w-85 px-1 fs-10 fw-500" />
            <TableCell title="Planned " class="i-w-85 fs-10 fw-500 justify-center">
                <span class="fs-10 fw-500">Planned <br/> Budget</span>
            </TableCell>
            <TableCell title="Budget not allocated" class="i-w-85 fs-10 fw-500" />
            <TableCell title="Planned Int. budget" class="i-w-85 fs-10 fw-500" />
        </div>
        <TimelineHeader v-if="timeline" :timeline="timeline" class="bg-primary-three" />
    </div>
</template>

<script setup>
    import TableCell from '../../../components/TableCell.vue';
    import TimelineHeader from './TimelineHeader.vue'

    const props = defineProps({
        timeline: Object
    });

</script>