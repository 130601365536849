<template>
    <div
        style="width: 1170px;height: 720px;" 
        class="rounded-tr rounded-tl relative"
    >
        <div  
            class="rounded-tr rounded-tl pl-6 pr-4 flex justify-between items-center i-h-50 bg-primary-three border border-b border-secondary-four"
        >
            <p v-if="currentModalConfig && currentModalConfig.title">{{ currentModalConfig.title  }}</p>
            <ModalIconTitle v-else/>
            <div class="flex gap-x-7">
                <Dots
                    :dots="currentModalConfig.dots"
                    v-if="currentModalConfig.showDots"
                />
                <Close @click.native="toggleModal(false)" class="w-4 h-4 cursor-pointer opacity-40 hover:opacity-80" />
            </div>
        </div>
        <div class="mt-8 px-10">
            <slot />
        </div>
    </div>
</template>

<script setup>
import Dots from '../Dots.vue'
import ModalIconTitle from '@/components/planning/integration/ModalIconTitle.vue'

const props = defineProps({
    integrationMethods: Object
})
const {toggleModal, currentModalConfig} = props.integrationMethods

</script>