<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">AdditionalSettings</h1>
        <h2 class="fw-500 fs-14 text-primary-one w-535px">
            Decide how your data is kept synchronized.
        </h2>
        <div class="mt-10 flex flex-col justify-start gap-5 h-378px overflow-y-auto">
            <div class="grid row items-center gap-6">
                <h2 class="px-2">Instrat360</h2>
                <span></span>
                <h2 class="px-2">Jira</h2>
            </div>
            
            <div v-for="(item, index) in jiraForm.jira_mapping" :key="index" class="grid row items-center gap-6">
                <Instrat360Dropdown :index="index" :mapItem="item" />
                <BothSideIcon class="text-primary-four" />
                <JiraDropdown :index="index" :mapItem="item" :options="jiraDropdownOptions" />
            </div>

        </div>
        <h2 @click="addMapItem(jiraForm.jira_mapping, placeholder)" class="text-primary-four fw-600 fs-14 ml-4 mt-4 cursor-pointer">+ Add more mapping levels</h2>

        <div class="absolute left-10 bottom-5">
            <Buttons
                @next="updateJiraConfig('DataSynchronized')"
                @back="switchModalComponent('SelectJiraProject')"
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUpdated } from 'vue'
import BothSideIcon from '../icons/BothSideIcon.vue';
import Buttons from '@/components/planning/integration/Buttons.vue'
import useMapping from '@/components/planning/integration/jira/useMapping.js'
import Instrat360Dropdown from '@/components/planning/integration/jira/Instrat360Dropdown.vue'
import JiraDropdown from '@/components/planning/integration/jira/JiraDropdown.vue'

const props = defineProps({
    integrationMethods: Object
})
const { 
    loadIssue,
    jiraDropdownOptions,
    placeholder,
    instrat360Dropdown,
    addMapItem
} = useMapping()
const { 
    switchModalComponent, 
    updateJiraConfig, 
    jiraForm 
} = props.integrationMethods

onMounted(async () => {
    await loadIssue(jiraForm.value.project_id, jiraForm.value.jira_connection_info.projectId)

    if(jiraForm.value.jira_mapping.length == 0){
        addMapItem(jiraForm.value.jira_mapping, placeholder)
    }
})
</script>

<style scoped>
.h-378px{
    height: 378px;
}
.row{
    grid-template-columns: 1fr 14px 1fr;
}
</style>