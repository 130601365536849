<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">AdditionalSettings</h1>
        <h2 class="fw-500 fs-14 text-primary-one w-535px">
            Decide how your data is kept synchronized.
        </h2>
        <div class="mt-10 grid gap-5">
            <div class="grid row items-center gap-6">
                <h2 class="px-2">Instrat360</h2>
                <span></span>
                <h2 class="px-2">Jira</h2>
            </div>
            <div class="grid row items-center gap-6">
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                    <option :value="null">Name & Description</option>
                </select>
                <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.75 1L13.25 3.5L10.75 6" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M0.75 3.5H13.25" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                        
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-12">
                    <option :value="null">Name & Description</option>
                </select>
            </div>
            <div class="grid row items-center gap-6">
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                    <option :value="null">Start & End Dates</option>
                </select>
                <BothSideIcon class="text-primary-four" />
                <select class="w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-12">
                    <option :value="null">Start & End Dates</option>
                </select>
            </div>
            <div class="grid row items-center gap-6">
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                    <option :value="null">Assignments</option>
                </select>
                <BothSideIcon class="text-primary-four" />
                <select class="w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-12">
                    <option :value="null">Assignments</option>
                </select>
            </div>
            <div class="grid row items-center gap-6">
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                    <option :value="null">JIRA sidebar links</option>
                </select>
                <BothSideIcon class="text-primary-four" />
                <select class="w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-12">
                    <option :value="null">JIRA standard information</option>
                </select>
            </div>
            <div class="grid row items-center gap-6">
                <select class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                    <option :value="null">Add parameter to sync</option>
                </select>
                <BothSideIcon class="text-primary-four" />
                <select class="w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-12">
                    <option :value="null">Add parameter to sync</option>
                </select>
            </div>
        </div>
        <h2 class="text-primary-four fw-600 fs-14 ml-4 mt-4">+ Add more mapping levels</h2>

        <div class="absolute left-10 bottom-5">
            <Buttons
                @back="switchModalComponent('DataSynchronized')"
            />
        </div>
    </div>
</template>

<script setup>
import BothSideIcon from '../icons/BothSideIcon.vue';
import Buttons from '@/components/planning/integration/Buttons.vue'

const props = defineProps({
    integrationMethods: Object
})
const { switchModalComponent, connectJira, jiraForm, getJiraConfig } = props.integrationMethods
</script>

<style scoped>
.row{
    grid-template-columns: 1fr 14px 1fr;
}
</style>