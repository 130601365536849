import axios from "axios"
import { ref, watch } from 'vue'
import { filterData, searchFilter } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'


const selectedProjectId = ref(null)
const selectedModuleId = ref(null)
const selectedModule = ref(null)
const selectedPropertyId = ref(null)
const selectedParameterId = ref(null)
const selectedParameter = ref(null)
const planData = ref([])
const filteredLevelData = ref([])
const levelData = ref([])
const definitions = ref([])
const systemParameters= ref([])
const parameterValues = ref({})
let   timeoutId = null

export default function(){
    const _flatten = ({ child = [], ...rest }) => [rest, ...child.flatMap(_flatten)];
    const getLevelData = (levelData) => {
        const flatData = _flatten(levelData)
        let lastLevelData = flatData.filter(item => (item.is_last_level))

        return lastLevelData.map(item => {
            item.selectedParameter = selectedModule.value.system_parameters.find(parameter => parameter.id == selectedParameterId.value)
            item.sampleValue       = getSampleValue(item.id, selectedModule.value.parameter_values[selectedParameterId.value])
            item.isFlipped         = false
            return item
        })
    }

    const getSampleValue = (itemId, parameters) => {
        if(!parameters) return 0
        let selectedParameter = parameters.find(item => item.id === itemId)
        let value = selectedParameter?.sample_value ? selectedParameter?.sample_value : 0
        return value ? Number(value).toFixed(2) : 0.00
    }
    const getRangeValue = (item, parameterId, parameterValues) => {
        if(parameterId == selectedParameterId.value){
            return item.sampleValue
        }
        return getSampleValue(item.id, parameterValues[parameterId])
    }
    const getDefinitions = async (workspaceId) => {
        let { data } = await axios.get(`definitions/workspace/${workspaceId}}`).then(({data}) => data)
        definitions.value = data
    }
    const updateSampleValue = (payload, cb) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async () => {
            await axios.post('properties/updateFromScenario', payload)
            cb()
        }, 1000)
    }

    watch([selectedProjectId, selectedModuleId, selectedPropertyId, selectedParameterId], async () => {
        if(selectedProjectId.value && selectedModuleId.value && selectedPropertyId.value && selectedParameterId.value){
            levelData.value = searchFilter(getLevelData(filteredLevelData.value), 'title')
        }
    })
    watch(filterData, () => {
        levelData.value = searchFilter(getLevelData(filteredLevelData.value), 'title')
    }, {deep: true})

    return {
        getLevelData,
        getRangeValue,
        getSampleValue,
        getDefinitions,
        updateSampleValue,
        definitions,
        selectedProjectId,
        selectedModuleId,
        selectedModule,
        selectedPropertyId,
        selectedParameterId,
        planData,
        levelData,
        selectedParameter,
        filteredLevelData,
        systemParameters,
        parameterValues
    }
}