import { cloneDeep, isEmpty, max, flatten, sumBy, round, meanBy } from "lodash";
import {
  getAvgPlannedEffortSumUp,
  getAvgMonthlyEfficiency,
  getWorkingHour,
  getDayDiff,
  getProgress,
  getCapacity,
  handleCompletionHistory,
  getPlanedBudgetGraphData,
  getEstimatedBudgetGraphData,
  getNotAllocatedBudgetGraphData,
  getParentMembers,
  generateProgressHistory,
  handleProgressHistory
} from './calculation'

const getModuleParameters = (item) => {
    return max(flatten(item.child.map(i => i.child.map(j => j.parameters.length)))) || 0;
}

export const transformPlanData = (item) => {
  let basis = cloneDeep(item);
  delete basis.child;
  const again = (item, basis) => {
    if (!isEmpty(item.child)) {
      let children               = item.child.map((item) => again(item, basis));
      let efficiencies           = getAvgPlannedEffortSumUp(children) || 0;
          item.capacity          = sumBy(children, (item) => item.capacity);
          item.estimatedEffort   = sumBy(children, (item) => item.estimatedEffort);
          item.monthlyEfficiency = efficiencies;
          item.avgPlannedEffort  = getAvgMonthlyEfficiency(efficiencies, item);
          item.totalCompletion   = meanBy(children, (item) => item.totalCompletion) || 0;
          item.avgProgress       = meanBy(children, (item) => item.avgProgress) || 0;
          item.totalMembers      = getParentMembers(children);


      //fields for cost-estimation
      item.totalHourlyRate         = sumBy(children, (item) => item.totalHourlyRate);
      item.estimatedResourceBudget = item.capacity * (item.estimatedEffort / 100) * item.totalHourlyRate || 0
      item.plannedResourceBudget   = (item.avgPlannedEffort / 100) * item.capacity * item.totalHourlyRate || 0
      item.estimatedBudget         = item.estimatedResourceBudget * (basis.materials_equipment / 100) || 0
      item.plannedBudget           = item.plannedResourceBudget * (basis.materials_equipment / 100) || 0        // target
      item.budgetNotAllocated      = item.estimatedBudget - item.plannedBudget
      item.plannedBillableEst      = item.plannedBudget * (basis.estimated_billable_hours / 100) || 0
      item.plannedIntBudget        = item.plannedBudget - item.plannedBillableEst

      // Graph data
      item.plannedBudgets         = getPlanedBudgetGraphData(efficiencies, basis, item)
      item.estimatedBudgets       = getEstimatedBudgetGraphData(efficiencies, item)
      item.notAllocatedBudgets    = getNotAllocatedBudgetGraphData(efficiencies, basis, item)
      item.totalCompletionHistory = handleCompletionHistory(children, item)
      item.progressHistory        = handleProgressHistory(children, item)
      
      if(item.i_am == 'team') {
        item.estimatedResourceBudget = item.capacity * (item.estimated_effort / 100) * item.totalHourlyRate || 0
      }
    }

    if (item.is_last_level) {
      item.estimatedEffort = item.estimated_effort || 0;
      if (!isEmpty(item.child)) {
        let totalHourlyRate = sumBy(item.child, (childItem) => sumBy(childItem.child, 'avg_hourly_rate'))
        item.totalHourlyRate = totalHourlyRate
        // console.log(item.id, totalHourlyRate)
        //for const est
        item.estimatedResourceBudget = item.capacity * (item.estimatedEffort / 100) * item.totalHourlyRate || 0
      }
      if (isEmpty(item.child)) {
        item.totalCompletion = item.completion || 0;
        item.avgProgress = getProgress(item) || 0;
        item.capacity = getCapacity(item, basis) || 0;
        item.totalHourlyRate = basis.avg_hourly_rate || 0;
        item.totalCompletionHistory = item.completion_histories
        item.progressHistory = generateProgressHistory(item)
      }
    }

    if (item.i_am == "team_member") {
      item.capacity               = getCapacity(item, basis) || 0;
      item.avgPlannedEffort       = getAvgMonthlyEfficiency(item.monthly_efficiency, item) || 0;
      item.monthlyEfficiency      = item.monthly_efficiency;
      item.totalCompletion        = item.completion || 0;
      item.avgProgress            = getProgress(item) || 0;
      item.totalMembers           = [item.team_member];
      item.totalCompletionHistory = item.completion_histories
      item.progressHistory        = generateProgressHistory(item)

      //for const estimation
      item.totalHourlyRate         = item.avg_hourly_rate;
      item.estimatedResourceBudget = item.capacity * (item.estimated_effort / 100) * item.totalHourlyRate || 0
      // item.plannedResourceBudget   = (item.avgPlannedEffort / 100) * item.capacity * item.totalHourlyRate || 0
      // item.estimatedBudget         = item.estimatedResourceBudget * (basis.materials_equipment / 100) || 0
      // item.plannedBudget           = item.plannedResourceBudget * (basis.materials_equipment / 100) || 0        // target
      // item.budgetNotAllocated      = item.estimatedBudget - item.plannedBudget
      // item.plannedBillableEst      = item.plannedBudget * (basis.estimated_billable_hours / 100) || 0
      // item.plannedIntBudget        = item.plannedBudget - item.plannedBillableEst
    }
    item.workingHour = round(getWorkingHour(item, basis.working_hours), 1);
    item.totalDays = round(getDayDiff(item), 1);
    item.plan_id = basis.id;
    return item;
  };
  item.maxParameters = getModuleParameters(item);
  return again(item, basis);
}