import { ref } from 'vue'

const showRightPanel = ref(false)
const selectedItem = ref({})

const useRightPanel = () => {

    return {
        showRightPanel,
        selectedItem
    }
}


export {
    useRightPanel
}