<template>
    <div style="width: 790px; height: 340px;" class="space-y-5 overflow-y-auto border-r border-secondary-four">
        <div 
            v-for="item in teamMembers"
            :key="item.id"
            class="hover:bg-gray-100"
        >
            <ResourceItem
                :item="item"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import ResourceItem from './ResourceItem.vue'
const props = defineProps({
    resources: Array
})
const teamMembers = computed(() => {
    if(!props.resources?.length) return []
    return props.resources.flatMap(resource => resource.child)
})
</script>