<template>
    <div>
        <div
            class="flex items-center border-b border-secondary-four h-10"
            :style="{
                backgroundColor: item.is_sub_task == 1 ? settings.taskColor : '',
                color: item.is_sub_task == 1 ? getContrastColor(settings.taskColor) : ''
            }"
        >
            <TitleColumn
                :item="item"
                :offset="offset"
                :hideCheckbox="hideCheckbox"
                :isMapped="getMappingStatus(item, jiraForm)"
                @collapseItem="collapseItem(item)"
                @openRightPanel="openRightPanel(item)"
                :jiraDropdownOptions="jiraDropdownOptions"
            />

            <div class="version w-40 status border-r border-secondary-four h-full flex items-center">
                <select
                    v-if="getMappingStatus(item, jiraForm)"
                    @change="handleSprintChange"
                    class="bg-transparent fw-500 w-full"
                    v-model="item.jira_link.version"
                >
                    <option :value="null">Select Version</option>
                    <option
                        v-for="{id, name} in versions" 
                        :key="id"
                        :value="id"
                    >
                        {{  name }}
                    </option>
                </select>
            </div>

            <div
                class="sprint border-r border-secondary-four h-full flex items-center"
                style="width: 243px"
            >
            <select
                v-if="getMappingStatus(item, jiraForm)"
                @change="handleSprintChange"
                class="bg-transparent fw-500 w-full"
                v-model="item.jira_link.sprint"
              >
                  <option :value="null">Select Sprint</option>
                  <option
                      v-for="{id, name} in sprints" 
                      :key="id"
                      :value="id"
                  >
                      {{  name }}
                  </option>
              </select>
            
            </div>

            <div
                v-for="n in 2"
                :key="n+1"
                style="width: 163px"
                class="border-r border-secondary-four h-full flex items-center"
            >
                <svg class="cursor-pointer ml-auto mr-3" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.3155 7.77481H8.34784V11.8337H4.65377V7.77481H0.68606V4.28596H4.65377V0.204239H8.34784V4.28596H12.3155V7.77481Z" fill="#2B80AD"/>
                </svg>
            </div>
        </div>
       <Transition name="slide" mode="out-in">
            <div v-if="!item.childVisibility">
                <Row
                    v-for="item in item.child"
                    :key="item.id"
                    :item="item"
                    :settings="settings"
                    :offset="offset+7"
                    :hideCheckbox="hideCheckbox"
                    :showConfigurationModal="showConfigurationModal"
                    :shared="shared"
                    :handleSprintChange="handleSprintChange"
                    :jiraForm="jiraForm"
                    :sprints="sprints"
                    :jiraDropdownOptions="jiraDropdownOptions"
                    :versions="versions"
                ></Row>
            </div>
        </Transition>
    </div>
</template>

<script setup>
    import { set, watchEffect } from 'vue';
    import { getContrastColor } from '@/plugins/lodash.js'
    import { useRightPanel } from '@/components/planning/useRightPanel.js';
    import Row from './Row.vue';
    import TitleColumn from '../components/TitleColumn.vue';
    import { getMappingStatus } from '@/components/planning/integration/jira/jiraHelper.js'
    const props = defineProps({
        item: Object,
        settings: Object,
        offset: Number,
        hideCheckbox: Boolean,
        showConfigurationModal: Function,
        handleSprintChange: Function,
        shared: Object,
        sprints: Array,
        jiraForm: Object,
        jiraDropdownOptions: Array,
        versions: Array
    });
    
    const { showRightPanel, selectedItem } = useRightPanel()
    const openRightPanel = (item) => {
        showRightPanel.value = true;
        selectedItem.value = item
    }

    const collapseItem = (item) => {
        set(item, 'childVisibility', !item.childVisibility);
    }
    // watchEffect(() => {
    //     if(props.offset == 14) {
    //         props.shared.module = props.item;
    //         props.collectModules(props.item);
    //     }
    //     set(props.item, 'parameters', props.shared.module.parameters);
    // });

    
</script>

<style scoped>
@import '/assets/css/planning.css';
</style>
