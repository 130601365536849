<template>
    <Column v-if="item.i_am == 'plan' || item.i_am == 'project'" class="h-full"></Column>
    <Column
        v-else
        @click.native="handleClick(item ,{item, parameter, parameterValue, parameterValues:module.parameter_values})"
        class="h-full"
        :class="[getHoverClass(item, item.i_am), getWeight(item)]"
    >
        {{ round(parameterValue) }}
    </Column>
</template>

<script setup>
    import { computed } from 'vue';
    import Column from './Column.vue';
    import { getParameterValueFromHistory } from '@/store/services/traits/parameter.js';
    import { usePlanning } from '@/views/screens/planning/usePlanning.js';

    import { round } from 'lodash'
    const emit = defineEmits(['onClickParameter'])
    const props = defineProps({
        item: Object,
        modules: Array,
        parameter: Object
    });
    const module = computed(() => props.modules.find(module => module.module_id == props.item.module_id))
    const parameterValue = computed(() => {
        let module = props.modules.find(module => module.module_id == props.item.module_id);
        if(!module) {
            return;
        }
        return getParameterValueFromHistory(props.item, props.parameter, module.parameter_values)
    })
    const { getWeight } = usePlanning();

    const handleClick = (item, data) => {
        if(item.child.length || !['task', 'level'].includes(item.i_am)) return
        emit('onClickParameter', data)
    }

    const getHoverClass = (item, type) => (!item.child.length && ['task', 'level'].includes(type)) ? 'cursor-pointer click-bounce' : ''
</script>