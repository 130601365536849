import useJira from '@/components/planning/integration/jira/useJira.js'
import { onMounted, ref, set } from 'vue'
import { get, isEmpty } from 'lodash'
import router from '@/routes/routes.js'
import axios from 'axios'
import { filter as lodashFilter } from 'lodash'
import { searchFilter, sorting, filterByGroupType, filterByShow, filterByTime } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import { setMembers } from '@/views/screens/projectManagement/fragments/taskBoard/header/useHeader.js'


const { getJiraConfig, jiraForm } = useJira()
const boardId = ref(null)
const jiraColumns = ref([])
const jiraData = ref([])
let timeoutId = null

export default function useJiraTaskBoard () 
{
    const route = router.currentRoute;

    const getAssignedMembers = (item, cb) => {
        let flatten = ({ child = [], ...rest }) => [rest, ...child.flatMap(flatten)];
        return lodashFilter(flatten(item), cb)
    }
    const getMembers = (item) => {
        let avatars = []
        let assignedMembers = getAssignedMembers(item,  i => i.i_am == "team_member")
      
        avatars = assignedMembers.map(member => {
          return {
            name: member.team_member?.name,
            file_path: member.team_member?.img_path
          }
        })
        return avatars
    }


    function _flatten(data) {
        let result = [];
        function flattenHelper(item) {
            result.push(item);
            if (item.child && item.child.length > 0) {
                item.child.forEach((child) => {
                    flattenHelper(child);
                });
            }
        }
        flattenHelper(data);
        return result;
    }

    const getLevelData = (levelData) => {
        const flatData = _flatten(levelData)
        let filteredData = filterByGroupType(flatData)
        
        filteredData = filterByShow(filteredData)
        filteredData = filterByTime(filteredData)
        return filteredData
    }

    const getJiraColumns = async (boardId) => {
        const params = {
            project_id: route.params.projectId,
            jira_board_id: boardId
        }
        
        const { data } = await axios.get(`services/jira/boards/configuration`, { params } ).then(({data}) => data)
        if(data){
            jiraColumns.value = data.columnConfig?.columns
        }
    }

    const loadBulkJiraIssue = async (instratData) => {
        const payload = []
        instratData.forEach(item => {
            if(item.jira_link && item.jira_link.id){
                payload.push({
                    instrat_id: item.id,
                    issue_id: item.jira_link.id
                })
            }
        })

        if(!isEmpty(payload)){
            clearTimeout(timeoutId)
            timeoutId = setTimeout(async () => {
                const { data } = await axios.post(`services/jira/issues/bulk?project_id=${route.params.projectId}`, {links: payload}).then(({data}) => data)
                data.forEach(item => {
                    item.instData = _getInstData(instratData, item)
                    item.progress = get(item, 'issue.fields.progress.progress')
                    item.title    = get(item, 'issue.fields.summary')
                })

                let dataList = sorting(data)
                    dataList = searchFilter(data, 'title')
                jiraData.value = dataList

                let instData = jiraData.value.map(item => item.instData)
                setMembers(instData)
            }, 1000)
        }
    }

    const _getInstData = (instData, item) => {
        let filteredData = instData.find(inst => inst.id == item.instrat_id)
        return filteredData
    }

    const showData = (column, item) => {
        const columnIssues   = get(column, 'statuses')
        const issueStatusId  = get(item, 'issue.fields.parent.fields.status.id')

        let status = columnIssues.find(item => {
            return (item.id == issueStatusId)
        })
        return status
    }

    onMounted(async () => {
        await getJiraConfig(route.params.projectId)
        boardId.value  = jiraForm.value.jira_connection_info.boardId
        getJiraColumns(boardId.value)
    })

    return {
        boardId,
        jiraColumns,
        loadBulkJiraIssue,
        jiraData,
        showData,
        getLevelData,
        getMembers,
        getAssignedMembers
    }
}