import ModuleList from '@/components/planning/sidebar/ProjectList.vue'
import Properties from '@/components/planning/sidebar/Properties.vue'
import { ref, computed } from 'vue';

export const useSidebar = () => {
    const components = {
        ModuleList,
        Properties
    }
    const currentSidebar = ref('ModuleList');
    const sidebarVisibility = ref(true);
    const propsData = ref({});
    const activeSidebarComponent = computed(() => components[currentSidebar.value]);
    const switchSidebarComponent = (value, data) => {
        if(currentSidebar.value == 'ModuleList' && value == 'ModuleList') {
            sidebarVisibility.value = false;
        }
        currentSidebar.value = value;
        propsData.value = data;
    }

    return {
        currentSidebar,
        sidebarVisibility,
        activeSidebarComponent,
        switchSidebarComponent,
        propsData
    }
}
