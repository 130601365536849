<template>
    <div class="pl-10 hover:bg-gray-100">
        <div v-if="item.i_am == 'team_member'" style="height: 68px;" class="grid grid-cols-6 gap-x-5 pr-8 items-center">
            <div class="col-span-2 flex gap-x-3 items-center space-y-1">
                <img :src="item.img_path || '/images/profile.png'" class="h-10 w-10 object-cover rounded-full">
                <div class="w-40">
                    <p class="fs-16 fw-500 text-primary-one truncate" :title="item.name">{{ item.name }}</p>
                    <p class="fs-13 fw-400 text-secondary-six truncate" :title="item.designation">{{ item.designation }}</p>
                </div>
            </div>
            <div class="space-y-1">
                <p :class="rowTitleCss">Capacity in Period</p>
                <p :class="rowValueCss">{{ round(get(item, 'capacity', 0)) }}</p>
            </div>
            <div class="space-y-1">
                <p :class="rowTitleCss">Avg. Planned </p>
                <p :class="rowValueCss">{{ round(get(item, 'hoursPlaned', 0)) }}%</p>
            </div>
            <div class="space-y-1">
                <p :class="rowTitleCss">Remaining Capacity</p>
                <p :class="rowValueCss">+{{ round(get(item, 'remaining', 0)) }}%</p>
            </div>
            <div class="space-y-1">
                <div style="height: 22px; width: 59px; background-color: #2BAD7E;" class="rounded-sm flex items-center justify-between text-white px-2 fs-10 fw-400">
                    On track
                </div>
                <p :class="rowValueCss" class="mt-1">{{ item.progress }}% Progress</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { round, get } from 'lodash';
const props = defineProps({
    item: Object,
})
const rowTitleCss = 'fw-400  text-secondary-six fs-10'
const rowValueCss = 'fw-500 text-primary-one fs-14'
</script>