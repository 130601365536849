import { concat, round } from 'lodash'
import { differenceInDays } from 'date-fns'

export const dateCalculation = () => {
    const target = 1
    const getTotalWorkingDay = (startDate, endDate) => differenceInDays(new Date(endDate), new Date(startDate))

    const getGoneDays = (startDate, totalWorkingDay, compareDate) => {
        const today = compareDate || new Date()
        const itemStartDate =  new Date(startDate)
        if(today.getTime() > itemStartDate.getTime()){
            let startDayDiff = differenceInDays(today, itemStartDate)
            return startDayDiff > totalWorkingDay ? totalWorkingDay : startDayDiff
        }
        return 0
    }

    // return work should have done in percentage
    const getWorkShouldDone = (totalWorkingDay, goneDays) => {
        if(totalWorkingDay && goneDays) {
            const expected = (( target / totalWorkingDay ) * goneDays) * 100;
            return expected > 100 ? 100 : expected
        }
    }

    const calculateProgress = (totalCompletion, workShouldDone) =>{
        const completion  = totalCompletion || 0;
        if(!workShouldDone) return round(completion, 2);
        return limitNumber(round(completion - workShouldDone, 2));
    }

    const getProgress = (item, currentDate)=> {
        const { start_date, end_date, totalCompletion } = item;
        if(!start_date || !end_date){
            return 0;
        }
        const totalWorkingDays  = getTotalWorkingDay(start_date, end_date) || 0
        const goneDays          = getGoneDays(start_date, totalWorkingDays, currentDate) || 0
        const workShouldDone    = getWorkShouldDone(totalWorkingDays, goneDays) || 0
        return calculateProgress(totalCompletion, workShouldDone)
    }

    const getExpectedProgress = (startDate, endDate, currentDate) =>{
        // console.log(startDate, endDate, currentDate)
        if(currentDate.getTime() < startDate.getTime()) return 0
        if(currentDate.getTime() >= endDate.getTime()) return 100
        let totalDays = differenceInDays(startDate, endDate)
        let startDayDiff = differenceInDays(startDate, currentDate)
        return (( target / totalDays ) * startDayDiff) * 100
    }

    const remainingDays = (item) => {
        const { start_date, end_date } = item;
        if(!start_date || !end_date){
            return 0;
        }
        const totalWorkingDays  = getTotalWorkingDay(start_date, end_date) || 0
        const goneDays          = getGoneDays(start_date, totalWorkingDays) || 0
        return totalWorkingDays - goneDays
    }

    function limitNumber(num) {
        if (num > 100) {
          return 100;
        } else if (num < -100) {
          return -100;
        } else {
          return num;
        }
      }

    return {
        progress: 0,
        goneDays: 0,
        totalWorkingDay: 0,
        remainingDays,
        getProgress,
        getTotalWorkingDay,
        getWorkShouldDone,
        getGoneDays,
        getExpectedProgress
    }
}