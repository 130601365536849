<template>
    <div class="relative">
        <slot name="trigger" :handleToggle="handleToggle" :selectedItem="selectedItem"></slot>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <div v-if="show"
                class="absolute right-0 z-10 px-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-opacity-5 focus:outline-none shadow-two fs-13 fw-400"
                :class="{'bottom-full' : drawback}"
                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none" v-for="(item, index) in items" :key="index">
                    <slot name="option" :item="item" :onSelect="value => onSelect(item, value)"></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: "Select"
    },
    items: {
        type: Array,
        default: () => []
    }
});
const drawback = inject('drawback', false);
const emit = defineEmits(['onChange'])
const show = ref(false);
const selectedItem = ref(null);

const toggleMenu = (value) => show.value = value

const handleClickOutSide = () => {
    document.removeEventListener('click', handleClickOutSide)
    toggleMenu(false)
}

const handleToggle = () => {
    if (!show.value) {
        toggleMenu(true)
        setTimeout(() => {
            document.addEventListener('click', handleClickOutSide)
        }, 100)

        return
    }
    if (show.value) toggleMenu(false)
}

const onSelect = (item, payload) => {
    if (!(payload instanceof MouseEvent)) {
        selectedItem.value = payload;
        emit('onChange', payload);
        return;
    }
    emit('onChange', item);
    selectedItem.value = item;
}
</script>