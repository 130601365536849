import axios from "axios";
import { ref } from 'vue'
import { round, find, orderBy, cloneDeep, sumBy, omit, isEmpty, some, flatten, flatMap, groupBy, map, meanBy } from "lodash";
import { collapseAllRow as collapseRow, getLastLevelsUnderModule } from '@/views/screens/projectManagement/services/utils/_filter.js'

import {
  handleMonthlyEfficiency,
  getAvgMonthlyEfficiency,
  findMemberDetails,
  getCapacity,
  generateProgressHistory,
  isMyTask,
  filterEfficiencyByItemDuration
} from "./calculation.js";

export const transformResourceData = (team, plan) => {
  let member = {};
  const again = (item, plan, member) => {
    if (!isEmpty(item.child)) {
      if (item.i_am == "team_member") {
        member = item;
      }
      let children                  = item.child.map((item) => again(item, plan, member));
          item.viewable             = some(children, (item) => item.viewable);
      let efficiencies              = handleMonthlyEfficiency(children);
      // let validEfficiencies         = filterEfficiencyByItemDuration(efficiencies, item)
          item.validEfficiencies    = efficiencies
          item.capacity             = sumBy(children, (item) => item.capacity) || 0;
          item.monthlyEfficiency    = efficiencies;                                          
          item.avgMonthlyEfficiency = getAvgMonthlyEfficiency(efficiencies, item) || 0;
          item.estimatedEffort      = sumBy(children, (item) => item.estimatedEffort) || 0;
          item.hoursPlaned          = sumBy(children, (item) => item.hoursPlaned) || 0;
          item.remaining            = sumBy(children, (item) => item.remaining) || 0;
    }
    if (item.is_last_level) {
      item.viewable = isMyTask(item, member);
      if (item.viewable) {
        let memberDetails                   = findMemberDetails(item, member);
            memberDetails.monthlyEfficiency = filterEfficiencyByItemDuration(memberDetails.monthly_efficiency, item);
        let AvgMonthlyEfficiency            = getAvgMonthlyEfficiency(memberDetails.monthly_efficiency, item) || 0;
        let capacity                        = getCapacity(item, plan) || 0;
            // console.log(plan.weekend_holiday_adjustment, plan.id,  '====capacity=====')
            item.capacity                   = capacity;
            item.avgMonthlyEfficiency       = AvgMonthlyEfficiency;
            item.monthlyEfficiency          = memberDetails.monthlyEfficiency;                                     // timeline data
            item.estimatedEffort            = item.estimated_effort || 0;
            item.hoursPlaned                = capacity * (AvgMonthlyEfficiency / 100);
            item.remaining                  = 100 - AvgMonthlyEfficiency;
            item.memberDetails              = memberDetails;
        }
    }
    if (item.i_am == "team_member") {
      item.competency = item.primary_competency || item.secondary_competency || item.tertiary_competency;
    }
    return item;
  };
  return again(team, plan, member);
};

export const useResource = () => {
    const plan = ref({});
    const resources = ref([]);
    const localDataCopy = ref([]);
    const getPlanning = async (planId) => {
        return await axios.get(`project-management/plan/${planId}?team_view=1`).then(({ data }) => data.data);
    };
    const injectLevelData = async (teams, planId) => {
        let planning = await getPlanning(planId);
        let planCopied = cloneDeep(planning);
        plan.value = omit(planCopied, ["child"]);
        let data = teams.filter(i => i.child.length > 0).map((team) => {
            team.child = team.child.map((item) => {
                // Member area
                item.child = cloneDeep([planning]);
                return item;
            });
            return transformResourceData(team, plan.value);
        });
        resources.value = data;
        localDataCopy.value = cloneDeep(data)
    };
    const sync = (resources, plan) => {
        resources.value = resources.map((team) => transformResourceData(team, plan));
    };

    const onlyTask = (state, config) => {
        resources.value  = !state ? cloneDeep(localDataCopy.value) : getLastLevelsUnderModule(resources.value, item => item.is_last_level, 5);
        if(config.sync) {
            // updateProjectSettings()
        }
    }
    const collapseAllRow = (value, config) => {
        collapseRow(resources.value, value);
        if(config.sync) {
            // updateProjectSettings(settings.value);
        }
    }
  const preparePlannedCapacitySeries = (resources, name, dataKey="monthlyEfficiency",) => {
    let allEfficiencies = flatMap(resources, dataKey)
    allEfficiencies = groupBy(allEfficiencies.filter(efficiency => efficiency != undefined), 'date')
    let data =map(allEfficiencies, (value, key) => ({
      x: new Date(key),
      y: round(meanBy(value, 'value'))
    }))
    data = _orderChartDataByDate(data)
    return { name, data}
  }
    const prepareAvailableCapacitySeries = (resources) => {
        resources.forEach(resource => {
        const membersEfficiency = sumBy(resource.child, ch => ch.efficiency);
        if(!resource.monthlyEfficiency) resource.monthlyEfficiency = []
        resource.resourceAvailableEfficiency = resource.monthlyEfficiency.map(({date, value}) =>({
            date,
            value: value - membersEfficiency
        }))
        })
        const availableMonthlyCapacity = preparePlannedCapacitySeries(resources, 'Available Capacity', 'resourceAvailableEfficiency')
        return availableMonthlyCapacity
    }
    //for people chart
    const prepareCapacitySeries = (resources, timeline) => {
        const children = flatMap(resources, 'child')
        const chartSeries = children.map(child => ({
        name: child.title,
        data: _generateMonthWiseEfficiencyOfMember(child, timeline)
        }))
        return  chartSeries
    }
    // for people chart
    const prepareProgressSeries = (resources) => {
        let finalSeries = []
        const flatData = flattenData(resources)
        const allMember = flatten(map(resources, child => child.child))
        const dataWithCompletionHistory = flatData.filter(item => item.completion_histories && item.viewable)
        dataWithCompletionHistory.forEach(item => item.progressHistory = generateProgressHistory(item))

        allMember.forEach(member => {
            const tasks = dataWithCompletionHistory.filter(task => isMyTask(task, member))
            const series= preparePlannedCapacitySeries(tasks, member.title, 'progressHistory')
            if(series.data?.length) finalSeries.push(series)
        })
        return finalSeries
    }

    const getValue = (key, monthlyEfficiency) => {
        let data =  find(monthlyEfficiency, ['date', key]);
        if(data) {
        return data.value
        }
        return 0;
    }

    const _orderChartDataByDate = data => orderBy(data, ['x'], ['asc']);

    const _generateMonthWiseEfficiencyOfMember = (child, timeline) =>{
        let data =  timeline.months.map(({month, year}) => ({
        x: new Date(`${month}${year}`),
        y: round(getValue(`${month}${year}`, child.monthlyEfficiency))
    }))
    return _orderChartDataByDate(data)
  }

    const flattenData = data => {
        let flattened = [];
        data.forEach((element) => {
            flattened.push(element);
            if (element.child.length > 0) {
                flattened = flattened.concat(flattenData(element.child));
            }
        });
        return flattened;
    }

    return {
        plan,
        resources,
        injectLevelData,
        collapseAllRow,
        onlyTask,
        sync,
        preparePlannedCapacitySeries,
        prepareAvailableCapacitySeries,
        getValue,
        prepareProgressSeries,
        prepareCapacitySeries
    }
}