<template>
    <Column v-if="item.i_am == 'plan' || item.i_am == 'project'" class="h-full"></Column>
    <Column
        v-else
        @click.native="handleClick(item ,{item, parameter, parameterValue, parameterValues:module.parameter_values})"
        class="h-full text-white"
        :style="{backgroundColor}"
        :class="[getHoverClass(item, item.i_am), getWeight(item)]"
    > 
        {{ definition }}
    </Column>
</template>
<script setup>
    import Column from './Column.vue';
    import { usePlanning } from '@/views/screens/planning/usePlanning.js';

    import { getParameterValueFromHistory, getParameterBg, getParameterDefinition } from '@/store/services/traits/parameter.js'
    import { computed } from 'vue';

    const props = defineProps({
        item: Object,
        modules: Array,
        parameter: Object
    });
    const { getWeight } = usePlanning();

    const emit = defineEmits(['onClickParameter'])
    const module = computed(() => props.modules.find(module => module.module_id == props.item.module_id))
    const backgroundColor = computed(() => getParameterBg(getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()), props.parameter.styles));
    const definition = computed(() => getParameterDefinition(getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()), props.parameter.styles))
    const parameterValue = computed(() => getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()))
    const getParameterValuesFromModule = () => {
        let module = props.modules.find(module => module.module_id == props.item.module_id);
        if(!module) {
            return;
        }
        return module.parameter_values;
    }
    const handleClick = (item, data) => {
        if(item.is_last_level) {
            emit('onClickParameter', data)
        }
    }

    const getHoverClass = (item) => (item.is_last_level) ? 'cursor-pointer click-bounce' : ''
</script>