<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5219 6.6563C21.0138 7.80501 21.1343 9.07881 20.8663 10.2994C20.5983 11.5199 19.9554 12.6261 19.0275 13.4631C18.0996 14.3001 16.9331 14.8259 15.6915 14.9671C14.4499 15.1082 13.1952 14.8575 12.1031 14.25V14.25L6.84375 20.3438C6.42106 20.7665 5.84777 21.0039 5.25 21.0039C4.65222 21.0039 4.07894 20.7665 3.65625 20.3438C3.23356 19.9211 2.99609 19.3478 2.99609 18.75C2.99609 18.1523 3.23356 17.579 3.65625 17.1563L9.75 11.8969C9.14258 10.8049 8.89188 9.55017 9.03299 8.30854C9.1741 7.06691 9.69996 5.90049 10.5369 4.97257C11.3739 4.04466 12.4801 3.40171 13.7007 3.13374C14.9212 2.86578 16.195 2.98621 17.3437 3.47817L13.4062 7.4063L13.9406 10.0594L16.5937 10.5938L20.5219 6.6563Z" stroke="#3D3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "WrenchIcon"
}
</script>

<style scoped>

</style>
