<template>
    <div>
        <span
            :class="[progressAmount < 0 ? 'text-primary-six' : 'text-success']"
            class="">{{ progressAmount }}%
        </span>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { round } from 'lodash'
    const props = defineProps({
        item: Object,
    });
    const progressAmount = computed(() => {
       return props.item.avgProgress ? round(props.item.avgProgress) : 0
    });
</script>