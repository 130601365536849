import { render, staticRenderFns } from "./Instrat360Dropdown.vue?vue&type=template&id=36488ed0&scoped=true&"
import script from "./Instrat360Dropdown.vue?vue&type=script&setup=true&lang=js&"
export * from "./Instrat360Dropdown.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Instrat360Dropdown.vue?vue&type=style&index=0&id=36488ed0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36488ed0",
  null
  
)

export default component.exports