import { set } from 'vue';
import { getFlattenCollections } from '@/store/modules/global/services.js'
import { filter } from 'lodash'

export const collapseAllRow = (data, state) => {
    const again = (items) => {
        items.forEach(item => {
            if(item.child.length > 0) {
                again(item.child)
            }
            set(item, 'childVisibility', state);
        });
    }
    again(data);
}

export const getLastLevelsUnderModule = (items, cb, level = 3) => {
    const again = (i, cb, depth) => {
        if (typeof depth == "number") depth++;
            else depth = 1;
        if(i.child.length) {
            let children = i.child.map(j => again(j, cb, depth));
            if(depth == level) {
                i.child = filter(getFlattenCollections(children), cb);
            }
        }
        return i;
    }
    return items.map(i => again(i, cb));
}
