<template>
    <Column v-if="!item.i_am == 'plan' || item.i_am == 'project'" class="h-full"></Column>
    <Column
        v-else
        class="h-full font-semibold"
        @click.native="handleClick(item ,{item, parameter, parameterValue})"
        :style="{color}"
        :class="getHoverClass(item)"
    >
        {{ definition }}
    </Column>
</template>
<script setup>
    import Column from './Column.vue';
    import { getParameterValueFromHistory, getParameterBg } from '@/store/services/traits/parameter.js'
    import { computed } from 'vue';
    const props = defineProps({
        item: Object,
        modules: Array,
        parameter: Object
    });
    const emit = defineEmits(['onClickParameter'])

    const definition = computed(() => getParameterTextDefinition(getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()), props.parameter.styles));
    const color = computed(() => getParameterBg(getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()), props.parameter.styles));
    const parameterValue = computed(() => getParameterValueFromHistory(props.item, props.parameter, getParameterValuesFromModule()))
    const getParameterValuesFromModule = () => {
        let module = props.modules.find(module => module.module_id == props.item.module_id);
        if(!module) {
            return;
        }
        return module.parameter_values;
    }
    const getParameterTextDefinition = (value, definitionStyles) => {
        if(value > 0) {
            value = value - .000001;
        }
        let valueIndex = Math.floor(value / 2.5);
        if(valueIndex > 4) {
            valueIndex = 4;
        }
        if(definitionStyles[valueIndex]) {
            return definitionStyles[valueIndex].definition;
        }
        return null;
    }
    const handleClick = (item, data) => {
        if(item.is_last_level) {
            emit('onClickParameter', data)
        }
    }

    const getHoverClass = (item) => (item.is_last_level) ? 'cursor-pointer click-bounce' : ''
</script>