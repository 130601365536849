<template>
    <div class="relative flex">
        <div v-show="sidebarVisibility" class="i-left-panel shadow-one min-h-screen">
            <div class="relative">
                <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
                <NavToggle v-if="projectId" />
            </div>
            <slot name="sidebar"></slot>
        </div>
        <img
            v-if="!sidebarVisibility"
            src="/images/icons/build/navigation.svg"
            class="absolute cursor-pointer opacity-50 hover:opacity-100 hover:bg-primary-three i-w-30 i-h-30"
            title="Full screen"
            style="top: 104px; left: 40px;"
            @click="$emit('exitFullScreen')"
        />
        <div class="pl-10 i-right-panel">
                <div :style="[!sidebarVisibility ? {'margin-left' : '67px', width: '1800px'} : {}]">
                    <div
                        class="ws-heading-box"
                        :style="[!sidebarVisibility ? {marginRight: `8px`} : '' ]"
                    >
                    <workspace-menu />
                </div>
                <div style="margin-top: -41px">
                    <slot name="toolbar"></slot>
                </div>
            </div>
            <div>
                <slot></slot>
            </div>
        </div>

        <Nav v-model="showNavigation" @close="showNavigation=false"/>
    </div>
</template>

<script setup>
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'

defineProps({
    sidebarVisibility: Boolean
});
const { projectId, showNavigation } = useNavigation()
// .ws-heading-box {
//     margin: 40px 50px 0 40px;
//     padding-bottom: 44px;
// }
</script>

