<template>
    <div 
        class="fixed top-0 z-50 bg-primary-two rightPanelWrapper h-full shadow transition-all"
        :class="showRightPanel ? '-right-0' : '-right-full'"
    >
        <Header />

        <div class="navigation">
            <BasicInfo />
            <SubTaskList  @createTask="(item) => $emit('createTask', item)"/>
            <JiraIssues :selectedItem="selectedItem"/>
            <Comments />
        </div>
    </div>
</template>

<script setup>
    import { useRightPanel } from '@/components/planning/useRightPanel.js'
    import Header from './rightPanelComponents/Header.vue'
    import BasicInfo from './rightPanelComponents/BasicInfo.vue'
    import SubTaskList from './rightPanelComponents/SubTaskList.vue'
    import JiraIssues from './rightPanelComponents/JiraIssues.vue'
    import Comments from "@/components/planning/rightPanelComponents/Comments.vue"
    
    const { showRightPanel, selectedItem } = useRightPanel()
</script>

<style scoped>
    .rightPanelWrapper{
        width: 395px;
        box-shadow: -4px 0px 12px rgba(35, 39, 47, 0.1);
    }
    select {
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("/images/icons/chevron_down.svg");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position-x: 97%;
        background-position-y: 8px;
    }
</style>
