<template>
    <InputCell :with-percent="false">
        <input
            v-model="localItem"
            type="text"
            class="w-full h-10"
            style="font-weight: inherit; font-family: inherit; font-size: inherit; color: inherit;"
            autocomplete="off"
        />
    </InputCell>
</template>

<script setup>
    import InputCell from '@/views/screens/projectManagement/components/InputCell.vue';
    import { useTeam } from '@/views/screens/projectManagement/services/useTeam.js'
    import { isFunction } from 'lodash';
    import { computed } from 'vue';
    const props = defineProps({
        item: Object,
        itemKey: {
            type: String,
            required: true
        },
        handelChange: {
            type: Function,
            required: false
        }
    });

    const { updateTeamMember, sync } = useTeam();

    const localItem = computed({
        get() {
            return props.item[props.itemKey];
        },
        set(value) {
            props.item[props.itemKey] = value;
            sync()
            if(!props.handelChange && !isFunction(props.handelChange)) {
                updateTeamMember(props.item);
                return;
            }
            props.handelChange(props.item);
        }
    })

</script>