<template>
    <div class="px-6 mt-4">
        <div class="flex justify-between items-center pb-4 border-b border-secondary-four cursor-pointer">
          <h1 @click="subTaskToggle = !subTaskToggle" class="fw-700 fs-14 uppercase">Subtask List</h1>
          <button @click="subTaskToggle = !subTaskToggle" class="cursor-pointer">
            <svg class="transform" :class="subTaskToggle ? 'rotate-0' : 'rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75098 12.5L10.001 6.25L16.251 12.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div v-if="subTaskToggle" class="mt-5">

          <div v-for="(item, index) in getSubTask(selectedItem.child)" :key="index" class="task_wrapper px-3 py-2 rounded relative text-white overflow-hidden my-4 flex justify-between gap-4">
            <span class="relative z-10">{{ getTitle(item) }}</span>
          </div>

          <div @click="$emit('createTask', selectedItem)" v-if="isEmpty(selectedItem.child)" class="fw-500 fs-14 text-secondary-two text-center px-5 py-3 flex items-center justify-center gap-x-2 cursor-pointer bg-white h-10 rounded fs-12 font-normal">
            Click here to add subtask
          </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { isEmpty } from 'lodash'
import { useRightPanel } from '@/components/planning/useRightPanel.js'

const subTaskToggle = ref(false)
const { selectedItem } = useRightPanel()
const getSubTask = (child) => {
  let filteredItem = child.filter(item => {
    if(item.is_sub_task == 1){
      return item
    }
  })

  return filteredItem
}

const getTitle = (item) => {
  let title = ''

  if(item.index_id){
    title = item.index_id
  }

  title += `${item.index_code} ${item.title}`
  return title
}

</script>

<style scoped>
.task_wrapper{
    background-color: #5F9198;
}
.task_wrapper .progress{
    height: 100%;
    width: var(--width);
    background-color: #2B898F;
    position: absolute;
    top: 0;
    left: 0;
}
.navigation {
  height: calc(100vh - 55px);
  overflow-y: auto;
  padding-bottom: 1rem;
}
</style>