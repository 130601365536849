<template>
    <div
        style="width: 359px"
        :class="{'opacity-60 pointer-events-none': !isMapped}"
        class="pl-1 h-full flex items-center justify-start border-r border-secondary-four"
    >
        <div class="h-4 w-4 mr-2">
            <img v-if="isMapped" :src="getIcon(isMapped.jira.id)" class="h-4 w-4">
        </div>

        <div v-if="isMapped">
            <CheckboxCheckedIcon v-if="!item.notMapped" @click.native="addNotMappedFlag(item, true)"  class="w-4 h-4 i-mr-7 cursor-pointer" />
            <CheckboxIcon v-else @click.native="addNotMappedFlag(item, false)" class="w-4  h-4 i-mr-7 opacity-50 cursor-pointer" />
        </div>
        <CheckboxIcon v-else class="w-4  h-4 i-mr-7 opacity-50 pointer-events-none" />
        <div class="flex items-center gap-4">
            <svg @click="$emit('collapseItem')" class="cursor-pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" stroke="#C9C9C9"/>
                <path v-if="item.childVisibility" d="M10.0683 7.076H7.5123V9.668H6.4923V7.076H3.9483V6.152H6.4923V3.548H7.5123V6.152H10.0683V7.076Z" fill="currentColor"/>
                <path v-else d="M9.17931 6.14V7.064H4.39131V6.14H9.17931Z" fill="currentColor"/>
            </svg>
            <span 
                class="fs-12  cursor-pointer"
                :class="getWeight(item)"
                @click="$emit('openRightPanel')">
                {{ item.index_id }}{{ item.index_code }} {{ item.title }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { set } from 'vue'
import { usePlanning } from '@/views/screens/planning/usePlanning.js';
const props = defineProps({
    item: Object,
    offset: Number,
    hideCheckbox: Boolean,
    isMapped: [Object, Boolean],
    jiraDropdownOptions: Array
});

const { getWeight } = usePlanning();

const getIcon = (id) => {
    if(!props.jiraDropdownOptions || !props.jiraDropdownOptions.length) return
    let item = props.jiraDropdownOptions.find(item => item.id == id)
    return item ? item.icon : ''
}

const addNotMappedFlag = (item, value) => {
    set(item, 'notMapped', value)
    if(item.child.length) {
        item.child.forEach(child => addNotMappedFlag(child, value))
    }
}
</script>