<template>
  <div v-click-outside="() => toggleComments = false" class="relative">
    <Panel height="238px">
      <template #header>
        <div class="flex gap-1 items-center fs-10 fw-800">
          STAT.
          <span
            class="text-white rounded block px-2"
            style="padding: 2px 8px; background: #ad2b81"
          >{{ getIssueStatus(item) }}</span>
        </div>
        <div class="flex gap-1 items-center fs-10 fw-800">
          PROG.
          <span
            class="rounded block px-2 text-white"
            :style="{padding: '2px 8px', background: getColor(item.progress)}"
          >{{ item.progress }}%</span>
        </div>
      </template>

      <template #content>
        <div class="p-4 pb-0">
          <p class="fs-10 fw-700 text-color-616161">
            {{ item.title }}
          </p>
          <p class="fs-14 fw-700 text-primary-one">
            {{ item.instData.title }}
          </p>

          <div class="grid gap-3 mt-3">
            <div class="flex">
              <div class="fs-12 fw-500 flex-shrink-0 text-secondary-two w-24">
                Type:
              </div>
              <div class="fs-12 fw-500 text-primary-one flex items-center gap-1">
                <img 
                  :src="getIssueTypeIcon(item)" 
                  :alt="getIssueType(item)"
                >
                {{ getIssueType(item) }}
              </div>
            </div>
            <div class="flex">
              <div class="fs-12 fw-500 flex-shrink-0 text-secondary-two w-24">
                Assignee:
              </div>
              <div class="fs-12 fw-500 text-primary-one">
                <img 
                  v-if="getAssigneeImage(item)"
                  :src="getAssigneeImage(item)" 
                  class="shadow border border-white rounded-full block object-cover" 
                  style="width: 40px; height: 40px;"
                >
                <span v-else>Unassigned</span>
              </div>
            </div>
            <div class="flex">
              <div class="fs-12 fw-500 flex-shrink-0 text-secondary-two w-24">
                Status:
              </div>
              <div class="fs-12 fw-500 text-primary-one">
                {{ getIssueStatus(item) }}
              </div>
            </div>
            <div class="flex">
              <div class="fs-12 fw-500 flex-shrink-0 text-secondary-two w-24">
                Progress:
              </div>
              <div class="fs-12 fw-500 text-primary-one flex items-center gap-1">
                <svg width="112" height="6" viewBox="0 0 112 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.2" width="112" height="6" rx="3" fill="#2B80AD" />
                  <rect :width="`${getIssueProgress(item)}%`" height="6" rx="3" fill="#2B898F" />
                </svg>
                <span class="fs-12 fw-600 text-primary-one">{{ getIssueProgress(item) }}%</span>
              </div>
            </div>
          </div>

          <div class="flex justify-between gap-2 mt-6">
            <AvatarGroup
              class="origin-top-left"
              :dimension="20"
              :avatars="getMembers(item.instData)"
              :limit="3"
            />
            <button 
              class="-mt-5 p-1 w-6 h-6 flex items-center justify-center rounded" 
              @click="manageComment(item)"
              :class="toggleComments && 'bg-gray-200'"
            >
              <BalIcon class="w-4 h-4" />
            </button>

          </div>
        </div>
      </template>
    </Panel>
    <Comments 
      class="absolute top-full z-50 w-full"
      style="background-color:#fff;margin-top: -30px;"
      v-if="toggleComments"
      :activeId="item.instData.id"
      :comments="commentsList"
      :listType="false"
      :minShowLength="0"
    />
  </div>
</template>

<script setup>
import Panel from "./Panel.vue"
import BalIcon from "../BalIcon.vue"
import AvatarGroup from "@/elements/atom/AvatarGroup.vue"
import { get } from 'lodash'
import { ref } from 'vue'
import useJiraTaskBoard from '@/views/screens/projectManagement/services/useJiraTaskBoard.js'
import Comments from '@/components/InstMenu/right-navigation-drawer/module/Comments.vue'
import { colors } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import { commentsList, handleComments } from '@/views/screens/projectManagement/services/useTaskboardComment.js'

const toggleComments = ref(false)
const { getMembers } = useJiraTaskBoard()


const props = defineProps({
  item: {
    type: Object,
    default: {}
  }
})
const manageComment = (item) => {
  toggleComments.value = !toggleComments.value
  if(!toggleComments.value) return
  handleComments(item.instData)
}
const getColor = (progress) => {
    let range = Math.floor(progress/10)
    return _getColorWithRange(range)
}
const _getColorWithRange = (range) => {
  let color = colors.value.find(item => {
    let ranges = item.range.split("-")
    if(range<0) return true
    return (range >= ranges[0] && range <= ranges[1])
  })
  return color?.color
}

const getIssueType     = (item) => get(item, 'issue.fields.issuetype.name')
const getIssueTypeIcon = (item) => get(item, 'issue.fields.issuetype.iconUrl')
const getIssueStatus   = (item) => get(item, 'issue.fields.status.name')
const getIssueProgress = (item) => get(item, 'issue.fields.progress.progress')
const getAssigneeImage = (item) => get(item, 'issue.fields.assignee.avatarUrls[48x48]')
</script>
