import { isNumber } from 'lodash';
import { format } from 'date-fns'

export default {
    chart: {
        toolbar: {
            show: false
        }
    },
    colors: ['#2B80AD', '#ABA62B', '#8D8D8D'],
    stroke: {
        curve: 'smooth',
        width: 1
    },
    dataLabels: {
        enabled: false
    },
    yaxis: {
        labels: {
            show: true,
            formatter: function (val) {
                if (!isNumber(val)) return
                if (val) return val.toFixed(0)
            }
        },
        // max: 200
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
        },
    },
    grid: {
        show: true,
    },
    xaxis: {
        type: "datetime",
        labels: {
        format: 'MMM yy',
        showDuplicates: false}
    },
    tooltip: {
        x: {
            show: false,
        },
    },
    legend: {
        show: true,
        horizontalAlign: 'left',
        offsetX: 38,
        position: 'top',
    }
}