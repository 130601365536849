export const getMappingStatus = (item, config) => {
    const {i_am, level} = item
    const levelKey = `${i_am}_${level}`
    const { jira_mapping } = config
    
    let mapItem = jira_mapping.find(item => {
        return item.instrat?.key == levelKey
    })
    if(!mapItem) {
        mapItem = jira_mapping.find(item => {
            return item.instrat?.key == i_am
        })
    }
    if(!mapItem || !mapItem.jira?.id) return false
    if(mapItem && mapItem.jira?.id) return mapItem
}