<template>
    <div>
        <div class="flex gap-x-4 items-center mt-3 pl-4">
            <div class="text-white fs-10 fw-600">
                Est. Vs. Planned {{ estVsPlanned }}%
            </div>
            <div class="ml-auto text-white fs-10 fw-600">
                Avg. Plan Effort {{ round(item.avgPlannedEffort, 2) }}%
            </div>
        </div>
        <div class="flex justify-between gap-x-5  h-full">
            <div class="flex-1" style="height: 40px">
                <BaseChar :series="series" :chart-options="{}" />
            </div>
            <slot name="avatar"></slot>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { round } from 'lodash'
    import BaseChar from './BaseChar.vue';
    const props = defineProps({
        item: Object
    });
    const estVsPlanned = computed(() => {
       return round(props.item.avgPlannedEffort - props.item.estimatedEffort, 2)
    });
    const series = computed(() => [
        {
            name: 'Capacity',
            data: props.item.monthlyEfficiency.map(item => {
                return {
                    x: item.date,
                    y: round(item.value, 2)
                }
            })
        }
    ]);
</script>