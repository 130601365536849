<template>
    <div style="width: 266px" class="space-y-8 fs-12 fw-400 text-primary-one">
        <p class="fw-600 fs-18">{{ settingTitle }}</p>
        <table>
            <tr>
                <td class="i-w-181 pb-5">
                    <p>Time Remaining:</p>
                </td>
                <td class="pb-5">
                    <p>{{ remainingDays(plan) }}/{{ getTotalWorkingDay(plan.start_date, plan.end_date) }} Days</p>
                </td>
            </tr>
            <tr>
                <td class="pb-5">
                    <p>Completion vs. Progress:</p>
                </td>
                <td class="pb-5">
                    <p class="text-green-500 fw-700 fs-14">{{ round(plan.avgProgress) }}%</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Risk:</p>
                </td>
                <td>
                    <div
                    style="width: 73px; height: 22px"
                    class="rounded-sm text-white flex justify-center items-center fs-12 bg-primary-four"
                >
                    High
                </div>
                </td>
            </tr>
        </table>
        <div class="grid gap-5">
            <slot>
                <LockIconEditableField
                    :item="plan"
                    title="Weekend and holidays:"
                    model_key="weekend_holiday_adjustment"
                    :updatePlanDebounce="localUpdatePlanDebounce"
                />

                <LockIconEditableField
                    :item="plan"
                    title="Working hours:"
                    :showPercent="false"
                    isLocked
                    model_key="working_hours"
                    :updatePlanDebounce="localUpdatePlanDebounce"
                />

                <LockIconEditableField
                    :item="plan"
                    title="Dedication:"
                    model_key="dedication"
                    :updatePlanDebounce="localUpdatePlanDebounce"
                />
            </slot>
            <div :class="itemWrapperClass">
                <p>Notify Team:</p>
                <div class="r-item-width">
                    <SwitchComponent
                        :value="true"
                        style="transform: scale(.6)" 
                        class="transform"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { round } from 'lodash'
import LockIconEditableField from './LockIconEditableField.vue'
import SwitchComponent from '@/elements/atom/SwitchComponent.vue'
import { dateCalculation } from '@/composable/timeline/dateCalculation.js'
import { useProjectManagement  } from '@/views/screens/projectManagement/services/useProjectManagement.js'

const props = defineProps({
    plan: Object,
    settingTitle: String
})
const itemWrapperClass = 'flex items-center justify-between'
const { updatePlanDebounce } = useProjectManagement()
const { remainingDays, getTotalWorkingDay } = dateCalculation(props.plan);
const emit = defineEmits(['changeData']);
const localUpdatePlanDebounce = (id, data) => {
    emit('changeData');
    updatePlanDebounce(id, data);
}
</script>

<style scoped>
    .r-item-width{
        width: 91px;
    }
</style>