import { ref, computed } from 'vue';
import Timeline from './Timeline.vue';
import Capacity from './Capacity.vue';
import CostEst from './CostEst.vue';

export const useProgressPanel = () => {
    const components = ref([
        {
            title: 'Timeline',
            component: Timeline,
            active: true
        },
        {
            title: 'Capacity',
            component: Capacity,
            active: false
        },
        {
            title: 'Cost Est',
            component: CostEst,
            active: false
        }
    ])
    const activeIndex = ref(0);

    const activeComponent = computed(() => components.value[activeIndex.value].component);


    return {
        activeIndex,
        components,
        activeComponent
    }
}