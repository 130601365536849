<template>
  <div
    class="_customShadow border border-secondary-four overflow-y-auto"
    style="border-radius: 6px"
  >
    <div
      class="header px-4 border-b border-secondary-four flex items-center justify-between gap-1 h-10"
      style="background: #f9f9f9"
    >
      <div class="flex items-center gap-3">
        <slot name="header"></slot>
      </div>
      <button class="flex items-center gap-2">
        <slot name="action"></slot>
      </button>
    </div>

    <div class="bg-white" :style="{height}">
        <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
defineProps({
    height: String
})
</script>

<style scoped>
._customShadow {
  box-shadow: 0px 1px 6px rgba(44, 52, 65, 0.1);
}
</style>
