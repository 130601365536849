<template>
    <div class="h-full w-full flex items-center justify-center">
        <InputCell v-if="editable" class="text-primary-four" v-model="localEfficiency" />
        <span v-else>{{ localEfficiency }}</span>
    </div>
</template>

<script setup>
import { find } from 'lodash';
import { useProjectManagement } from '@/views/screens/projectManagement/services/useProjectManagement.js'
import InputCell from '@/views/screens/projectManagement/components/InputCell.vue';
import { computed } from 'vue';
const props = defineProps({
    month: String,
    item: Object,
    editable: Boolean
});
const { updateResource } = useProjectManagement();
const emit = defineEmits(['changeEfficiency'])
const localEfficiency = computed({
    get: function () {
        let obj = find(props.item.monthlyEfficiency, ['date', props.month]);
        if (obj) {
            return `${obj.value}%`;
        }
        return ''
    },
    set(value) {
        const trimVal = extractNumbers(value);
        const { item, month } = props;
        const monthly_efficiency = item.monthly_efficiency;

        const objIndex = monthly_efficiency.findIndex(obj => obj.date === month);
        const obj = objIndex !== -1 ? monthly_efficiency[objIndex] : undefined;

        if (obj && trimVal) {
            obj.value = trimVal;
        } else if (obj && !trimVal) {
            monthly_efficiency.splice(objIndex, 1);
        } else if (trimVal) {
            monthly_efficiency.push({ date: month, value: trimVal });
        } else {
            return;
        }

        //   console.log(obj || { date: month, value: trimVal }, 'initial');
        updateResource(item);
        emit('changeEfficiency', item);
    }
});

const extractNumbers = (input) => {
    if (!input) return undefined
    const regex = /^[-+]?(\d*\.)?\d+/;
    const match = String(input).match(regex);
    if (match) {
        let number = Number(match[0])
        return number ? number : undefined;
    }
    return undefined;
}
</script>