<template>
    <div v-click-outside="() => toggleDropdown = false" class="relative w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
        <div @click="toggleDropdown = !toggleDropdown" class="selected flex gap-2 items-center cursor-pointer relative">
            {{ mapItem.instrat.value }}
        </div>
        <div v-if="toggleDropdown" class="absolute z-10 max-h-44 overflow-y-auto shadow border flex flex-col justify-start top-full left-0 bg-white rounded-b">
            <div
                v-for="(option, i) in getOptions"
                :key="i"
                :value="option.value"
                class="flex gap-2 items-center w-full px-3 py-1 cursor-pointer hover:bg-black hover:bg-opacity-5"
                :class="option.isUsed && 'pointer-events-none opacity-60'"
                @click="handleDropdown(mapItem, option, index)"
            >
                {{ option.value }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue'
    import useMapping from '@/components/planning/integration/jira/useMapping.js'

    const { cleanBottomMap } = useMapping()
    const props = defineProps({
        index: [Number, String],
        mapItem: Object
    })

    const toggleDropdown = ref(false)
    const selectedItem = ref({
        value: "Nothing Selected"
    })

    const handleDropdown = (mapItem, option, index) => {
        toggleDropdown.value = false
        mapItem.instrat.key = option.key
        mapItem.instrat.value = option.value
        mapItem.instrat.serial = option.serial

        selectedItem.value = option

        cleanBottomMap(index)
    }

    const getOptions = computed(() => {
        return props.mapItem.instrat.options
    })

    onMounted(() => {
        if(props.mapItem.instrat.value){
            selectedItem.value.value = props.mapItem.instrat.value
        }
    })
</script>

<style scoped>
    .shadow{
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
</style>