<template>
    <div>
        <div class="flex gap-x-4 items-center mt-3 pl-4">
            <div class="flex items-center gap-2">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.34375 12.6562V2.8125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.34375 9.84364C6.09375 7.03114 8.90625 12.6561 12.6562 9.84364V2.81239C8.90625 5.62489 6.09375 -0.000113044 2.34375 2.81239" fill="white"/>
                    <path d="M2.34375 9.84364C6.09375 7.03114 8.90625 12.6561 12.6562 9.84364V2.81239C8.90625 5.62489 6.09375 -0.000113044 2.34375 2.81239" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <div class="text-white fs-10 fw-600">
                    {{ getProgressDefinition(round(item.avgProgress, 2)) }}
                </div>
            </div>
            <div class="text-white fs-10 fw-600">
                PROG. {{ round(item.avgProgress, 2) || 0 }}%
            </div>
            <div class="ml-auto text-white fs-10 fw-600">
                CMPL. <span class="inline-block px-1 rounded border border-white bg-success">{{ round(item.totalCompletion, 2) || 0 }}%</span>
            </div>
        </div>
        <div class="flex justify-between gap-x-5  h-full">
            <div class="flex-1" style="height: 48px">
                <BaseChar :series="series" :chart-options="{}" />
            </div>
            <slot name="avatar"></slot>
        </div>
    </div>
</template>

<script setup>
    import BaseChar from './BaseChar.vue';
    import { computed } from 'vue';
    import { round, isEmpty } from 'lodash';
    import { useProjectManagement } from '@/views/screens/projectManagement/services/useProjectManagement.js'
    const props = defineProps({
        item: Object
    });
    const { getProgressDefinition } = useProjectManagement();

    const series = computed(() => {
        return [
            {
                name: 'Progress',
                data: !isEmpty(props.item.progressHistory) ?  props.item.progressHistory.map(i => ({x: i.date, y: round(i.value, 2)})) : []
            }
        ]
    });
</script>