<template>
    <div class="property-body mt-8 fs-14 text-secondary-one" v-if="activeScenarioCollection && activeScenarioCollection.id">
      <div class="mb-6 relative">
        <img v-if="previewURL" :src="previewURL" alt="image" class="block w-full _my_img_size">
        <img v-else :src="(activeScenarioCollection.file) ? activeScenarioCollection?.file?.file_path : '/images/build/demo_image_1.jpg'" alt="image" class="block w-full _my_img_size">
        <label v-if="canEdit(project.cans)" class="absolute right-1 bottom-1 property-image-icon cursor-pointer">
          <input @input="(e)=>{onChange(e), updateImage(
              {file, id:activeScenarioCollection.id},
                ()=>{
                  activeScenarioCollection.file = {file_path: previewURL}
                  previewURL = ''
                }
              )}"
              :disabled="!canEdit(project.cans)"
              hidden type="file" accept="image/x-png,image/jpeg" />
  
          <button class="pointer-events-none">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
              <path d="M0 12.3477H12V10.3477H0V12.3477ZM0 7.34766H18V5.34766H0V7.34766ZM0 2.34766H12V0.347656H0V2.34766Z" fill="#E2E2E2"/>
            </svg>
          </button>
        </label>
      </div>
      <div class="mb-10" v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0">
        <div class="flex items-center">
          <span class="text-primary-one ls-5 font-poppins fs-14 fw-600" v-if="!activeScenarioCollection.is_module">{{ activeScenarioCollection.index_code }}</span>
          <input :disabled="!canEdit(project.cans)" type="text" class="focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-primary-one fw-600 ls-5 font-poppins fs-14 truncate"
                 :class="{'ml-1': !activeScenarioCollection.is_module}"
                 v-model="activeScenarioCollection.title"
                 @keyup="updateItem(activeScenarioCollection)" />
        </div>
        <TextareaAutoHeight
          v-if="activeScenarioCollection && Object.keys(activeScenarioCollection).length !== 0"
          v-model="activeScenarioCollection.description"
          :isDisabled="!canEdit(project.cans)"
          @onType="updateItem(activeScenarioCollection)"
        />
      </div>
      <router-link :to="{path: '/build/'+$route.params.id+'/properties/'+activeScenarioCollection.module_id+'/'+activeScenarioCollection.id}" :event="activeScenarioCollection && activeScenarioCollection.module_id && activeScenarioCollection.id ? 'click' : ''" :title="!activeScenarioCollection.id ? 'Please, select an item first' : ''">
        <div class="flex justify-between cursor-pointer items-center pb-3 mb-8 i-border-b-1 border-secondary-two">
          <p class="fw-500 fs-12 text-secondary-two">
            Manage properties and Association
          </p>
          <img src="/images/icons/build/manage_property.svg" alt="icon" />
        </div>
      </router-link>
  
      <slot />
  
      <div
        class="p-0 bg-transparent relative"
        v-if="activeScenarioCollection && activeItemProperties && activeItemProperties.length > 0"
    >
    <!-- v-for="(dataset, index) in properties.filter(el => el.is_visible)" 
    :key="index" -->
        <div class="i-border-b-1 border-secondary-two i-mb-30" 
        >
        <!-- <input type="text" v-model="dataset.title" class="bg-transparent text-primary-one fw-600 w-full" @input="updateProperty(dataset)" /> -->
          <div class="flex justify-between items-center cursor-pointer i-pb-25 fw-600">
            <p class="bg-transparent text-primary-one fw-600 w-full">INST Standard Property</p>
            <!-- <input type="text" v-model="dataset.title"  @input="updateProperty(dataset)" /> -->
            <img :id="'down-arrow-'+index" class="p-2" src="@/assets/images/icons/build/chevron_down.svg" alt="image" @click="statusShow(index)">
            <img :id="'up-arrow-'+index" class="p-2 hidden" src="@/assets/images/icons/build/chevron_up.svg" alt="image" @click="statusHide(index)">
          </div>
          <div class="hidden" :id="'value-cont-'+index">
            <div class="flex justify-start items-center i-mb-25" 
                v-for="(prop, pindex) in properties.filter(el => el.is_visible)" 
                :key="pindex" @mouseover="showValue(index, pindex)" 
                @mouseleave="hideValue(index, pindex)"
            >
              <input type="text" v-model="prop.title" class="bg-transparent prop-items i-mr-15" @input="updateParameter(prop)" />
                <property-sample 
                    v-if="!prop.option || prop.option == 1"
                    :property="prop"
                    :index="index"
                    :p-index="pindex"
                    :item="activeScenarioCollection"
                    @updateParameterData="updateParameterData"
                />
                <input-property v-if="prop.option == 2"
                    :property="prop"
                    :index="index"
                    :p-index="pindex"
                    :item="activeScenarioCollection"
                    :disabled="!canEdit(project.cans)"
                    @updateParameterData="updateParameterData"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
    import axios from 'axios'
    import TextareaAutoHeight from '@/elements/inputs/TextareaAutoHeight'
    import { useFile } from '@/composable/useFile.js'
    import usePermission from '@/composable/usePermission.js'
    const { canDelete, canCreate, canEdit } = usePermission()
  
    const { onChange, previewURL, file } = useFile()
  </script>
  
  <script>
  import {mapActions, mapGetters} from 'vuex';
  import PropertySample from "@/components/properties/PropertySample.vue";
  import InputProperty from "@/components/properties/InputProperty";
  export default {
    name: "ScenarioProperty",
    components: {InputProperty, PropertySample},
    props: {
      activeScenarioCollection: Object,
      activeModule: Object,
    //   properties: Array,
      project: Object
    },
    data:() => ({
        index: 0
    }),
    computed: {
      activeItemProperties() {
        if(this.activeScenarioCollection.is_module) {
          return this.activeScenarioCollection.temp_properties_value;
        }
        return this.activeScenarioCollection.properties;
      },

      properties() {
        if(this.activeModule) return this.activeModule.system_parameters
        return []
      }
    },
    methods: {
      ...mapActions({
        loadModuleData: "roadmap/loadModuleData"
      }),
      async updateImage({file, id}, callback) {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('image', file)
  
  
        let objData = Object.assign({}, this.activeScenarioCollection);
        if(objData.i_am == 'module'){
          await axios.post('modules/image', formData)
        }else{
          await axios.post('projects/scenarios/collections/image', formData)
        }
  
        callback()
      },
      statusShow(index) {
        let cont = document.getElementById('value-cont-'+index)
        let up = document.getElementById('up-arrow-'+index)
        let down = document.getElementById('down-arrow-'+index)
        cont.style.display = 'block'
        up.style.display = 'block'
        down.style.display = 'none'
      },
      statusHide(index) {
        let cont = document.getElementById('value-cont-'+index)
        let up = document.getElementById('up-arrow-'+index)
        let down = document.getElementById('down-arrow-'+index)
        cont.style.display = 'none'
        up.style.display = 'none'
        down.style.display = 'block'
      },
      showValue(index, pindex) {
        let value = document.getElementById('value-hover-'+index+'-'+pindex)
        value.style.display = 'block'
      },
      hideValue(index, pindex) {
        let value = document.getElementById('value-hover-'+index+'-'+pindex)
        value.style.display = 'none'
      },
      updateParameterData(obj) {
        obj.sc_id = this.activeScenarioCollection.id;
        obj.id = obj.property_collection_id;
        obj.value = obj.sample_value;
        obj.module_id = this.activeScenarioCollection.module_id;
        obj.module_collection_id = this.activeScenarioCollection.module_collection_id;
        obj.scenario_id = this.activeScenarioCollection.scenario_id;
  
        let itemParameter = this.activeScenarioCollection.properties.find(data => parseInt(data.property_collection_id) === parseInt(obj.property_collection_id));
        if(!itemParameter) {
          this.activeScenarioCollection.properties.push({
            property_collection_id: obj.property_collection_id,
            scenario_collection_id: this.activeScenarioCollection.id,
            module_collection_id: this.activeScenarioCollection.module_collection_id,
            sample_value: obj.sample_value,
            module_id: this.activeScenarioCollection.module_id
          });
        }
        this.$store.dispatch("programmatic/updatePropertyItem", obj)
            .then(response => {
              if (response) {
                this.loadAModuleData(this.activeModule);
              }
            });
      },
      loadAModuleData(moduleId) {
        this.$emit("loadAModuleData", moduleId);
      },
      updateItem(obj, e = null) {
        let objData = Object.assign({}, obj);
        if(e) {
          objData.description = e.target.innerText;
        }
        if(objData.is_module) {
          let data = {
            id: objData.id,
            title: objData.title,
            description: objData.description
          }
          this.$store.dispatch("build/updateModule", data);
        } else {
          this.$store.dispatch("programmatic/updateCollection", objData);
        }
      },
      updateProperty(property) {
        this.$store.dispatch("manageProperty/updateProperty", {
          id: property.id,
          title: property.title,
          module_id: property.module_id
        });
      },
      updateParameter(parameter) {
        this.$store.dispatch("manageProperty/updatePropertyCollectionItem", {
          id: parameter.id,
          title: parameter.title
        });
      }
    },
  }
  </script>
  
  <style scoped>
  .property-body {
    max-height: 907px;
    min-height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .prop-items {
    width: 150px;
  }
  </style>
  