<template>
  <div>
    <div class="p-10 grid gap-4">
        <label class="grid gap-1">
          Project Name
          <input
            type="text"
            required="required"
            class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
            placeholder="Project Name"
            v-model="payload.name"
          />
        </label>
        <label class="grid gap-1 relative">
          Project Key
          <input
            type="text"
            required="required"
            class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
            placeholder="Project Key"
            v-model="payload.key"
            @input="validateProjectKey"
          />
          <span v-if="!isEmpty(projectKeyValidationMsg)" class="fs-10 text-red-500 absolute top-full">{{ projectKeyValidationMsg }}</span>
        </label>

        <label class="grid gap-1">
            Project Type Key
            <select
                required="required"
                class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
                v-model="payload.projectTypeKey"
            >
                <option :value="null" selected>Select Key</option>
                <option v-for="(item, index) in projectTypeKey" :value="item.key" :key="index">{{ item.formattedKey }}</option>
            </select>
        </label>

        <input
          type="hidden"
          required="required"
          value="PROJECT_LEAD"
          v-model="payload.assigneeType"
          class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
        />
        
        <label class="grid gap-1">
            Lead Account
            <select
                required="required"
                class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
                v-model="payload.leadAccountId"
            >
                <option :value="null" selected>Select Account</option>
                <option v-for="(item, index) in leadAccountIds" :value="item.accountId" :key="index">{{ item.displayName }}</option>
            </select>
        </label>

        <div class="flex gap-x-4 mt-5">
            <button 
                class="fs-14 fw-500 rounded shadow-one border border-primary-one bg-primary-one text-white" 
                :class="!isEmpty(projectKeyValidationMsg) && 'pointer-events-none opacity-50'"
                style="height: 38px; width: 100px;"
                @click="handleCreate(projectKeyValidationMsg, payload)"
            > 
                Create
            </button>
            <button @click="$emit('close')" class="fs-14 fw-500 rounded shadow-one border border-primary-one" style="height: 38px; width: 100px;"> Cancel </button>
        </div>
    </div>

  </div>
</template>

<script setup>
    import useProjectCreate from './useProjectCreate'
    import { isEmpty } from 'lodash'

    const { 
        leadAccountIds, 
        projectTypeKey, 
        validateProjectKey, 
        projectKeyValidationMsg, 
        payload,
        handleCreate
    } = useProjectCreate()
</script>

<style lang="scss" scoped></style>
