import { ref, computed } from "vue"
import useJira from '@/components/planning/integration/jira/useJira.js'
import { modalComponents as components, ModalWrapper } from './jira/useImporter';

const navigator = ref({
    showModal: false,
    component: 'SelectProjectManagementTools'
})

export default function useIntegration(){
    const configuration = {
        SelectProjectManagementTools: {
            showDots: false,
            title: 'Integrate Plan',
            dots: 0,
            showClose: true
        },
        ConfigureJira: {
            showDots: true,
            title: null,
            dots: 1,
            showClose: true
        },
        SelectJiraProject: {
            showDots: true,
            title: null,
            dots: 2,
            showClose: true
        },
        ConfigureMapping: {
            showDots: true,
            title: null,
            dots: 3,
            showClose: true
        },
        DataSynchronized: {
            showDots: true,
            title: null,
            dots: 4,
            showClose: true
        },
        AdditionalSettings: {
            showDots: false,
            title: null,
            dots: 6,
            showClose: true
        },
        Prepare: {
            showDots: false,
            title: null,
            dots: 7,
            showClose: true
        },
        Success: {
            showDots: false,
            title: null,
            dots: 8,
            showClose: true
        },
    }

    const activeModalComponent = computed(() => components[navigator.value.component]);
    const currentModalConfig = computed(() => configuration[navigator.value.component])

    const toggleModal = (value) => {
        navigator.value.showModal = value
    }

    const switchModalComponent = (value, data) => {
        navigator.value.component = value
    }

    const { 
        jiraForm,
        storeJiraConfig,
        handleSprintChange,
        handleJiraConfig, 
        jiraLoader,
        getJiraConfig,
        getJiraProjects,
        jiraProjects,
        getJiraBoards,
        jiraBoards,
        updateJiraConfig,
        getSprints,
        sprints,
        createJiraBulkIssue,
        loader,
        getJiraVersions,
        versions
    } = useJira(switchModalComponent)

    return {
        navigator,
        toggleModal,
        switchModalComponent,
        activeModalComponent,
        ModalWrapper,
        currentModalConfig,
        storeJiraConfig,
        jiraForm,
        handleSprintChange,
        jiraLoader,
        getJiraConfig,
        getJiraProjects,
        jiraProjects,
        getJiraBoards,
        jiraBoards,
        handleJiraConfig,
        updateJiraConfig,
        getSprints,
        sprints,
        createJiraBulkIssue,
        loader,
        getJiraVersions,
        versions
    }
}