export const avatars = [
    {
        name: 'user one',
        file_path:'https://instrat360-dev.s3.eu-central-1.amazonaws.com/profile/images/eNQNNkpJKIBl5mzWnaDlcbH29UlY9CpSOlelS8h2.jpg'
    },
    {
        name: 'user two',
        file_name: 'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    },
    {
        name: 'user three',
        file_name: 'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
    },
    {
        name: 'user four',
        file_name: 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'user five',
        file_name: 'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    }
]


export const series = [
    {
        name: 'Progress',
        data: [
            {
                x:"2023-02-21",
                y:3.06

            },
            {
                x:"2023-02-22",
                y:2.06

            },
            {
                x:"2023-02-23",
                y:20.06

            },
            {
                x:"2023-02-24",
                y:23.06

            },
            {
                x:"2023-02-25",
                y:27.06

            },{
                x:"2023-02-26",
                y:0
            },{
                x:"2023-02-27",
                y:0

            },{
                x:"2023-02-28",
                y:0

            },
        ]
    }

]