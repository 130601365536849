<template>
    <div class="px-6 mt-4">
        <div class="flex justify-between items-center pb-4 border-b border-secondary-four cursor-pointer">
          <h1 @click="integrationToggle = !integrationToggle" class="fw-700 fs-14 uppercase">Integration</h1>
          <button @click="integrationToggle = !integrationToggle" class="cursor-pointer">
            <svg class="transform" :class="integrationToggle ? 'rotate-0' : 'rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75098 12.5L10.001 6.25L16.251 12.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div v-if="integrationToggle" class="mt-5">
          <div class="mb-2 flex justify-end" @click="toggleModal(true)">
            <WrenchIcon class="cursor-pointer" />
          </div>

          <div class="flex gap-2 items-center fs-14 fw-600 mb-3">
            <JiraIssuesIcon />
            Jira Issues
          </div>

          <div class="grid gap-4">
            <div class="flex gap-4 items-center">
              <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">INS TASK NAME:</span>
              <span class="fw-600 fs-12 text-primary-one">{{ jiraIssue && jiraIssue.fields && jiraIssue.fields.summary ? jiraIssue.fields.summary : 'N/A' }}</span>
            </div>
<!--            <div class="flex gap-4 items-center">-->
<!--              <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Linked Jira Issue:</span>-->
<!--              <span class="fw-600 fs-12 text-primary-one">EPIC-22 Implement Frontend</span>-->
<!--              <button>-->
<!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M7.24219 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6919C3.19085 16.5747 3.125 16.4157 3.125 16.2499V12.7577C3.12472 12.6766 3.14044 12.5962 3.17128 12.5211C3.20211 12.446 3.24745 12.3778 3.30469 12.3202L12.6797 2.94524C12.7378 2.88619 12.8072 2.83929 12.8836 2.80728C12.9601 2.77527 13.0421 2.75879 13.125 2.75879C13.2079 2.75879 13.2899 2.77527 13.3664 2.80728C13.4428 2.83929 13.5122 2.88619 13.5703 2.94524L17.0547 6.42962C17.1137 6.48777 17.1606 6.55709 17.1927 6.63355C17.2247 6.71 17.2411 6.79205 17.2411 6.87493C17.2411 6.95781 17.2247 7.03987 17.1927 7.11632C17.1606 7.19277 17.1137 7.26209 17.0547 7.32024L7.67969 16.6952C7.62216 16.7525 7.55391 16.7978 7.47884 16.8287C7.40376 16.8595 7.32335 16.8752 7.24219 16.8749V16.8749Z" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M10.625 5L15 9.375" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--            </div>-->
            <div class="flex gap-4 items-center">
                <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Type:</span>
                <span class="fw-600 fs-12 text-primary-one flex">
                  <img :src="jiraIssue.fields.issuetype.iconUrl" alt="" class="mr-1 w-4 h-4" v-if="jiraIssue && jiraIssue.fields && jiraIssue.fields.issuetype && jiraIssue.fields.issuetype.iconUrl">
                  {{ jiraIssue && jiraIssue.fields && jiraIssue.fields.issuetype ? jiraIssue.fields.issuetype.name : 'N/A' }}
                </span>
            </div>
<!--            <div class="flex gap-4 items-center">-->
<!--                <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Sprint:</span>-->
<!--                <span class="fw-600 fs-12 text-primary-one">Discovery Sprint</span>-->
<!--            </div>-->
            <div class="flex gap-4 items-center">
              <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Priority:</span>
              <span class="fw-600 fs-12 text-primary-one flex items-center gap-1">
                  <img :src="jiraIssue.fields.priority.iconUrl" alt="" class="mr-1 w-4 h-4" v-if="jiraIssue && jiraIssue.fields && jiraIssue.fields.priority && jiraIssue.fields.priority.iconUrl">
                  {{ jiraIssue && jiraIssue.fields && jiraIssue.fields.priority ? jiraIssue.fields.priority.name : 'N/A' }}
                </span>
            </div>
            <div class="flex gap-4 items-center">
                <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Status:</span>
                <span class="fw-600 fs-12 text-primary-one flex items-center gap-1">
                  {{ jiraIssue && jiraIssue.fields && jiraIssue.fields.status ? jiraIssue.fields.status.name : 'N/A' }}
                </span>
            </div>
            <div class="flex gap-4 items-center">
              <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Creator:</span>
              <span class="fw-600 fs-12 text-primary-one flex items-center gap-1">
                  <img :src="jiraIssue.fields.creator.avatarUrls['24x24']" alt="" class="mr-1" style="border-radius: 50%" v-if="jiraIssue && jiraIssue.fields && jiraIssue.fields.creator && jiraIssue.fields.creator.avatarUrls">
              </span>
            </div>
<!--            <div class="flex gap-4 items-center">-->
<!--                <span class="fw-500 fs-12 text-secondary-two w-28 capitalize">Progress:</span>-->
<!--                <span class="fw-600 fs-12 text-primary-one flex items-center gap-2">-->
<!--                    <svg width="140" height="6" viewBox="0 0 140 6" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <rect opacity="0.2" width="140" height="6" rx="3" fill="#2B80AD"/>-->
<!--                        <rect width="91" height="6" rx="3" fill="#2B898F"/>-->
<!--                    </svg>-->
<!--                    70%-->
<!--                </span>-->
<!--            </div>-->
          </div>
        </div>
    </div>
</template>

<script setup>
import JiraIssuesIcon from '@/components/planning/Icons/JiraIssuesIcon.vue'
import WrenchIcon from '@/components/planning/rightPanelComponents/Icons/WrenchIcon'
import useIntegration from '@/components/planning/integration/useIntegration.js'
import { ref, watchEffect } from 'vue'
import axios from 'axios'
import router from '@/routes/routes.js';

const props = defineProps({
  selectedItem: Object
})
const route = router.currentRoute;
const jiraIssue = ref(null)
const { toggleModal } = useIntegration()
const integrationToggle = ref(false)

//refactor
watchEffect(async ()=> {
  if(props.selectedItem.jira_link?.key) {
    const { data } = await axios.get(`/services/jira/issues?project_id=${route.params.projectId}&jira_issue_key=${props.selectedItem.jira_link.key}`)
    jiraIssue.value = data.data
  } else {
    jiraIssue.value = null;
  }
})
</script>
