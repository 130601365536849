import { ref, set, computed, watch } from 'vue';
import { transformPlanData } from '@/views/screens/projectManagement/services/usePlan.js'
import { collapseAllRow as collapseRow, getLastLevelsUnderModule } from '@/views/screens/projectManagement/services/utils/_filter.js'
import axios from 'axios';
import { cloneDeep } from 'lodash'
import router from '@/routes/routes.js';

const weightSet = {
    plan: 'fw-800',
    project: 'fw-700',
    module: 'fw-700',
    level: {
        1: 'fw-600',
        2: 'fw-500',
        3: 'fw-400',
        4: 'fw-400',
        5: 'fw-400',
    },
    task: {
        1: 'fw-500',
        2: 'fw-500',
        3: 'fw-500',
        4: 'fw-400'
    }
}




export const usePlanning = () => {
    const settings = ref({
        planColor: '#2BAD7E',
        projectColor: '#2BAD7E',
        moduleColor: '#3485AF',
        taskColor: '#2BAD7E',
        periodColor: '#2B80AD',
        simpleMode: false,
        isExpanded: false,
        activePlans: false,
        titleColumn: {
            width: '320px',
            display: 'flex',
            minimize: false,
            hidden: false
        },
        dataColumn: {
            width: '500px',
            display: 'flex',
            minimize: false,
            hidden: false
        },
        parameterColumn: {
            width: 'auto',
            display: 'flex',
            minimize: false,
            hidden: false
        },
        detailColumn: {
            width: '200px',
            display: 'flex',
            minimize: false,
            hidden: false
        }
    });
    const localData = ref([]);
    const localDataCopy = ref([]);
    
    const isolated = ref(false);
    const route = router.currentRoute;
    const projectId = computed(() => route.params.projectId);

    const updateColumnWidth = (key, width, sync = true) => {
        settings.value[key].minimize = Boolean(width == 47);
        settings.value[key].width = `${width}px`;
        if(sync) {
            updateProjectSettings(settings.value)
        }
    }
    
    const updateColumnVisibility = (key, property) => {
        settings.value[key].hidden = Boolean(property == 'none');
        settings.value[key].display = property;
        updateProjectSettings(settings.value);
    }
    
    const updateProjectSettings = async (payload = null) => {
        let localSettings = payload || settings.value;
        await axios.post(`planning/settings`, {
            project_id: projectId.value,
            settings: localSettings
        });
    }

    const collapseAllRow = (value, config) => {
        collapseRow(localData.value, value);
        if(config.sync) {
            updateProjectSettings(settings.value);
        }
    }

    const onlyTask = (state, config) => {
        localData.value  = !state ? cloneDeep(localDataCopy.value) : getLastLevelsUnderModule(localData.value, item => item.is_last_level);
        if(config.sync) {
            updateProjectSettings()
        }
    }

    const activePlans = (state, config) => {
        localData.value  = !state ? cloneDeep(localDataCopy.value) : localData.value.filter(item => item.status == 1);
        if(config.sync) {
            updateProjectSettings()
        }
    }

    const makeIsolation = (item) => {
        set(item, 'isolated', !item.isolated);
        isolated.value = !isolated.value;
        let collection = item;
        if(item.i_am != 'plan') {
            collection = _isolateData(localData.value, item);
        }
        /*
            item: {
                parent_ids: [1, 2, 3, 4]
                parent_ids: [1, 3, 4]
            }
        
        */


        localData.value = isolated.value ? [collection] : cloneDeep(localDataCopy.value);
    }
    const _getParentId = (item) => {
        const dataSet = {
            module: 'implementation_planning_project_id',
            project: 'implementation_planning_id',
            level: 'module_id'
        }
        return item[dataSet[item.i_am]];
    }

    const _isolateData = (collections, item) => {
        const findObjectById = (data, targetId) => {
            for (const object of data) {
                let key = object.i_am == 'module' ? 'module_id' : 'id'
                if (object[key] === targetId) {
                    object.child = [item]; // item.hidden = true
                    return object;
                } else if (object.child) {
                    const foundObject = findObjectById(object.child, targetId);
                    if (foundObject) {
                        return foundObject;
                    }
                }
            }
            return null;
        };

        let parent = findObjectById(collections, _getParentId(item));

        if (parent && parent.i_am != "plan") {
            return _isolateData(collections, parent);
        }
        return parent;
    }

    const getWeight = (item) => {
        
        if(['plan', 'project', 'module'].includes(item.i_am)) {
            return weightSet[item.i_am];
        }
        if(item.i_am == 'level') {
            return weightSet[item.i_am][item.level] || 'fw-400';
        }
        if(item.i_am == 'task') {
            return weightSet[item.i_am][item.level] || 'fw-400';
        }
        return 'fw-400'
   }


    return {
        getWeight,
        makeIsolation,
        updateColumnWidth,
        updateColumnVisibility,
        updateProjectSettings,
        collapseAllRow,
        onlyTask,
        activePlans,
        localDataCopy,
        settings,
        localData,
        projectId
    }
}