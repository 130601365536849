import { first, last, groupBy, isEmpty, values } from 'lodash'
import { lastDayOfMonth, differenceInDays, addDays, addQuarters, format as dateFormat } from 'date-fns'
import { dateDefer, maxDate, minDate, findMinMaxDate } from '@/plugins/lodash.js'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
import { _listQuarters } from './utils';
import { ref } from 'vue';
export const useTimeline = () => {
    const timelineParent = ref(null);

    const prepareTimeline = (data) => {
        const startDate = minDate(data.map(item => item.start_date)) || null;
        let endDate = maxDate(data.map(item => item.end_date)) || null;
        if (!startDate && !endDate) {
            return {
                months: [],
                quatre: [],
                years: []
            }
        }
        let monthArray = [];
        let yearArray = [];
        let datFrom = new Date(startDate);
        let datTo = new Date(endDate);
       
        let quarters = _listQuarters(datFrom, datTo);
        if(quarters && quarters.length < 5) {
            let remaining = 5 - quarters.length;
            datTo = addQuarters(datTo, remaining);
        }
    
        let fromYear = datFrom.getFullYear();
        let toYear = datTo.getFullYear();
        let diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

        let startMonth = datFrom.getMonth() - datFrom.getMonth() % 3;

        let totalMonth = diffYear - startMonth + 1;

        if (!(Number.isInteger(totalMonth / 3))) {
            let yy = (totalMonth / 3).toString().split(".")[1].slice(0, 2);
            diffYear += yy == '33' ? 2 : 1;
        }
        for (let i = startMonth; i <= diffYear; i++) {
            let year = Math.floor(fromYear + (i / 12))
            monthArray.push({
                month: months[i % 12],
                year
            });
            yearArray.push(year);
        }
        return {
            months: monthArray,
            quatre: quarters,
            years: values(groupBy(yearArray)).map(d => ({ year: d[0], count: d.length }))
        };
    }
    const getRef = (el) => {
        timelineParent.value = el;
    }
    const getTimelineWidth = () => {
        if (!timelineParent.value) return 0
        return timelineParent.value.offsetWidth
    }
    const _getTimelineStartDate = (_timeline) => {
        if(isEmpty(_timeline.months) || isEmpty(_timeline.years)) {
            return;
        }
        return new Date(`1 ${first(_timeline.months).month} ${first(_timeline.years).year}`)
    }
    const _getTimelineEndDate = (_timeline) => {
        if(isEmpty(_timeline.months) || isEmpty(_timeline.years)) {
            return;
        }
            return lastDayOfMonth(new Date(`1 ${last(_timeline.months).month} ${last(_timeline.years).year}`))
    }
    const _datePerPixel = (dates) => {
        return getTimelineWidth() / dates;
    }
    const _dateToPixel = (timelineTotalDate, dates) => {
        return _datePerPixel(timelineTotalDate) * dates
    }

    const getLeft = (_timeline, item) => {
        if (!_timeline || !item) return 0
        const timelineStartDate = _getTimelineStartDate(_timeline)
        const timelineEndDate = _getTimelineEndDate(_timeline)

        const timelineTotalDate = differenceInDays(timelineEndDate, timelineStartDate)
        const { start_date: startDate, end_date: endDate } = item
        const startDateDiff = differenceInDays(new Date(startDate), timelineStartDate)
        let left = _dateToPixel(timelineTotalDate, startDateDiff)
        return left
    }

    const getWidth = (_timeline, item) => {
        const timelineStartDate = _getTimelineStartDate(_timeline)
        const timelineEndDate = _getTimelineEndDate(_timeline)
        const timelineTotalDate = differenceInDays(timelineEndDate, timelineStartDate)
        const { start_date: startDate, end_date: endDate } = item
        const itemTotalDate = dateDefer(startDate, endDate)
        let width = _dateToPixel(timelineTotalDate, itemTotalDate) || 1

        return width
    }

    const updateParent = (item, flatData) => {
        const { parent } = item
        if (!parent || !parent.length) return

        parent.forEach(parentDomId => {
            const immediateParent = flatData.find(item => item.dom_id == parentDomId)
            if (!immediateParent) return
            const childStartDateArr = immediateParent.child.map(({ start_date }) => start_date)
            const childEndDateArr = immediateParent.child.map(({ end_date }) => end_date)
            immediateParent.start_date = findMinMaxDate(childStartDateArr, 'min')

            console.log(findMinMaxDate(childEndDateArr, 'max'), 'find parent')
            immediateParent.end_date = findMinMaxDate(childEndDateArr, 'max')
        })
    }

    const syncChildDate = (item) => {
        if(!item.child.length) return
        item.child.forEach(child => {
            child.start_date = item.start_date
            child.end_date = item.end_date

            if(child.child.length) syncChildDate(child)
        })
    }

    const handleResize = ({ timeline, item, left, width, handle, successCB }) => {
        const timelineStartDate = _getTimelineStartDate(timeline)
        const timelineEndDate = _getTimelineEndDate(timeline)
        const timelineTotalDate = differenceInDays(timelineEndDate, timelineStartDate)

        if (handle == 'ml') {
            const totalDateFromLeft = left / _datePerPixel(timelineTotalDate)
            item.start_date = dateFormat(addDays(timelineStartDate, totalDateFromLeft), 'yyyy-MM-dd')
        }

        if (handle == 'mr') {
            const totalDateForWidth = width / _datePerPixel(timelineTotalDate)
            item.end_date = dateFormat(addDays(new Date(item.start_date), totalDateForWidth), 'yyyy-MM-dd')
        }

        if(successCB) successCB(timeline, item)
    }

    const handleDrag = ({timeline, item, left, width, successCB }) => {
        const timelineStartDate = _getTimelineStartDate(timeline)
        const timelineEndDate = _getTimelineEndDate(timeline)
        const timelineTotalDate = differenceInDays(timelineEndDate, timelineStartDate)
        
        const totalDateFromLeft = left / _datePerPixel(timelineTotalDate)
        item.start_date = dateFormat(addDays(timelineStartDate, totalDateFromLeft), 'yyyy-MM-dd')
        
        const totalDateForWidth = width / _datePerPixel(timelineTotalDate)
        let newEndDate = addDays(new Date(item.start_date), totalDateForWidth)
        if(newEndDate.getTime() > timelineEndDate.getTime()) {
            newEndDate = timelineEndDate
        }
        // console.log(addDays(new Date(item.start_date), totalDateForWidth), 'dfd xx')
        item.end_date = dateFormat(newEndDate, 'yyyy-MM-dd')
        console.log(timelineEndDate, newEndDate, 'hadledreag')
        if(successCB) successCB(timeline, item)
    }

    return {
        getRef,
        getLeft,
        getWidth,
        prepareTimeline,
        handleResize,
        handleDrag,
        updateParent,
        syncChildDate,
        getTimelineStartDate: _getTimelineStartDate,
        getTimelineEndDate: _getTimelineEndDate
    }
}