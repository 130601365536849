<template>
<div id="xxx-1" style="outline:none" class="w-full h-full scroll-smooth">
    <div 
        class="w-full h-full border-transparent"
        :ref="sceneId"
        :style="{borderWidth}"
    >
        <slot></slot>
    </div>
</div>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
const props = defineProps({
    sceneId: {
        type: String,
        default: 'scene'
    },
    spaceAround: {
        type: Number,
        default: 4
    },
    createInstance: Function
})

const scene = ref()
const borderWidth = computed(() => `${props.spaceAround}px`)
onMounted(() => {
    props.createInstance(scene.value)
}) 
</script>

<style>
    .panzoom-parent{
    border-width: 10px;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    }
</style>