<template>
  <div
    v-click-outside="() => toggleComments = false"
    class="relative"
  >
    <Panel height="293px">
        <template #header>
          <div class="flex gap-1 items-center fs-10 fw-800">
            CMPL.
            <span
              @click="handleCompletion(item, () => { loadData(planning) })"
              class="text-white rounded block px-2"
              :class="item.is_last_level && isEmpty(item.child) ? 'cursor-pointer' : ''"
              :style="{padding: '2px 8px', background: getColor(getTotalCompletion(item))}"
            >{{ getTotalCompletion(item) }}%</span>
          </div>
          <div class="flex gap-1 items-center fs-10 fw-800">
            PROG.
            <span
              class="rounded text-white block px-2"
              :style="{padding: '2px 8px', background: getColor(item.progress)}"
            >{{ item.progress }}%</span>
          </div>
        </template>

        <template #content>
            <div class="p-4 pb-0">
              <p class="fs-10 fw-700 text-color-616161 uppercase">
                {{ getLabel(item.level) }} {{ item.index_code }}
              </p>
              <p class="fs-14 fw-700 text-primary-one">
                {{ item.index_code }} {{ item.title }}
              </p>
      
              <div class="flex gap-4 mt-3">
                <div class="grid" style="width: 98px">
                  <span class="fs-10 fw-500">Est. vs. PL. Capcity</span>
                  <span class="fs-12 fw-700 text-primary-six mt-1">
                    {{ item.estimatedEffort ? `${estimatedEffortVsPlanned}%` : '0%' }}
                  </span>
                </div>
                <div class="grid">
                  <span class="fs-10 fw-500">Avg Planned Capacity</span>
                  <span class="fs-12 fw-700 mt-1" style="color: #2bad7e">
                    {{ round(item.AvgPlanCapacity).toString().concat('%') }}
                  </span>
                </div>
              </div>
      
              <div class="flex gap-4 mt-3">
                <div class="grid" style="width: 98px">
                  <span class="fs-10 fw-500">Planned Budget</span>
                  <span class="fs-12 fw-700 text-black">{{ round(_getPlannedBudget(item)) || 0 }} USD</span>
                </div>
                <div class="grid">
                  <span class="fs-10 fw-500">Est. Budget Allocated</span>
                  <span class="fs-12 fw-700 text-black">{{round(getEstimatedBudgetAllocated(item)) || 0}}%</span>
                </div>
              </div>
      
              <div class="flex justify-between gap-2 mt-4">
                <AvatarGroup
                  class="origin-top-left"
                  :dimension="20"
                  :avatars="getMembers(item)"
                  :limit="3"
                />
                <button 
                  class="-mt-5 p-1 w-6 h-6 flex items-center justify-center rounded" 
                  @click="manageComment(item)"
                  :class="toggleComments && 'bg-gray-200'"
                >
                  <BalIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
            <div class="-mt-1" >
              <BaseChart
                :chart-options="{}"
                :series="series"
                :height="90"
              />
            </div>
        </template>
    </Panel>
    <Comments 
      class="absolute top-full z-50 w-full"
      style="background-color:#fff;margin-top: -118px;"
      v-if="toggleComments"
      :activeId="item.id"
      :comments="commentsList"
      :listType="false"
      :minShowLength="0"
    />
  </div>
</template>

<script setup>
import Panel from './Panel.vue'
import BaseChart from './BaseChar.vue'
import AvatarGroup from '@/elements/atom/AvatarGroup.vue'
import BalIcon from "../BalIcon.vue"
import useTaskBoard from '../../../services/useTaskBoard'
import { isUndefined, sum, round, orderBy, isEmpty } from 'lodash'
import { ref, computed } from 'vue'
import { colors } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import { commentsList, handleComments } from '@/views/screens/projectManagement/services/useTaskboardComment.js'
import Comments from '@/components/InstMenu/right-navigation-drawer/module/Comments.vue'
import { fillRemainingMonthCompletion } from '@/views/screens/projectManagement/services/calculation.js'

const props = defineProps({
  item: Object,
  planning: Object,
})
const { loadData, getAssignedMembers, selectedModule, handleCompletion } = useTaskBoard()
const toggleComments = ref(false)
const manageComment = (item) => {
  toggleComments.value = !toggleComments.value
  if(!toggleComments.value) return
  handleComments(item)
}
const getMembers = (item) => {
  let avatars = []
  let assignedMembers = getAssignedMembers(item,  i => i.i_am == "team_member")

  avatars = assignedMembers.map(member => {
    return {
      name: member.team_member?.name,
      file_path: member.team_member?.img_path
    }
  })

  const uniqueTester = {}
  avatars = avatars.filter(avatar => {
    if(uniqueTester[uniqueTester.name]){
      return false
    }
    uniqueTester[uniqueTester.name] = 1
    return avatar
  })
  return avatars
}
const getLabel = (level) => {
  let _level = level > 4 ? 4 : level
  let collection_settings = JSON.parse(selectedModule.value.collection_settings)
  return collection_settings?.collections[_level].title
}
const estimatedEffortVsPlanned = computed(() => {
  if(isUndefined(props.item.estimatedEffort) && isUndefined(props.item.avgPlannedEffort)) {
      return ''
  }
  let estimatedEffort = !isUndefined(props.item.estimatedEffort) ? props.item.estimatedEffort : 0;
  let avgPlannedEffort = !isUndefined(props.item.avgPlannedEffort) ? props.item.avgPlannedEffort : 0;
  let value = round(sum([avgPlannedEffort - estimatedEffort]), 2);
  return value ? value : 0;
})

const _getPlannedBudget = item =>   item.plannedResourceBudget * (props.planning.materials_equipment / 100) || 0

const _getEstimatedBudget = item => {
  item.estimatedBudget = item.estimatedResourceBudget * (props.planning.materials_equipment / 100) || 0
  return item.estimatedBudget
}

const getEstimatedBudgetAllocated = item => {
  let est_budget = _getEstimatedBudget(item)
  let planned    = _getPlannedBudget(item)

  return (planned * 100) / est_budget
}

const getColor = (progress) => {
    let range = (progress/10)
    return _getColorWithRange(range)
}
const _getColorWithRange = (range) => {
  let color = colors.value.find(item => {
    let ranges = item.range.split("-")
    if(range<=0) return true
    return (range > ranges[0] && range <= ranges[1])
  })
  return color?.color
}
const series = computed(() => {
  const historyWithMissingMonth = fillRemainingMonthCompletion(props.item, 'value', 'progressHistory')
  const seriesData = [{
    name: 'Progress',
    data: historyWithMissingMonth.map(item => {
      return {
        x: item.date,
        y: Number(item.value) ? item.value.toFixed(2) : 0
      }
    })
  }]
  if(!seriesData) return []
  return orderBy(seriesData, ['x'], ['asc'])
})

const getTotalCompletion = (item) => {
  let totalCompletion = item.totalCompletion != undefined ? item.totalCompletion : 0
  console.log(totalCompletion)
  return totalCompletion
}
</script>