<template>
    <div class="grid gap-2 mb-5">
        <h1 v-if="label" class="fs-12 fw-500 text-primary-one fs-14 fw-500 text-color-616161">{{ label }}</h1>
        <select
            v-model="selected"
            :style="{width: maxWidth}"
            class="fs-14 fw-500 overflow-hidden text-color-616161 border border-secondary-five rounded px-3 py-2"
        >
            <option
                v-for="option in localOptions"
                :key="option[itemValue]"
                :value="option[itemValue]"
            >
                {{ option[itemText] }}
            </option>
        </select>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { find, isArray } from 'lodash';
    
    const props = defineProps({
        label: [String, Boolean],
        defaultOption: {
            type: String,
            required: false
        },
        maxWidth: {
            type: String,
            default: () => '100%'
        },
        options: {
            type: Array,
            required: false
        },
        itemText: {
            type: String,
            default: () => 'title'
        },
        itemValue: {
            type: String,
            default: () => 'id'
        },
        value: [String, Number]
    });
    const emit = defineEmits(['input', 'change']);

    const selected = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
            emit('change', value);
        }
    });
    const configuration = {
        defaultOption: props.defaultOption,
        itemText     : props.itemText,
        itemValue    : props.itemValue
    }
    const localOptions = computed(() => {
        if(props.defaultOption) {
            const { defaultOption, itemValue = 'id', itemText = 'title' } = configuration;
            if(!find(props.options, [itemText, defaultOption]) && isArray(props.options) && defaultOption) {
                props.options.unshift({
                    [itemValue]: null,
                    [itemText]: defaultOption
                });
            }
        }
        return props.options;
    });
</script>