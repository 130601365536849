import ModalWrapper from '@/components/planning/integration/jira/ModalWrapper.vue';
import SelectProjectManagementTools from '@/components/planning/integration/SelectProjectManagementTools.vue'
import SelectJiraProject from '@/components/planning/integration/jira/SelectJiraProject.vue'
import ConfigureJira from '@/components/planning/integration/jira/ConfigureJira.vue'
import ConfigureMapping from '@/components/planning/integration/jira/ConfigureMapping.vue'
import AdditionalSettings from '@/components/planning/integration/jira/AdditionalSettings.vue'
import Prepare from '@/components/planning/integration/jira/Prepare.vue'
import Success from '@/components/planning/integration/jira/Success.vue'
import DataSynchronized from '@/components/planning/integration/jira/DataSynchronized.vue'

export const modalComponents = {
    SelectProjectManagementTools,
    SelectJiraProject,
    ConfigureJira,
    ConfigureMapping,
    AdditionalSettings,
    Prepare,
    Success,
    DataSynchronized
}

export {
    ModalWrapper,
}