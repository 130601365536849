export const planning = [
    {
        id: 1,
        title: 'Work Breakdown Structure - WBS',
        width: 301
    },
    {
        id: 2,
        title: 'Cal. Capacity Hours / FTE',
        width: 85
    },
    {
        id: 3,
        title: 'Estimated Effort FTE %',
        width: 85
    },
    {
        id: 4,
        title: 'Avg. Planned Res. FTE %',
        width: 98
    },
    {
        id: 5,
        title: 'Estimated Effort vs. Planned',
        width: 98
    },
    {
        id: 6,
        title: 'CMPL in %',
        width: 66
    },
    {
        id: 7,
        title: 'PROG in %',
        width: 66
    }
]