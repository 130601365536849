<template>
    <div class="flex h-full w-full items-center justify-center rounded-sm">
        <slot :setValue="setValue" :value="value">
            <input
                @input="setValue"
                placeholder="Add"
                :value="value"
                type="text"
                class="w-full h-full text-center italic bg-transparent panzoom-exclude"
                autocomplete="off"
            />
        </slot>
    </div>
</template>

<script setup>
    import { isArray } from 'lodash' 
    const props = defineProps({
        item: Object,
        withPercent: {
            type: Boolean,
            default: () => true
        },
        value: {
            required: false
        },
    });
    const emit = defineEmits(['input']);
    const setValue = (e) => {
        let value = e.target.value.match(/\d+/g)
        if(props.withPercent && value) {
            value = value.join('').concat('%');
        }
        if(isArray(value)) value = value?.join('')
        emit('input', value || '');
    }
</script>