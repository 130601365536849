import { eachMonthOfInterval, endOfMonth, format, differenceInDays } from 'date-fns';
import { isEmpty, orderBy, sumBy, uniqBy, meanBy, map, flatten, groupBy, compact, merge } from "lodash";
import { generateMonthList, dateParser, getMonthsTotalDate } from '@/plugins/lodash.js'
import { dateCalculation } from "@/composable/timeline/dateCalculation.js";

const {
  getProgress:calculateProgress,
  getTotalWorkingDay, 
  getExpectedProgress 
} = dateCalculation();

export const getDayDiff = (item) => {
  let startDate = new Date(item.start_date);
  let endDate = new Date(item.end_date);
  return differenceInDays(endDate, startDate) + 1;
};

export const fillRemainingMonthCompletion = (item, valueKey='completion', mergeKey='totalCompletionHistory') => {
  const start = new Date(item.start_date);
  const end = new Date(item.end_date);
  if(start > end) return []
  const months = eachMonthOfInterval({ start, end });
  let totalMonths = map(months, month => ({date: format(month, "MMMyyyy"), [valueKey]: 0}));
  return merge(totalMonths, item[mergeKey])
}

export const getWorkingHour = (item, workingHours) => {
  let days = getDayDiff(item);
  return days * workingHours;
};

export const adjustedWorkingHour = (item, basis) => {
    let hour = getWorkingHour(item, basis.working_hours) || 1;
    let adjustment = ((100 - basis.weekend_holiday_adjustment) / 100);
    return hour * adjustment;
};

export const getCapacity = (item, basis) => {
  let serialize = adjustedWorkingHour(item, basis);
  return serialize * (basis.dedication / 100);
};


const _formatDateString = (dateString) => {
    const month = dateString.match(/[a-zA-Z]+/)[0];
    const year = dateString.match(/\d+/)[0];
    return `${month} ${year}`;
}
export const handleCompletionHistory = (child) => {
  let data = groupBy(flatten(map(child, item => fillRemainingMonthCompletion(item, 'completion', 'totalCompletionHistory'))), 'date');
    let completionHistories = [];
    Object.keys(data).map((key) => {
      completionHistories.push({
        date: key,
        completion: meanBy(data[key], (m) => m.completion || 0)
      });
    });
    return orderBy(completionHistories, item => new Date(item.date), ['asc']);
};

export const handleProgressHistory = (child) => {
    const validChild = child.filter(ch => ch.progressHistory)
    let data = groupBy(flatten(map(validChild,  children => children.progressHistory)), 'date');
    let progressHistory = Object.keys(data).map((key) => ({
      date: key,
      value: meanBy(data[key], (m) => m.value || 0)
    }))
    // console.log(progressHistory, child, 'item.id')
    return orderBy(progressHistory, item => new Date(item.date), ['asc']);
};
export const handleMonthlyEfficiency = (child, config = {viewable: true, key: "monthlyEfficiency"}) => {
  let children = child;
  if(config.viewable) {
    children = child.filter((item) => item.viewable)
  }
  if (isEmpty(children)) {
    return [];
  }
  let data = groupBy(flatten(map(children, config.key)), 'date');
  let efficiencies = [];
  Object.keys(data).map((key) => {
    efficiencies.push({
      date: key,
      value: sumBy(data[key], (m) => m.value)
    });
  });
  return efficiencies;
};

export const getAvgPlannedEffortSumUp = (children) => {
    if (isEmpty(children)) {
      return [];
    }
    let ef = compact(map(children, "monthlyEfficiency"));
    if(isEmpty(ef)) {
        return []
    }
    let fl = flatten(ef);
    let gr = groupBy(fl, "date");
    let data = gr;
    let efficiencies = [];
    Object.keys(data).map((key) => {
      efficiencies.push({
        date: key,
        value: sumBy(data[key], (m) => m.value),
      });
    });
    return efficiencies;
};

export const filterEfficiencyByItemDuration = (efficiencies, item) => {
  const { start_date, end_date } = item
  if(!start_date || !end_date || !efficiencies.length) return []
  let localStartDate = new Date(start_date)
  let localEndDate = new Date(end_date)
  if(localEndDate < localStartDate) return []
  const monthList = generateMonthList(localStartDate, localEndDate);
  if(!monthList?.length) return [];
  const validEfficiencies = efficiencies.filter(efficiency => monthList.includes(efficiency.date))
  return validEfficiencies
}

export const fillEfficiency = (efficiencies, item) => {
  const { start_date, end_date } = item
  if(!start_date || !end_date || !efficiencies.length) return []
  let localStartDate = new Date(start_date)
  let localEndDate = new Date(end_date)
  if(localEndDate < localStartDate) return []
  const monthList = generateMonthList(localStartDate, localEndDate);
  let validEfficiencies = filterEfficiencyByItemDuration(efficiencies, item)
  let filledEfficiency = map(monthList, month => {
    const eff = validEfficiencies.find(({date}) => date == month)
    if(eff) {
      return eff
    }
    return {
      date: month,
      value: 100
    }
  });
  return filledEfficiency
}

export const getAvgMonthlyEfficiency = (efficiencies) => {
  return meanBy(efficiencies, (item) => item.value) || 0;
};

export const getEstimatedBudgetGraphData = (efficiencies, item) => {
    return map(efficiencies, (efficiency) => {
        return {
            date: efficiency.date,
            value: item.estimatedBudget
        }
    })
}

export const getPlanedBudgetGraphData = (efficiencies, basis, item) => {
    return map(efficiencies, (efficiency) => {
        let resourceBudget = (efficiency.value / 100) * item.capacity * item.totalHourlyRate || 0
        return {
            date: efficiency.date,
            value: resourceBudget * (basis.materials_equipment / 100)
        }
    });
}

export const getNotAllocatedBudgetGraphData = (efficiencies, basis, item) => {
    return map(efficiencies, (efficiency) => {
        let resourceBudget = (efficiency.value / 100) * item.capacity * item.totalHourlyRate || 0;
        let plannedBudget = resourceBudget * (basis.materials_equipment / 100)
        return {
            date: efficiency.date,
            value: item.estimatedBudget - plannedBudget
        }
    })
}

export const findMemberDetails = (item, member) => {
  let data = item.members.find((i) => i.team_member_id == member.id);
  if (!data) {
    return {};
  }
  return data;
};

export const getProgress = (item) => calculateProgress(item);

export const getParentMembers = (children) => {
    let members = children.filter(i => !isEmpty(i.totalMembers)).map(y => y.totalMembers);
    return uniqBy(flatten(members), 'id');
}

export const isMyTask = (item, member) => {
  return !isEmpty(findMemberDetails(item, member));
};

export const generateProgressHistory = (item) => {
  const { start_date:itemStartDate, end_date:itemEndDate } = item;
  if (
      !item.completion_histories 
      || !item.completion_histories.length
      || !itemStartDate
      || !itemEndDate
    ) return [];
  const currentDate = new Date()
  const expectedProgress = getExpectedProgress(
    new Date(itemStartDate),
    new Date(itemEndDate),
    currentDate
  )
  // console.log(expectedProgress, 'expectedProgress')
  return item.completion_histories.map(({ date, completion }) => {
    let today
    let expectedProgressForThisMonth
    const lsatDateOfMonth = endOfMonth(new Date(date)) // Output: 2023-04-30T23:59:59.999Z
    today = new Date(lsatDateOfMonth)
    if(format(lsatDateOfMonth, 'MMMyyyy') == format(currentDate, 'MMMyyyy')) {
      today = currentDate
      // console.log(date, format(lsatDateOfMonth, 'MMMyyyy'), format(currentDate, 'MMMyyyy'), 'same date')
    }
    if(today.getTime() > new Date(itemEndDate).getTime()) {
      today = new Date(itemEndDate)
    }
    /*
      if the month haven't started yet
      expected progress for this month
      will be zero
    */
    const isFutureMonth = new Date(date).getTime() > currentDate.getTime()
    if(isFutureMonth) {
      expectedProgressForThisMonth = 0
    } else {
      expectedProgressForThisMonth = getExpectedProgress(
        new Date(itemStartDate),
        new Date(itemEndDate),
        today
      )
    }
    
    // console.log(expectedProgressForThisMonth)
    return {
      date,
      itemDuration: getTotalWorkingDay(itemStartDate, itemEndDate),
      completion,
      expectedProgress,
      today,
      isFutureMonth,
      expectedProgressForThisMonth,
      value: completion - expectedProgressForThisMonth,
    };
  });
}