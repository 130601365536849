import PlanInformation from '@/views/screens/projectManagement/fragments/impPlans/Index.vue'
import Teams from '@/views/screens/projectManagement/fragments/teams/Index.vue'
import Resources from '@/views/screens/projectManagement/fragments/resourcesTab/Index.vue'
import People from '@/views/screens/projectManagement/fragments/people/Index.vue'
import CostEstimation from '@/views/screens/projectManagement/fragments/costEstimation/Index.vue'
import PriorityAndRisk from '@/views/screens/projectManagement/fragments/PriorityAndRisk.vue';
import Timeline from '@/views/screens/projectManagement/fragments/timeline/Index.vue'
import TaskBoard from '@/views/screens/projectManagement/fragments/taskBoard/Index.vue'

export const components = [
    {
        id: 1,
        title: 'Timeline',
        component: Timeline
    },
    {
        id: 2,
        title: 'Task Board',
        component: TaskBoard
    },
    {
        id: 3,
        title: 'Imp. Plans',
        component: PlanInformation
    },
    {
        id: 4,
        title: 'Resources',
        component: Resources
    },
    {
        id: 5,
        title: 'People',
        component: People
    },
    {
        id: 6,
        title: 'Cost Est.',
        component: CostEstimation
    },
    {
        id: 7,
        title: 'Teams',
        component: Teams
    },
    // {
    //     id: 8,
    //     title: 'Documentation',
    //     component: PriorityAndRisk
    // }
];
