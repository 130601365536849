import { ref, onMounted } from 'vue'
import router from '@/routes/routes.js';
import axios from 'axios';
import { isEmpty, property } from 'lodash'
import useJira from '@/components/planning/integration/jira/useJira.js'

const showModal = ref(false)
export default function useProjectCreate()
{
    const { getAllProject } = useJira()
    const route = router.currentRoute;

    const payload = ref({
        name: null,
        key: null,
        projectTypeKey: null,
        assigneeType: 'PROJECT_LEAD',
        leadAccountId: null
    })

    const leadAccountIds = ref([])
    const projectTypeKey = ref([])
    const projectKeyValidationMsg = ref(null)
    let timeoutId = null
    
    const getLeadAccountIds = async () => {
        let { data } = await axios.get(`services/jira/users`, { params: {project_id: route.params.projectId} }).then(({data}) => data)
        if(!data) return
        data = data.filter(item => {
            if(item.accountType == 'atlassian' && item.active){
                return item
            }
        })

        leadAccountIds.value = data
    }

    const getProjectTypeKey = async () => {
        let { data } = await axios.get(`services/jira/projects/key-types`, { params: {project_id: route.params.projectId} }).then(({data}) => data)
        if(!data) return
        projectTypeKey.value = data
    }

    const validateProjectKey = (e) => {
        let target = e.target
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async () => 
        {
            let parameters = {
                project_id: route.params.projectId,
                key: target.value
            }
            let { data } = await axios.get(`services/jira/projects/valid/check-validity`, { params: parameters }).then(({data}) => data)
            projectKeyValidationMsg.value = data.errors?.projectKey
        }, 500)
    }

    const handleCreate = async (projectKeyValidationMsg, payload) => 
    {
        let hasNullProperty = false
        for(let property in payload){
            if(isEmpty(payload[property])){
                alert(`Any field must not be empty!`)
                hasNullProperty = true
                return
            }
        }

        if(isEmpty(projectKeyValidationMsg) && !hasNullProperty){
            const parameters = {
                project_id: route.params.projectId
            }
            let res = await axios.post(`services/jira/projects`, payload, { params: parameters })
            if(res.status){
                getAllProject()
                showModal.value = false
                return
            }
            alert(res.message)
        }
    }

    onMounted(async () => {
        getProjectTypeKey()
        getLeadAccountIds()
    })

    return {
        showModal,
        leadAccountIds,
        projectTypeKey,
        validateProjectKey,
        projectKeyValidationMsg,
        payload,
        handleCreate
    }
}