import { filter } from 'lodash'
import { ref } from 'vue'

export const boardMembers = ref([])
export const setMembers = (levelData) => {
    boardMembers.value = []
    getMembers(levelData)
}

let flatten = ({ child = [], ...rest }) => [rest, ...child.flatMap(flatten)];
const getAssignedMembers = (item, cb) => {
    let flatData = item.map(item => flatten(item))
    return filter(flatData.flat(), cb)
}
const getMembers = (item) => {
    let avatars = []
    let assignedMembers = getAssignedMembers(item,  i => i.i_am == "team_member")
    avatars = assignedMembers.map(member => {
      return {
        id: member.team_member?.user_id,
        name: member.team_member?.name,
        file_path: member.team_member?.img_path
      }
    })

    boardMembers.value = _makeUnique(avatars)
}

const _makeUnique = (arrayData) => {
    const uniqueNames = {};
    const uniqueArray = [];

    arrayData.forEach(obj => {
        if (!uniqueNames[obj.name]) {
            uniqueNames[obj.name] = true;
            uniqueArray.push(obj);
        }
    })

    return uniqueArray
}