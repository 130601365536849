<template>
    <div class="flex items-center relative border-b border-secondary-three i-h-50">
        <div v-for="({month, year}, index)  in timeline.months" 
            :key="`child-${index}-${Math.random()}`" 
            class="w-month flex-shrink-0 h-full border-r border-secondary-three"
        >
            <div 
                v-if="!isLastLevel"
                :style="{backgroundColor: getBg(item.i_am, getValue(`${month}${year}`))}"
                class="fs-14 italic justify-center bg-primary-three flex-shrink-0 font-medium text-white flex items-center text-center h-full leading-4"
            >
               {{ getValue(`${month}${year}`) }}
            </div>
            <MonthlyEfficiency
                editable
                v-else 
                :item="item.memberDetails || {}"
                :month="`${month}${year}`"
                @changeEfficiency="handleDataSync"
            />
        </div>
    </div>
</template>

<script setup>
    import { getProgressCellBg } from '@/views/screens/projectManagement/services/useProjectManagement.js'
    import MonthlyEfficiency from '@/views/screens/projectManagement/components/MonthlyEfficiency.vue';
    import { useResource } from '@/views/screens/projectManagement/services/useResource.js'
    import { find, get } from 'lodash'
    import { computed } from 'vue';
    const props  = defineProps({
        timeline: Object,
        item: Object,
        handleDataSync: Function
    });
    const { sync } = useResource();
    const isLastLevel = computed(() => (props.item.i_am == 'level' || props.item.i_am == 'task') && props.item.child.length <= 0)
    const getValue = (key) => {
       let data =  find(props.item.monthlyEfficiency, ['date', key]);
       if(data && data.value) {
            return data.value.toString().concat('%');
       }
       return '';
    }

    const getBg = (iAm, val) => {
        if(iAm == 'team' && val) {
            return get(props.item, 'settings.backgroundColor', '#385E8A')
        }
        return val ? getProgressCellBg(iAm, val) : ''
    }
</script>