<template>
    <div class="px-6 mt-4">
        <div class="flex justify-between items-center pb-4 border-b border-secondary-four cursor-pointer">
          <h1 @click="basicInfoToggle = !basicInfoToggle" class="fw-700 fs-14 uppercase">Basic Info</h1>
          <button @click="basicInfoToggle = !basicInfoToggle" class="cursor-pointer">
            <svg class="transform" :class="basicInfoToggle ? 'rotate-0' : 'rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75098 12.5L10.001 6.25L16.251 12.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div v-if="basicInfoToggle" class="mt-5">
          <h3 class="fw-500 fs-12 text-secondary-two">Task name</h3>
          <h3 class="fw-800 fs-16 text-primary-one mb-2">{{ selectedItem.index_id }}{{ selectedItem.index_code }} {{ selectedItem.title }}</h3>

          <div class="grid gap-4">
<!--            <div class="flex gap-4 items-center">-->
<!--              <span class="fw-500 fs-12 text-secondary-two w-28">Project:</span>-->
<!--              <span class="fw-600 fs-12 text-primary-one">Digital Transformation</span>-->
<!--            </div>-->
<!--            <div class="flex gap-4 items-center">-->
<!--              <span class="fw-500 fs-12 text-secondary-two w-28">Parent:</span>-->
<!--              <span class="fw-600 fs-12 text-primary-one">Identifier 1001</span>-->
<!--            </div>-->
            <div class="flex gap-4 items-center">
              <span class="fw-500 fs-12 text-secondary-two w-28">Start Date:</span>
              <span class="fw-600 fs-12 text-primary-one">{{ selectedItem.start_date }}</span>
            </div>
            <div class="flex gap-4 items-center">
              <span class="fw-500 fs-12 text-secondary-two w-28">End Date:</span>
              <span class="fw-600 fs-12 text-primary-one">{{ selectedItem.end_date }}</span>
            </div>
<!--            <div class="flex gap-4 items-center justify-between">-->
<!--              <div class="flex gap-4 items-center">-->
<!--                <span class="fw-500 fs-12 text-secondary-two w-28">Assignee:</span>-->
<!--                <div class="images">-->
<!--                  <img src="https://images.unsplash.com/photo-1529665253569-6d01c0eaf7b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZmlsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="" class="w-7 h-7 rounded-full overflow-hidden object-cover object-center inline-block border border-white">-->
<!--                  <img src="https://images.unsplash.com/photo-1457449940276-e8deed18bfff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmlsZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="" class="w-7 h-7 rounded-full overflow-hidden object-cover object-center inline-block border border-white -ml-2">-->
<!--                  <img src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="" class="w-7 h-7 rounded-full overflow-hidden object-cover object-center inline-block border border-white -ml-2">-->
<!--                </div>-->
<!--              </div>-->
<!--              <button class="py-1 px-3 rounded bg-primary-four text-white self-end">Share</button>-->
<!--            </div>-->
          </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRightPanel } from '@/components/planning/useRightPanel.js'

const { selectedItem } = useRightPanel()
const basicInfoToggle = ref(false)
</script>

<style lang="scss" scoped>

</style>
