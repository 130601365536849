<template>
    <div class="w-full">
        <DropdownCell
            v-model="localItem"
            :title="title"
            :items="items"
        />
    </div>
</template>

<script setup>
    import DropdownCell from '@/views/screens/projectManagement/components/DropdownCell.vue';
    import { useTeam } from '@/views/screens/projectManagement/services/useTeam.js'
    import { computed } from 'vue';
    const { updateTeamMember } = useTeam();

    const options = {
        1: 'Confirmed',
        2: 'N/A'
    }
    const props = defineProps({
        item: Object
    });
    const title = computed(() => options[props.item.status] || 'N/A');
    const items = computed(() => Object.keys(options).map(key => ({title: options[key], id: key})))
    const localItem = computed({
        get() {
            return props.item['status'];
        },
        set(value) {
            props.item['status'] = value;
            updateTeamMember(props.item)
        }
    });
</script>