<template>
    <div class="grid items-center h-full h-600px">
        <div>
            <svg class="mx-auto block" width="233" height="140" viewBox="0 0 233 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M181.088 65.3333C181.088 100.863 152.266 129.667 116.711 129.667C81.1555 129.667 52.3335 100.863 52.3335 65.3333C52.3335 29.8036 81.1555 1 116.711 1C152.266 1 181.088 29.8036 181.088 65.3333Z" stroke="#2B80AD" stroke-width="2"/>
                <g filter="url(#filter0_dd_25059_188835)">
                <ellipse cx="116.711" cy="65.333" rx="46.2423" ry="46.2114" fill="white"/>
                </g>
                <path d="M101.828 65.3333L112.459 75.9566L133.719 54.71" stroke="#2B80AD" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M220.368 94.3902C220.586 93.3241 222.11 93.3241 222.328 94.3902L223.118 98.2522C223.198 98.6447 223.505 98.9514 223.897 99.0316L227.759 99.8203C228.825 100.038 228.825 101.562 227.759 101.78L223.897 102.569C223.505 102.649 223.198 102.955 223.118 103.348L222.328 107.21C222.11 108.276 220.586 108.276 220.368 107.21L219.579 103.348C219.498 102.955 219.191 102.649 218.799 102.569L214.937 101.78C213.871 101.562 213.871 100.038 214.937 99.8203L218.799 99.0316C219.191 98.9514 219.498 98.6447 219.579 98.2522L220.368 94.3902Z" fill="#1C1C1D"/>
                <path opacity="0.2" d="M30.7749 126.124C30.993 125.057 32.5163 125.057 32.7343 126.124L33.2072 128.436C33.2875 128.828 33.5943 129.135 33.9868 129.215L36.2974 129.687C37.3639 129.905 37.3639 131.429 36.2974 131.647L33.9868 132.119C33.5943 132.199 33.2875 132.505 33.2072 132.898L32.7343 135.21C32.5163 136.276 30.993 136.276 30.7749 135.21L30.302 132.898C30.2217 132.505 29.9149 132.199 29.5224 132.119L27.2118 131.647C26.1453 131.429 26.1453 129.905 27.2118 129.687L29.5224 129.215C29.9149 129.135 30.2217 128.828 30.302 128.436L30.7749 126.124Z" fill="#1C1C1D"/>
                <path opacity="0.2" d="M200.755 15.9903C200.973 14.9242 202.496 14.9242 202.714 15.9903L203.187 18.3024C203.267 18.6949 203.574 19.0016 203.967 19.0818L206.277 19.5538C207.344 19.7716 207.344 21.2955 206.277 21.5133L203.967 21.9852C203.574 22.0654 203.267 22.3721 203.187 22.7646L202.714 25.0767C202.496 26.1429 200.973 26.1429 200.755 25.0767L200.282 22.7646C200.202 22.3721 199.895 22.0654 199.502 21.9852L197.192 21.5133C196.125 21.2955 196.125 19.7716 197.192 19.5538L199.502 19.0818C199.895 19.0016 200.202 18.6949 200.282 18.3024L200.755 15.9903Z" fill="#1C1C1D"/>
                <path d="M13.9636 17.8565C14.1817 16.7904 15.705 16.7904 15.923 17.8565L17.3469 24.8183C17.4272 25.2108 17.734 25.5175 18.1265 25.5977L25.0899 27.02C26.1564 27.2378 26.1564 28.7617 25.0899 28.9795L18.1265 30.4018C17.734 30.482 17.4272 30.7887 17.3469 31.1812L15.923 38.143C15.705 39.2091 14.1817 39.2091 13.9636 38.143L12.5397 31.1812C12.4595 30.7887 12.1527 30.482 11.7601 30.4018L4.79679 28.9795C3.73029 28.7617 3.73029 27.2378 4.79679 27.02L11.7601 25.5977C12.1527 25.5175 12.4595 25.2108 12.5397 24.8183L13.9636 17.8565Z" fill="#2B80AD"/>
                <defs>
                <filter id="filter0_dd_25059_188835" x="54.4683" y="11.1216" width="124.485" height="124.423" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="8"/>
                <feGaussianBlur stdDeviation="8"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.223529 0 0 0 0 0.294118 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_25059_188835"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2"/>
                <feGaussianBlur stdDeviation="2"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.20092 0 0 0 0 0.221801 0 0 0 0 0.295833 0 0 0 0.06 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_25059_188835" result="effect2_dropShadow_25059_188835"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_25059_188835" result="shape"/>
                </filter>
                </defs>
            </svg>

            <h1 class="fs-28 fw-700 text-secondary-one text-center mt-5">Success!</h1>
            <h2 class="w-567px text-center mx-auto mt-3">
                You have now successful integrated Instrat 360 with your Jira project. <br />
                You can now choose between continuing in Instrat or switching to Jira?
            </h2>

            <div class="flex justify-center mt-10 gap-14 text-primary-one">
                <button @click="handleClose" class="p-3 w-48 text-center border border-primary-four rounded">
                    Continue in Instrat
                </button>
                <a
                    :href="jiraForm.jira_url+'/jira/projects'"
                    target="_blank"
                    class="p-3 w-48 text-center border border-primary-four rounded relative">
                    Go to Jira
                    <svg class="absolute top-1/2 transform -translate-y-1/2 right-3" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.1228 0H10.0103C10.0103 1.2107 10.4912 2.37182 11.3473 3.22792C12.2034 4.08402 13.3645 4.56497 14.5752 4.56497H16.4381V6.36347C16.4397 8.88234 18.4812 10.9239 21.0001 10.9255V0.877316C21.0001 0.392788 20.6073 0 20.1228 0Z" fill="#2684FF"/>
                        <path d="M15.1189 5.03857H5.00635C5.00796 7.55745 7.04951 9.599 9.56839 9.60062H11.4312V11.405C11.4345 13.9238 13.4773 15.9641 15.9962 15.9641V5.91589C15.9962 5.43136 15.6034 5.03857 15.1189 5.03857Z" fill="url(#paint0_linear_23735_169447)"/>
                        <path d="M10.1125 10.0742H0C0 12.5954 2.04381 14.6392 4.56497 14.6392H6.43365V16.4377C6.43526 18.9543 8.47325 20.9949 10.9898 20.9997V10.9515C10.9898 10.467 10.5971 10.0742 10.1125 10.0742Z" fill="url(#paint1_linear_23735_169447)"/>
                        <defs>
                            <linearGradient id="paint0_linear_23735_169447" x1="13.5486" y1="2.91783" x2="9.27298" y2="7.37921" gradientUnits="userSpaceOnUse">
                                <stop offset="0.18" stop-color="#0052CC"/>
                                <stop offset="1" stop-color="#2684FF"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_23735_169447" x1="320.07" y1="296.479" x2="136.383" y2="478.265" gradientUnits="userSpaceOnUse">
                                <stop offset="0.18" stop-color="#0052CC"/>
                                <stop offset="1" stop-color="#2684FF"/>
                            </linearGradient>
                        </defs>
                    </svg>                        
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        integrationMethods: Object
    })
    const { switchModalComponent, toggleModal, jiraForm } = props.integrationMethods

    function handleClose() {
        toggleModal(false)
        switchModalComponent('SelectProjectManagementTools')
    }
</script>

<style scoped>
    .w-567px{
        width: 567px;
    }
    .h-600px{
        height: 600px;
    }
</style>