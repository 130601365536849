import { ref, onMounted } from "vue"
import axios from "axios"
import { cloneDeep, isEmpty, get } from "lodash"
import useJira from '@/components/planning/integration/jira/useJira.js'

export default function useMapping()
{
    const { jiraForm } = useJira()

    const instrat360Dropdown = [
        {
            value: 'Nothing Selected',
            key: null,
            serial: 0,
        },
        {
            value: 'Implementation plan',
            key: 'plan',
            serial: 1,
        },
        {
            value: 'Project Level',
            key: 'project',
            serial: 2,
        },
        {
            value: 'Module Level',
            key: 'module',
            serial: 3,
        },
        {
            value: 'Group Level 1',
            key: 'level_1',
            serial: 4,
        },
        {
            value: 'Group Level 2',
            key: 'level_2',
            serial: 5,
        },
        {
            value: 'Group Level 3',
            key: 'level_3',
            serial: 6,
        },
        {
            value: 'Group Level 4',
            key: 'level_4',
            serial: 7,
        },
        {
            value: 'Imp. Level 1',
            key: 'task_1',
            serial: 8,
        },
        {
            value: 'Imp. Level 2',
            key: 'task_2',
            serial: 9,
        },
        {
            value: 'Imp. Level 3',
            key: 'task_3',
            serial: 10,
        },
        {
            value: 'Imp. Level 4',
            key: 'task_4',
            serial: 11,
        }
    ]

    const jiraDropdownOptions = ref([
        {
            key: null,
            value: 'Nothing Selected',
            icon: null,
        }
    ])


    const loadIssue = async (projectId, jiraProjectId) => {
        if(!projectId || !jiraProjectId) return

        const params = {
            project_id: projectId,
            jira_project_id: jiraProjectId
        }
        let { data } = await axios.get(`services/jira/issues/types`, { params }).then(({data}) => data)

        let dropdown = [{
            id: null,
            key: 'Nothing Selected',
            icon: null,
            hierarchyLevel: null
        }]

        if(data){
            data.forEach(item => {
                dropdown.push({
                    id: item.id,
                    key: item.untranslatedName,
                    icon: item.iconUrl,
                    hierarchyLevel: item.hierarchyLevel
                })
            })
        }

        jiraDropdownOptions.value = dropdown
    }

    const placeholder = {
        instrat: {
            key: null,
            value: 'Nothing Selected',
            options: null,
            serial: null
        },
        jira: {
            key: 'Nothing Selected',
            id: null,
            hierarchyLevel: null,
            icon: null,
        }
    }

    const addMapItem = (jiraMap, placeHolder) =>{
        let jiraMapLastElement = (jiraMap.length > 0) ? jiraMap[jiraMap.length - 1] : null
        
        placeHolder = cloneDeep(placeHolder)
        placeHolder.instrat.options = getInstratOptions(jiraMapLastElement)
        placeHolder.jira.options    = getJiraOptions(jiraMapLastElement)

        if(jiraMap.length > 0 && (isEmpty(get(jiraMapLastElement, 'instrat.key')) || isEmpty(get(jiraMapLastElement, 'jira.key')))){
            alert("Please select above options")
            return
        }
        
        if(placeHolder.instrat.options.length > 0 && placeHolder.jira.options.length > 0){
            jiraMap.push(placeHolder)
        }
    }

    const getInstratOptions = (jiraMapLastElement) => 
    {
        let instrat360DropdownCopy = cloneDeep(instrat360Dropdown)

        if(!isEmpty(jiraMapLastElement)){
            return instrat360DropdownCopy.filter(item => {
                if(item.serial > jiraMapLastElement.instrat.serial){
                    return item
                }
            })
        }

        return instrat360DropdownCopy
    }

    const getJiraOptions = (jiraMapLastElement) => 
    {
        let jiraDropdownOptionsCopy = cloneDeep(jiraDropdownOptions.value)

        if(isEmpty(jiraMapLastElement)) {
            return jiraDropdownOptionsCopy.filter(item => item.hierarchyLevel >= 0)
        }

        if(jiraMapLastElement.jira.hierarchyLevel == 0) {
            return jiraDropdownOptionsCopy.filter(item => {
                if(item.hierarchyLevel <= jiraMapLastElement.jira.hierarchyLevel 
                    && item.hierarchyLevel != 1
                ){
                    return item
                }
            })
        }
        
        return jiraDropdownOptionsCopy.filter(item => {
            if(
                item.hierarchyLevel < jiraMapLastElement.jira.hierarchyLevel 
                && item.hierarchyLevel != -1
            ){
                return item
            }
        })

        
    }

    const cleanBottomMap = (index) => 
    {
        if(index + 1 == jiraForm.value.jira_mapping.length) return
        jiraForm.value.jira_mapping.splice(Number(index) + 1, jiraForm.value.jira_mapping.length)
    }

    onMounted(() => {
        loadIssue(jiraForm.value.project_id, jiraForm.value.jira_connection_info.projectId)
    })

    return {
        placeholder,
        addMapItem,
        jiraDropdownOptions,
        loadIssue,
        instrat360Dropdown,
        cleanBottomMap
    }
}