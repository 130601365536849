<template>
    <div class="flex items-center border-b border-secondary-four i-h-50">
            <div
                class="pl-5 h-full border-r border-secondary-four"
                :class="[{'minimizeColumn' : settings.titleColumn.minimize}, item ? 'bg-white': '']"
                :style="settings.titleColumn"
            >
                <div @click="$emit('onClick')" class="cursor-pointer flex items-center h-full gap-x-4 justify-start" v-if="!item" :style="{marginLeft: `${offset}px`}">
                    <svg  width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.06042 4.724H4.97242V6.86H3.02842V4.724H0.940422V2.888H3.02842V0.74H4.97242V2.888H7.06042V4.724Z" fill="#C9C9C9"/>
                    </svg>
                    <div class="fs-12 text-secondary-five">{{ placeholder }}</div>
                </div>
            </div>
            
            <div :style="settings.dataColumn" class="h-full">
                <div v-for="y in 5" :key="`child-${y}-${(new Date()).getTime()}`" :class="[
                    {'border-r': (y == 5 && item)},
                    item ? 'bg-white': '',
                    {'hidden': settings.dataColumn.minimize}
                ]"
                class="w-100px border-secondary-four h-full"
                >
                    <span class="invisible"></span>
                </div>
                <div
                    v-if="item && settings.dataColumn.minimize"
                    class="border-r w-47px border-secondary-four h-full"
                    :class="{'bg-white' : item}"
                ></div>
            </div>
            <div :style="settings.parameterColumn" class="h-full">
                <div
                    class="w-100px px-2 bg-white cursor-pointer flex flex-shrink-0 items-center h-full  border-r border-secondary-four fs-12"
                    v-for="(parameter, index) in parameters"
                    v-show="!settings.parameterColumn.minimize"
                    :key="index"
                    :title="getParameterTitle(parameter)"
                    :class="[{'font-semibold': parameter.active }]"
                    @click="handleProgressAmount(parameter, index)"
                >
                    <p class="line-clamp clamp-1">{{ getParameterTitle(parameter) }}</p>
                </div>
                <div
                    v-if="item && settings.parameterColumn.minimize"
                    class="border-r w-47px border-secondary-four h-full"
                    :class="{'bg-white' : item}"
                ></div>
                <div v-if="item && !settings.parameterColumn.minimize" class="w-100px h-full bg-white flex items-center justify-center border-r border-secondary-four flex-shrink-0 fs-12"></div>
                <div v-if="item && !settings.parameterColumn.minimize" class="w-100px h-full bg-white flex items-center justify-center border-r border-secondary-four flex-shrink-0 fs-12"></div>
            </div>
            <div :style="settings.detailColumn" class="h-full">
                <div
                    v-if="item && settings.detailColumn.minimize"
                    class="border-r w-47px border-secondary-four h-full"
                    :class="{'bg-white' : item}"
                ></div>
                <div v-if="item && !settings.detailColumn.minimize" class="w-100px h-full bg-white flex items-center justify-center border-r border-secondary-four fs-12"></div>
                <div v-if="item && !settings.detailColumn.minimize" class="w-100px h-full bg-white flex items-center justify-center border-r border-secondary-four fs-12"></div>
            </div>
        </div>
</template>
<script setup>
    import {set, computed } from 'vue';

    const props = defineProps({
        placeholder: String,
        offset: Number,
        item: Object,
        settings: Object,
        emptyCells: {
            type: Number,
            default: () => 0
        }
    });

    const parameters = computed(() => props.item?.parameters || []);
    const getParameterTitle = (parameter) => {
        return props.item?.system_parameters.find(item => item.id == parameter.id)?.title || ''
    }
    const handleProgressAmount = (parameter) => {
        parameters.value.forEach(parameter => {
            set(parameter, 'active', false);
        });
        set(parameter, 'active', true);
    }
</script>

<style scoped>
    .minimizeColumn > * {
        visibility: hidden;
    }
</style>