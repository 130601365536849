import { render, staticRenderFns } from "./Prepare.vue?vue&type=template&id=146d0bfa&scoped=true&"
import script from "./Prepare.vue?vue&type=script&setup=true&lang=js&"
export * from "./Prepare.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Prepare.vue?vue&type=style&index=0&id=146d0bfa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "146d0bfa",
  null
  
)

export default component.exports