<template>
  <div v-click-outside="hideCalendar">
    <Column v-if="!item.is_last_level" :class="getWeight(item)"> {{ $lodash.format(item[itemKey], 'dd.MM.yyyy') }} </Column>
    <Datepicker
        v-else
        input-class="pl-5 w-100px bg-transparent text-secondary-one fs-12 border-r border-secondary-four"
        format="dd.MM.yyyy"
        :value="item[itemKey]"
        ref="element"
        @input="handleDate"
    />
  </div>

</template>

<script setup>
    import Column from './Column.vue';
    import Datepicker from 'vuejs-datepicker';
    import { format } from 'date-fns'
    import { ref } from 'vue';
    import { getWeight } from '@/composable/useCollection.js'

    const element = ref(null);
    const props = defineProps({
        item: Object,
        handleRowUpdating: Function,
        itemKey: {
            type: String,
            default: () => 'start_date'
        }
    });
    const hideCalendar = () => {
        if(element.value && element.value.isOpen) {
            element.value.close();
        }
    }
   const handleDate = (value) => {
        props.item[props.itemKey] = format(value, 'yyyy-MM-dd');
        props.handleRowUpdating(props.item, {sync: true});
   }
</script>