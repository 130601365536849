<template> 
    <div v-if="isLastLevel" class="bg-primary-three h-full">
        <InputCell
            class="text-primary-four"
            v-model="localEstimatedEffort"
        />
    </div>
    <div v-else>{{ title }}</div>
</template>

<script setup>
    import { computed } from 'vue';
    import InputCell from './InputCell.vue';
    import { trim } from 'lodash'
    import { useProjectManagement } from '@/views/screens/projectManagement/services/useProjectManagement.js'
    const { sync, updateLevelData } = useProjectManagement();
    const props = defineProps({
        item: Object,
    });
    const isLastLevel = computed(() => props.item.is_last_level);
    const title = computed(() => {
        if(['team', 'team_member'].includes(props.item.i_am)) {
            return ''
        }
        return props.item.estimatedEffort ? `${props.item.estimatedEffort}%`: '0%';
    });
    const localEstimatedEffort = computed({
        get() {
            return props.item.estimatedEffort ? `${props.item.estimatedEffort}%` : '0%'
        },
        set(value) {
            props.item.estimated_effort = parseInt(trim(value, '%'));
            sync();
            updateLevelData(props.item);
        }
    })
</script>