<template>
    <div>
        <SelectComponent
            :items="items"
            @onChange="onChange"
        >
            <template v-slot:trigger="{ handleToggle }">
                <div class="flex items-center px-2 fw-500 fs-13">
                    {{ selectedItem.title }}
                    <ChevronDownIcon @click.native="handleToggle" class="w-5 h-5 cursor-pointer" />
                </div>
            </template>
            <template v-slot:option="{ item, onSelect }">
                <div @click="onSelect" class="flex items-center justify-between w-full hover:bg-secondary-three pr-4 pointer-events-none ">
                    <span class="text-secondary-six hover:text-primary-one  block px-4 py-2 text-sm">{{ item.title }}</span>
                    <RightIcon v-if="selectedItem.title == item.title" class="w-3 h-3 text-green-500" />
                </div>
            </template>
        </SelectComponent>
    </div>
</template>

<script setup>
    import SelectComponent from '@/elements/inputs/SelectComponent.vue';
    import { ref } from 'vue';

    const props = defineProps({
        value: {
            required: false
        },
        items: {
            type: Array,
            default: () => [
                {id: 0, title: 'Progress'},//imp.plan+team
                {id: 1, title: 'Capacity'},//resource
                {id: 2, title: 'Budget'},//const estimation
                {id: 3, title: 'Risk & Priority'},
                {id: 4, title: 'Actual VS. Planned'},//people
            ]
        }
    });
    const selectedItem = ref(props.items[props.value || 0]);
    const emit = defineEmits(['input'])
    const onChange = (item) => {
        emit('input', parseInt(item.id));
        selectedItem.value = item;
    }
</script>