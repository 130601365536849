<template>
    <AvatarGroup
        :avatars="avatars"
        source-kye="img_path"
        :dimension="25"
        :limit="2"
    />
</template>

<script setup>
    import AvatarGroup from '@/elements/atom/AvatarGroup.vue'
    import { computed } from 'vue';
    const props = defineProps({
        item: Object
    });

    const avatars = computed(() => {
        if(!props.item.totalMembers) {
            return [];
        }
        return props.item.totalMembers;
    });

</script>