<template>
    <div>
        <div class="flex gap-x-4 items-center mt-3 pl-4">
            <div class="text-white fs-10 fw-600">
                Planned budget  {{ round(item.plannedBudget) }} USD
            </div>
            <div class="ml-auto text-white fs-10 fw-600">
                Allocation: {{ allocation }}%
            </div>
        </div>
        <div class="flex justify-between gap-x-5  h-full">
            <div class="flex-1" style="height: 40px">
                <BaseChar :series="series" :chart-options="{}" />
            </div>
            <slot name="avatar"></slot>
        </div>
    </div>
</template>

<script setup>
    import BaseChar from './BaseChar.vue';
    import { computed } from 'vue';
    import { round } from 'lodash';
    const props = defineProps({
        item: Object
    });
    // plannedBudget
    const allocation = computed(() => {
        if(!props.item.estimatedBudget) {
            return 0
        }
        return round(((props.item.plannedBudget * 100) / props.item.estimatedBudget), 2);
    }) 

    const series = computed(() => [
        {
            name: 'Cost Estimation',
            data: props.item.plannedBudgets.map(item => {
                return {
                    x: item.date,
                    y: round(item.value, 2)
                }
            })
        }
    ]);
</script>