<template>
    <Modal v-model="modalVisibility">
        <div style="width: 680px">
            <Header title="Add project" />
            <main class="p-6">
                <InputSelect
                    label="Project name"
                    default-option="Select one"
                    item-text="name"
                    v-model="projectId"
                    :options="projects"
                />
                <Footer
                    :submit="submit"
                    :cancel="close"
                />
            </main>
        </div>
    </Modal>
</template>

<script setup>
    import Modal from '@/elements/atom/Modal';
    import Header from '@/components/planning/components/ModalHeader.vue';
    import InputSelect from '@/components/planning/components/InputSelect.vue';
    import Footer from '@/components/planning/components/ModalFooter.vue';
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js';
    import router from '@/routes/routes.js';

    import axios from 'axios';
    import { ref, computed, onMounted } from 'vue';
    const emit = defineEmits(['input', 'finish']);
    const props = defineProps({
        plan: Object,
        value: Boolean
    });
    const { getProjectsByWorkspace, projects } = useProjectData();

    const projectId = ref(null);
    const route = router.currentRoute;

    const modalVisibility = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
        }
    });
    const submit = async () => {
        let data = {
            project_id: projectId.value,
            implementation_planning_id: props.plan.id
        }
        await axios.post('planning/projects/store', data).then(({data}) => data.data);
        emit('finish');
    }
    const close = () => {
        modalVisibility.value = false;
    }
    onMounted(async () => {
        await getProjectsByWorkspace(route.params.workspaceId);
    });
</script>