import { debounce, cloneDeep } from 'lodash'
import { transformPlanData } from '@/views/screens/projectManagement/services/usePlan.js'
import { components } from '@/views/screens/projectManagement/services/importer.js'
import { collapseAllRow as collapseRow, getLastLevelsUnderModule } from '@/views/screens/projectManagement/services/utils/_filter.js'
import { filterEfficiencyByItemDuration, fillEfficiency } from '@/views/screens/projectManagement/services/calculation.js'
import { ref, set, computed } from 'vue';
import axios from 'axios'

const localPlannings = ref([]);
const localDataCopy = ref([]);
const collectionId = ref(null);
const modalState = ref({
    assignTask: false
});
const definitionStyles = [
    { min: 0, bg: '#AD2B81' },
    { min: 20, bg: '#ABA62B' },
    { min: 40, bg: '#8D8D8D' },
    { min: 60, bg: '#2BAD7E' },
    { min: 80, bg: '#2B80AD' }
]
export const getCellBgByRange = val => {
    let def = cloneDeep(definitionStyles).reverse().find(({ min }) => val >= min) || { bg: '#ABA62B' };
    return def.bg
}

const progressCellBg = {
    team: '#385E8A',
    team_member: '#57C88B',
    plan: '#1C907C',
    project: '#1C907C',
    module: '#4E8F99',
    level: '#4E8F99'
}

const progressDefinition = {
    first: 'Bad progress',
    second: 'Good progress',
    third: 'Very good progress',
    forth: 'Awesome progress',
    fifth: 'Mind blowing'
}


export const getProgressCellBg = (iAm, val) => {
    if (iAm == 'team_member') {
        let value = Number(val.replace(/%/g, ''))
        return value > 100 ? '#AD2B81' : progressCellBg[iAm]
    }
    return progressCellBg[iAm] || '#4E8F99'
}
export const useProjectManagement = () => {
    const currentIndex = ref(0)
    const isolated = ref(false);

    const activeComponent = computed(() => components[currentIndex.value]);
    const updateTabIndex = (index) => {
        currentIndex.value = index
    }
    const removeInvalidEfficiency = (item) => {
        const allMember = [].concat(...item.child.map(team => team.child));
        if(!allMember?.length) return
        for(let member of allMember) {
            if( member.monthly_efficiency) {
                member.monthly_efficiency = fillEfficiency(member.monthly_efficiency, item)
            }
        }
    }
    const getProjectManagement = async (projectId) => {
        localPlannings.value = await axios.get(`project-management/${projectId}`).then(({ data }) => data.data.map(item => transformPlanData(item)));
        localDataCopy.value = cloneDeep(localPlannings.value)
    }
    const onlyTask = (state, config) => {
        localPlannings.value  = !state ? cloneDeep(localDataCopy.value) : getLastLevelsUnderModule(localPlannings.value, item => item.is_last_level);
        if(config.sync) {
            // updateProjectSettings()
        }
    }
    const collapseAllRow = (value, config) => {
        collapseRow(localPlannings.value, value);
        if(config.sync) {
            // updateProjectSettings(settings.value);
        }
    }
    const getProgressDefinition = (progress) => {
        if(progress < 21) {
           return progressDefinition['first'];
        }
        if(progress > 21 && progress < 41) {
            return progressDefinition['second'];
        }
        if(progress > 41 && progress < 61) {
            return progressDefinition['third'];
        }
        if(progress > 61 && progress < 81) {
            return progressDefinition['forth'];
        }
        return progressDefinition['fifth'];
    }
    const plan = computed(() => localPlannings.value.length ? localPlannings.value[0] : {});
    const sync = () => {
        localPlannings.value = localPlannings.value.map(item => transformPlanData(item));
    }
    const updatePlan = async (planId, data) => {
        sync();
        return await axios.post(`/planning/${planId}/update`, data);
    }
    const updatePlanDebounce = debounce((planId, data) => updatePlan(planId, data), 500);
    const updateResource = async (data, id = null) => {
        let localId = id ?? data.id;
        if (data.i_am == 'team_member') {
            return axios.post(`/project-management/resources/${localId}/update-assign-task`, data)
        }
        return updateLevelData(data);
    }
    const updateLevelData = async (data) => {
        let scenarioId = data.scenario_id;
        let levelId = data.id;
        return axios.post(`projects/scenarios/${scenarioId}/update/${levelId}`, data)
    }
    const assignTask = (data) => {
        return axios.post('project-management/resources/assign-task', data)
    }
    const makeIsolation = (item) => {
        set(item, 'isolated', !item.isolated);
        isolated.value = !isolated.value;
        localPlannings.value = isolated.value ? localPlannings.value.filter(plan => plan.id == item.plan_id) : cloneDeep(localDataCopy.value);
    }

    const activePlans = (state) => {
        localPlannings.value  = !state ? cloneDeep(localDataCopy.value) : localPlannings.value.filter(item => item.status == 1);
    }

    const plannings = computed(() => localPlannings.value);
    const setCollectionId = id => collectionId.value = id
    const getPlanUpdateData = ({ project_id, title, description, settings, dedication, weekend_holiday_adjustment, working_hours, materials_equipment, estimated_billable_hours, avg_hourly_rate, status }) => ({
        project_id, title, description, settings, dedication, weekend_holiday_adjustment, working_hours, materials_equipment, estimated_billable_hours, avg_hourly_rate, status
    })
    return {
        getProjectManagement,
        updateResource,
        updateLevelData,
        getProgressDefinition,
        collapseAllRow,
        makeIsolation,
        activePlans,
        updateTabIndex,
        onlyTask,
        plannings,
        plan,
        modalState,
        sync,
        getPlanUpdateData,
        components,
        currentIndex,
        activeComponent,
        updatePlanDebounce,
        assignTask,
        collectionId,
        setCollectionId,
        getProgressCellBg,
        removeInvalidEfficiency
    }

}