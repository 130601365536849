import { render, staticRenderFns } from "./BasicInfo.vue?vue&type=template&id=5aed4e71&scoped=true&"
import script from "./BasicInfo.vue?vue&type=script&setup=true&lang=js&"
export * from "./BasicInfo.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aed4e71",
  null
  
)

export default component.exports