<template>
    <div :style="{backgroundColor: getCellBg()}" class="text-white flex items-center justify-center h-full w-full">
        <div v-if="editable" class="h-full">
            <div class="h-full">
                <InputCell v-model="localCompletion" />
            </div>
        </div>
        <div v-else class="opacity-50">{{ localCompletion }}</div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { trim, round } from 'lodash'
    import { getCellBgByRange } from '@/views/screens/projectManagement/services/useProjectManagement.js'
    import InputCell from '@/views/screens/projectManagement/components/InputCell.vue'
    const props = defineProps({
        item: Object
    });
    const editable = computed(() => {
        let status = false;
        if(props.item.is_last_level) {
            status = true;
        }
        if(props.item.i_am == 'team_member') {
            status = true;
        }
        return status;
    });
    const emit = defineEmits(['change'])
    const localCompletion = computed({
        get: function() {
            return props.item.totalCompletion ? `${round(props.item.totalCompletion)}%` : '0%'
        },
        set(value) {
            props.item.completion = parseInt(trim(value, '%'));
            emit('change', value)
        }
    })
    const getCellBg = () => {
        let val = parseInt(trim(localCompletion.value, '%'))
        return getCellBgByRange(val)
    }
</script>