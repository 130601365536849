<template>
    <div>
        <HeaderForInstrat />
        <PanzoomComponent :createInstance="createZoomInstance" :space-around="0" >
            <div class="gap-4 pt-4 grid grid-cols-5" style="height: 820px">
                <Column
                    v-for="index in 5" 
                    :key="index"
                >
                    <template #header>
                        <input 
                            class="fs-14 fw-700 w-full border-b border-secondary-four bg-white px-4 flex items-center" style="height: 42px;"
                            v-model="instratColumnTitle[index-1]"
                        >
                    </template>

                    <template #content>
                        <template v-for="(item, i) in levelData" >
                            <Instrat
                                v-if="showData(item, index)"
                                :key="i"
                                :item="item"
                                class="mb-2 panzoom-exclude"
                            />
                        </template>
                    </template>
                </Column>
            </div>
        </PanzoomComponent>
    </div>
</template>

<script setup>
import useInstratBoard from '@/views/screens/projectManagement/services/useInstratBoard.js'
import Instrat from '@/views/screens/projectManagement/fragments/taskBoard/board/Instrat.vue'
import { onMounted, computed, ref, watch } from 'vue'
import { get } from 'lodash'
import axios from 'axios'
import router from '@/routes/routes.js'
import HeaderForInstrat from './header/HeaderForInstrat.vue'
import { planId } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import Column from './Column.vue'
import PanzoomComponent from '@/views/screens/projectManagement/components/PanzoomComponent.vue'


let timeoutId = null
const route = router.currentRoute
const workspaceId = computed(() => route.params.workspaceId)
const props = defineProps({
    planning: Object,
    createZoomInstance: Function,
    resetPanZoom: Function
})
const { selectedModuleId, selectedPropertyId, levelData, planData, getDefinitions } = useInstratBoard()

const loadData = async () => {
    let { data } = await axios.get(`project-management/plan/${props.planning.id}?timeline_view=1`)
    planData.value = data.data
}
const instratColumnTitle = ref([
    'Very Low',
    'Low',
    'Medium',
    'High',
    'Extreme'
])
const showData = (item, index) => {
    if(!selectedModuleId.value ^ !selectedPropertyId.value) return
    switch(index) {
        case 1: return (item.sampleValue >= 0 && item.sampleValue <= 2)
        case 2: return (item.sampleValue > 2 && item.sampleValue <= 4)
        case 3: return (item.sampleValue > 4 && item.sampleValue <= 6)
        case 4: return (item.sampleValue > 6 && item.sampleValue <= 8)
        case 5: return (item.sampleValue > 8 && item.sampleValue <= 10)
    }
    return (item.sampleValue > 0 ? Math.round(item.sampleValue/2)*2 : 2) == (index) * 2
}
const plan = computed(() => props.planning)
onMounted(async () => {
    loadData()
    planId.value = plan.value.id
    await getDefinitions(workspaceId.value)

    if(get(plan.value, 'settings.instratColumnTitle')){
        instratColumnTitle.value = get(plan.value, 'settings.instratColumnTitle')
    }
})

watch([instratColumnTitle], () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
        axios.post(`planning/${planId.value}/update`, {
            settings: {
                instratColumnTitle: instratColumnTitle.value
            }
        })
    }, 1000)
}, { deep: true })
</script>