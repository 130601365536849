<template>
    <div class="p-4 pb-0">
        <p class="fs-10 fw-700 text-color-616161 uppercase">
            {{ getLabel(item.level) }} {{ item.index_code }}
        </p>
        <p class="fs-14 fw-700 text-primary-one">
            {{ item.index_code }} {{ item.title }}
        </p>
        
        <Chart 
            class="mt-8 mb-4" 
            :item="item"
        />

        <p v-if="sliderDefinition?.definition" class="fs-13 fw-700 text-primary-one mb-1">
            {{ sliderDefinition?.definition }}
        </p>
        <p v-if="businessDefinition?.definition" class="fs-12 fw-500 overflow-y-auto" style="height: 70px;">
            {{ businessDefinition?.definition }}
        </p>
    </div>
</template>

<script setup>
    import Chart from './Chart.vue'
    import useInstratBoard from '@/views/screens/projectManagement/services/useInstratBoard.js'
    import { onMounted, ref } from 'vue'

    const props = defineProps({
        item: Object,
        parameterValues: Array,
        parameterHighestValue: [String, Number],
    })
    const { definitions, selectedParameter, selectedModule } = useInstratBoard()
    const getLabel = (level) => {
        let _level = level > 4 ? 4 : level
        let collection_settings = JSON.parse(selectedModule.value.collection_settings)
        return collection_settings?.collections[_level].title
    }

    const sliderDefinition   = ref({})
    const businessDefinition = ref({})

    const getDefinitions = (definitions, definition_id) => {
        if(!definition_id) return ''

        let definition = definitions.find(item => item.id == definition_id)
        
        sliderDefinition.value   = definition.items.find(item => item.type == 1)
        businessDefinition.value = definition.items.find(item => item.type == 3)

        sliderDefinition.value = sliderDefinition.value.range_with_definition[Math.round(props.item.sampleValue/2) - 1]
        businessDefinition.value = businessDefinition.value.range_with_definition[Math.round(props.item.sampleValue/2) - 1]
    }

    onMounted(() => {
        getDefinitions(definitions.value, selectedParameter.definition_id)
    })
</script>