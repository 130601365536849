<template>
    <div class="flex items-center gap-x-3 w-full relative group">
        <button 
            v-if="removeAble" 
            @click="$emit('remove')" 
            class="absolute top-1/2 transform -translate-y-1/2 -left-6 cursor-pointer opacity-0 group-hover:opacity-100"
        >
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.25 7H9.75" stroke="#8D8D8D" stroke-linecap="square" stroke-linejoin="round"></path><rect x="0.5" y="0.5" width="13" height="13" rx="6.5" stroke="#8D8D8D"></rect></svg>
        </button>
        <svg @click="collapseItem(item)" class="cursor-pointer flex-shrink-0" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="13" height="13" rx="0.5" stroke="#1C1C1D"/>
            <path v-if="item.isChildHidden" d="M10.0683 7.076H7.5123V9.668H6.4923V7.076H3.9483V6.152H6.4923V3.548H7.5123V6.152H10.0683V7.076Z" fill="currentColor"/>
            <path v-else d="M9.17931 6.14V7.064H4.39131V6.14H9.17931Z" fill="currentColor"/>
        </svg>
        <div class="flex items-center gap-3">
            <div :style="{backgroundColor: backgroundColor }" class="w-8 h-8 flex-shrink-0 rounded-full uppercase fs-14 font-medium flex justify-center items-center text-white">
                <span>{{ firstChar }}</span>
            </div>
            <p
                v-if="useFor == 'resource'"
                class="truncate text-left fs-12 fw-500"
                :title="item.title"
                :style="{width: adjustedWidth}"
            >{{ item.title }}</p>
            <EditableColumn
                v-if="useFor == 'team'"
                :item="item"
                item-key="title"
                :handelChange="handelChange"
            />
        </div>
        <div v-if="useFor == 'team'" class="flex items-center gap-x-2">
            <button
                @click="onSubmit(item.id)"
                class="ml-auto mr-3 font-medium text-primary-four fs-13 flex-shrink-0"
                :class="{'opacity-75' : loading}"
                :disabled="loading"
            >+ Add Member</button>
        
            <ColorPicker v-model="backgroundColor" @change="handelChange">
                <template #trigger="{colorCode, openColorPanel}">
                    <div
                        @click="openColorPanel"
                        :style="{backgroundColor: colorCode}" 
                        class="h-4 w-4 cursor-pointer bg-green-100"
                    ></div>
                </template>
            </ColorPicker>
        </div>
    </div>
</template>

<script setup>

    import { set, ref, computed } from 'vue';
    import { get, set as objectAssign, debounce } from 'lodash'
    import { useTeam } from '@/views/screens/projectManagement/services/useTeam.js';
    import EditableColumn from '@/views/screens/projectManagement/fragments/teams/table/EditableColumn.vue'
    import  ColorPicker  from '@/plugins/picker/color/ColorPicker.vue'
    const props = defineProps({
        item: Object,
        settings: {
            type: Object
        },
        removable: {
            type: Boolean,
            default: () => false
        },
        useFor: {
            type: String,
            default: () => 'team'
        },
        collapsable: {
            type: Boolean,
            default: () => false
        },
        removeAble: {
            type: Boolean,
            default: () => false
        }
    });
    const loading = ref(false);
    const { createTeamMember, updateTeam, getTeamsByProject } = useTeam();
    const onSubmit = async (teamId) => {
        loading.value = true;
       await createTeamMember({
            resource_team_id: teamId,
            title: 'Default Member'
        });
        getTeamsByProject();
        loading.value = false;
    }
    const handelChange = debounce(() => {
        updateTeam(props.item)
    },1000);
    const titleWidth = 210//in px
    const adjustedWidth = computed(() => {
        if(props.offset) return `calc(${titleWidth}px - ${props.offset}px)`
        return `${titleWidth}px`
    });
    const backgroundColor = computed({
        get() {
            return props.item?.settings?.backgroundColor || '#dddd'
        },
        set(value) {
            set(props.item.settings, 'backgroundColor', value);
        }
    });
    const firstChar = computed(() => props.item.title?.charAt(0))
    const collapseItem = (item) => set(item, 'childVisibility', !item.childVisibility)
</script>