<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">Synchorize implementation plan</h1>
        <h2 class="fw-500 fs-14 text-color-616161">
            Instrat 360 made it easy to implement your module or project to your JIRA or Azurevboard. Let’s try our simple methods.
        </h2>
        <div @click="switchModalComponent('ConfigureJira')" class="mt-10 flex gap-8">
            <div class="box rounded bg-white grid items-center justify-center cursor-pointer">
                <div class="content grid justify-items-center">
                    <JiraIcon />
                    <span class="fw-700 fs-18 text-secondary-one">JIRA</span>
                </div>
            </div>
            <div class="box rounded bg-white grid items-center justify-center cursor-pointer">
                <div class="content grid justify-items-center">
                    <AzureIcon />                  
                    <span class="fw-700 fs-18 text-secondary-one">AZURE</span>
                </div>
            </div>
        </div>
        <BothSideIcon class="mt-8"/>
        <h2 class="fw-800 fs-14 text-primary-four w-48 mt-3">
            You’re fully operational  - Sync Now and check for updates
        </h2>
    </div>
</template>

<script setup>
import JiraIcon from './icons/JiraIcon.vue';
import AzureIcon from './icons/AzureIcon.vue';
import BothSideIcon from './icons/BothSideIcon.vue';

const props = defineProps({
    integrationMethods: Object
})

const { switchModalComponent } = props.integrationMethods
</script>

<style scoped>
    .box{
        border: 1px solid #EDEDED;
        width: 220px;
        height: 140px;
        box-shadow: 0px 1px 6px rgba(44, 52, 65, 0.1);
    }

    .box:hover{

        box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.12);
    }
</style>