<template>
    <div class="grid items-center h-full h-600px">
        <div>
            <div class="progress w-36 h-36 grid items-center justify-center mx-auto border-2 rounded-full mb-16">
                <h1 class="fw-600 fs-28 text-primary-four">64%</h1>
            </div>
            <h1 class="fs-28 fw-700 text-secondary-one text-center">Preparing Your Project</h1>
            <h2 class="w-457px text-center mx-auto mt-3">Please wait while we set up your project according to your preferences</h2>
            <svg class="mt-14 block mx-auto" width="22" height="3" viewBox="0 0 22 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.5H21" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>                
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    integrationMethods: Object
})
const { switchModalComponent } = props.integrationMethods
</script>

<style scoped>
    .w-457px{
        width: 457px;
    }
    .h-600px{
        height: 600px;
    }
</style>