<template>
    <div class="flex items-center gap-2" :title="tooltipText">
        <div v-if="title" class="text-white fs-12 fw-500">
            {{ title }}
        </div>
        <ColorPicker v-model="localColor" @change="$emit('change')">
            <template v-slot:trigger="{ colorCode, openColorPanel }">
                <div @click="openColorPanel"
                    class="w-4 h-4 shadow rounded-sm cursor-pointer shadow-one border border-white"
                    :style="{backgroundColor: colorCode}"
                >
                </div>
            </template>
        </ColorPicker>
    </div>
</template>

<script setup>
    import ColorPicker from '@/plugins/picker/color/ColorPicker.vue';
    import { computed } from 'vue';
    const props = defineProps({
        title: {
            type: [String, Boolean],
            required: true
        },
        tooltipText: {
            type: [String],
            default: ''
        },
        value: {
            required: false
        }
    });
    const emit = defineEmits(['input', 'change']);

    const localColor = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value)
        }
    });
</script>