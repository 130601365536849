<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">Select or deselect data and assignments to be synchronized </h1>
        <h2 style="width: 688px" class="fw-500 fs-14 text-primary-one">
            Now that you setup the mapping in the previous step, you can deselect data that you don't want to synchronize.  Additionally you can manage the assignment for individual Epics and tasks.
        </h2>
        <div style="height: 451px;" class="overflow-y-scroll mt-4">
            <CollectionPanel
                :data="data"
                :settings="settings"
                :showConfigurationModal="showConfigurationModal"
                :showTask="showTask"
                :loadData="loadData"
                :handleSprintChange="handleSprintChange"
                :jiraForm="jiraForm"
                :sprints="sprints"
                :versions="versions"
            />
        </div>

        <div
            :class="{'opacity-20 pointer-events-none': loader}"
            class="absolute left-10 bottom-5">
            <Buttons
                @back="switchModalComponent('ConfigureMapping')"
                @next="jiraHandle(data)"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import CollectionPanel from './CollectionPanel/Index';
import Buttons from '@/components/planning/integration/Buttons.vue'
import { getMappingStatus } from '@/components/planning/integration/jira/jiraHelper.js'
import { getFlattenCollections } from '@/store/modules/global/services';

const props = defineProps({
    integrationMethods: Object,
    data: Array,
    loadData: Function,
})

let dataArr = []

const { createJiraBulkIssue, loader, switchModalComponent, getSprints, sprints, handleSprintChange, jiraForm, getJiraVersions, versions } = props.integrationMethods

const settings = ref({
    taskColor: '#F9F9F9'
})

const showConfigurationModal = (from, item) => {
    // For adding project we need plan
    if(from == 'project') {
        plan.value = item;
    }
    // For adding module we need project
    if(from == 'module') {
        project.value = item;
    }
    tab.value = from;
    showCreateModal.value = true;
}

const showTask = (item) => {
    showTaskModal.value = true;
    taskParent.value = item;
}

const jiraHandle = (collections) => {
    dataArr = []
    prepareData(collections)
    createJiraBulkIssue(dataArr, 'Success')
}

const prepareData = (collections, parent_i_am=null, parentId = null, level={}) => {
    collections.forEach(collection => {
        const mapInfo = getMappingStatus(collection, jiraForm.value)
        if(mapInfo && !collection.notMapped) {
            let collectionObj  = {
                id: getId(collection),
                ownId: collection.id,
                i_am: collection.i_am,
                map_id: mapInfo.jira.id,
                sprint: collection.jira_link.sprint || null,
                version: collection.jira_link.version || null,
                parent_id: parentId,
                parent_i_am: parent_i_am,
                title: collection.title,
                child: []
            }
            const {jira: {hierarchyLevel}} = mapInfo
            const flatDataArr = getFlattenCollections(dataArr)
            const higherLevel = level[hierarchyLevel +1] || level[hierarchyLevel +2]
            // console.log(level, hierarchyLevel, level[0])
            if(higherLevel) {
                const parent = flatDataArr.find(item => item.ownId == higherLevel.id)
                parent.child.push(collectionObj)
            } else {
                dataArr.push(collectionObj)
            }
            if(hierarchyLevel>-1) level[hierarchyLevel] = {id: collection.id}
        }

        if(collection.child.length) {
            prepareData(collection.child, collection.i_am, collection.id, level)
        } 
    })
}

const getId = (collection) => {
    if(collection.i_am == 'module') return collection.module_id
    if(collection.i_am == 'project') return collection.project_id
    return collection.id
}

onMounted(()=> {
    getSprints()
    getJiraVersions()
})
</script>
