const _getQuarter = (date) => {
    return date.getFullYear() + ' Q' + Math.ceil((date.getMonth() + 1) / 3);
}
export const _listQuarters = (sDate, eDate) =>  {
    if (sDate > eDate) {
        var t = eDate;
        eDate = sDate;
        sDate = t;
    }
    sDate = new Date(sDate);
    sDate.setDate(2);

    var startQ = _getQuarter(sDate);
    var endQ   = _getQuarter(eDate);

    var result = [startQ];
    while (startQ != endQ) {
        sDate.setMonth(sDate.getMonth() + 3);
        startQ = _getQuarter(sDate);
        result.push(startQ);
    }
    return result;
}