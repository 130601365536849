<template>
    <InputCell
        v-if="has(item, 'user')"
        v-model="localItem"
        v-slot="{ setValue, value }"
    >
        <input
            @input="setValue"
            :value="value"
            type="text"
            class="w-full fs-13"
            autocomplete="off"
        />
    </InputCell>
    <div v-else> {{ localItem }} </div>
</template>

<script setup>
    import InputCell from '@/views/screens/projectManagement/components/InputCell.vue';
    import { useTeam } from '@/views/screens/projectManagement/services/useTeam.js'
    import { computed } from 'vue';
    import { trim, has } from 'lodash'

    const props = defineProps({
        item: Object
    });

    const { updateTeamMember, sync } = useTeam();

    const localItem = computed({
        get() {
            return props.item.totalEfficiency ? `${props.item.totalEfficiency}%` : ``;
        },
        set(value) {
            props.item.efficiency = parseInt(trim(value, '%'));
            sync();
            updateTeamMember(props.item);
        }
    });
</script>