import { ref } from 'vue'
// import { debounce } from 'lodash'

const show = ref(false)
const left = ref(0)
const top = ref(0)

export default function useTooltip() {
    const handleDisplay = (display, x, y) => {
        show.value = display
        if (left) left.value = `${x+20}px`
        if (top) top.value = `${y+50}px`
    }

    const onMouseOver = (event) => {
        if(event.target.closest('.vdp-datepicker__calendar')){
            handleDisplay(false)
            return
        }
        handleDisplay(true, event.clientX, event.clientY)
    }

    return { show, left, top, onMouseOver, handleDisplay }
}