<template>
    <div class="p-4 pb-0">        
        <Chart 
            class="mt-2 mb-2" 
            :item="item"
        />
        
        <div class="mt-1 text-secondary-one fs-10 fw-500 grid gap-3">
            <div class="flex gap-2 items-center"
                v-for="(parameter, index) in systemParameters" 
                :key="index"
            >
                <span class="flex-shrink-0 truncate" style="width: 123px;">{{ parameter.title }}</span>
                <div class="w-full">
                    <InputRange 
                        :disabled="!item.is_last_level"
                        :value="getRangeValue(item, parameter.id, parameterValues)"
                        @onMouseUp="(value) => updateValue(parameter.id, value)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Chart from './Chart.vue'
import useInstratBoard from '@/views/screens/projectManagement/services/useInstratBoard.js'
import InputRange from './InputRange.vue'

const props = defineProps({
    item: Object,
    parameterHighestValue: [String, Number],
})

const { systemParameters, parameterValues, updateSampleValue, getRangeValue } = useInstratBoard()

const updateValue = async (parameter_id, sample_value) => {
    let selectedParameter = parameterValues.value[parameter_id]
    
    let selectedItem = selectedParameter.find(_item => _item.id == props.item.id)
    selectedItem.sample_value = sample_value

    let payload = {
        module_id: props.item.module_id,
        scenario_id: props.item.scenario_id,
        sample_value,
        module_collection_id: null,
        property_collection_id: parameter_id,
        scenario_collection_id: props.item.id
    }
    await updateSampleValue(payload, () => {
        if(props.item.selectedParameter.id == parameter_id){
            props.item.sampleValue = sample_value
        }
    })
}

</script>

<style scoped>
</style>