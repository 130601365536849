<template>
    <vue-draggable-resizable
        class="panzoom-exclude resizable-element z-10"
        :active="false"
        :handles="['ml','mr']"
        :w="width"
        :h="30"
        :z="isOpen ? 999999999 : 10"
        :x="left"
        parent
        axis="x"
        :draggable="isEditable(item)"
        :resizable="isEditable(item)"
        :prevent-deactivation="true"
        @resizing="handleResize"
        @onMounted="handleTextPosition"
        @onUpdated="handleTextPosition"
        @dragging="handleDrag"
    >
        <div
            class="progress h-full w-full rounded-sm border-opacity-50 flex items-center"
            :style="{
                backgroundColor: setOpacityOnColor(backgroundColor, 0.90),
                border: `2px solid ${setOpacityOnColor(backgroundColor, 1)}`
            }"
            @dblclick="$emit('collapseItem')"
            @mousemove.stop="handleMouseOver($event, item)"
            @mouseleave="handleDisplay(false)"
        >
            <div class="flex items-center justify-between w-full ">
                <div :style="{ ...getWidthInChUnit(item) }" class="inner-level text-white fs-12 ml-3 relative inline-block overflow-hidden truncate"> 
                    {{ item.index_id }}{{ item.index_code }} {{ item.title }} 
                </div>

                <div v-if="simpleMode && !item.is_last_level" class="avatar flex items-center">
                    <div class="h-full mr-1 flex-shrink-0">
                        <AvatarGroup source-kye="img_path" :limit="3" :dimension="18" :avatars="avatars" />
                    </div>
                    <button class="p-2 flex-shrink-0 rounded-full" @click.stop="isOpen = !isOpen">
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.75 1L4 3.75L1.25 1" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>               
                    </button>
                </div>
            </div>
            <div
                v-if="['right', 'left'].includes(levelLocation)"
                class="text-primary-one fs-12 ml-3 absolute whitespace-nowrap flex items-center gap-4"
                :class="(levelLocation == 'right') ? 'left-full' : (levelLocation == 'left') ? 'right-full mr-3' : ''"
            >
                {{ item.index_id }}{{ item.index_code }} {{ item.title }}
                
                <div v-if="simpleMode && !item.is_last_level" class="avatarOutside flex items-center">
                    <div class="h-full mr-1 flex-shrink-0">
                        <AvatarGroup source-kye="img_path" :limit="3" :dimension="18" :avatars="avatars" />
                    </div>
                    <button class="p-2 flex-shrink-0 rounded-full" @click.stop="isOpen = !isOpen">
                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 1L3.75 3.75L1 1" stroke="#1C1C1D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                                          
                    </button>
                </div>
            </div>
        </div>
        <div 
            v-if="isOpen" 
            class="z-10 pt-1 pr-4 rounded shadow-two"
            :style="{
                marginTop: '2px',
                backgroundColor: setOpacityOnColor(backgroundColor, 1),
                minWidth: '300px',
                maxWidth: '100%',
                height: '101px'
            }"
        >
            <ProgressPanel :item="item">
                <template v-slot:avatar>
                    <AvatarGroup source-kye="img_path" :limit="3" :dimension="18" :avatars="avatars" />
                </template>
            </ProgressPanel>
        </div>
    </vue-draggable-resizable>
</template>

<script setup>
    import { ref, computed, watch } from 'vue'
    import VueDraggableResizable from '@/plugins/vue-draggable-resizable/Index';
    import '@/assets/css/vue-draggable-resizable.css'
    import useHoverItem from '@/components/planning/useHoverItem.js';
    import AvatarGroup from '@/elements/atom/AvatarGroup.vue'
    import { getParameterValueFromHistory } from '@/store/services/traits/parameter.js';
    import { find } from 'lodash'
    import ProgressPanel from '@/components/planning/TimelinePanel/progressPanel/Index.vue'

    

    const levelLocation = ref(null)
    const isOpen = ref(false)
    const { setHoveredItem } = useHoverItem()
    let timeoutId = null
    const props = defineProps({
        item: Object,
        modules: Array,
        width: Number,
        left: Number,
        settings: Object,
        backgroundColor: String,
        handleDisplay: Function,
        onMouseOver: Function,
    });
    const simpleMode = computed(() => {
        if(!props.settings) {
            return true;
        }
        return props.settings.simpleMode;
    })
    const avatars = computed(() => {
        if(!props.item.totalMembers) {
            return [];
        }
        return props.item.totalMembers;
    });

    const getWidthInChUnit = (item) => {
        return { width: `${(item.index_id + item.index_code + item.title).length}ch` }
    }
    const handleTextPosition = ({target, left, width}) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            let textBox = target.$el.querySelector('.inner-level')
            let avatar = target.$el.querySelector('.avatar')
            
            if(textBox.parentElement.scrollWidth < (textBox.scrollWidth + 60))
            {
                let parentElement = textBox.closest('.parent-div')
                levelLocation.value = calculateThePosition(parentElement, textBox)
                textBox.style.opacity = 0
                textBox.style.width = 0
                textBox.style.pointerEvents = 'none'
                if(avatar) avatar.style.display = 'none'
            }else{
                levelLocation.value = null
                textBox.style.opacity = 1
                textBox.style.pointerEvents = 'auto'
                textBox.style.width = 'auto'
                if(avatar) avatar.style.display = 'flex'
            }
        }, 1000)
    }

    const handleResize = (data) => {
        handleTextPosition(data)
        emit('onResize', data)
    }

    const calculateThePosition = (parent, child) => {
        let childWidth = child.innerText.length * 8
        let transformData = getComputedStyle(child.closest('.resizable-element')).transform
        transformData = transformData.replaceAll('matrix(', '').replaceAll(')', '')
        transformData = transformData.split(',')
        transformData = transformData.map(item => Number(item))
        
        let leftPosition = Math.max(...transformData);
        if(((childWidth * 2 + 20) + leftPosition) > parent.clientWidth){
            return 'left'
        }
        return 'right'
    }

    const progress = ref(0);
    watch(() => props.item.parameters, (value) => {
        let data = find(value, 'active');
        let module = props.modules?.find(module => module.module_id == props.item.module_id);
        if(!module || !data) {
            return 0;
        }
        progress.value = getParameterValueFromHistory(props.item, data, module.parameter_values) * 10;
    }, {deep: true});

    const emit = defineEmits(['onResize', 'onDrag'])

    const handleDrag = (left, width, top, handle) => {
        emit('onDrag', {left, width, top, handle})
    }
    const setOpacityOnColor = (color, opacityValue) => {
        // return color;
        return `${color}${Math.floor(opacityValue * 255).toString(16).padStart(2, 0)}`
    }
    const handleMouseOver = (event, item) => {
        setHoveredItem(item)
        props.onMouseOver(event)
    }

    const isEditable = item => {
        if(['plan', 'project', 'module'].includes(item.i_am)) {
            return false;
        }else {
            return item.is_last_level;
        }
    }
</script>

<style scoped>
    .progress::before {
        content: '';
        top: 0;
        position: absolute;
        width: calc(v-bind(progress) * 1%);
        height: 100%;
        background: rgba(0,0,0, .2);
    }
</style>