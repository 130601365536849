<template>
    <div class="relative">
        <div
            @dblclick="makeIsolation(item)"
            class="item flex items-center justify-between mt-8 i-h-50 shadow-one rounded bg-primary-four cursor-pointer"
            :class="{'border border-primary-one': item.isolated}"
            :style="{backgroundColor: getBackground(item)}"
        >
            <span class="font-poppins font-semibold text-primary-three fs-14 pl-4">{{ item.index_id }}{{ item.index_code }}  {{ item.title }}</span>
            <div v-if="['plan', 'project'].includes(item.i_am) && item.child.length > 0" @click="expandItem(item)" class="icon cursor-pointer mr-3">
                <img
                    src="/images/icons/chevron_down_black.svg"
                    alt="icon"
                    :class="{'rotate-180': item.expand}"
                >
            </div>
        </div>
        <div v-if="!item.expand && ['plan', 'project', 'module'].includes(item.i_am) && item.child.length > 0">
            <Item
                v-for="(item, index) in item.child"
                :key="`${item.id}-${index}`"
                :makeIsolation="makeIsolation"
                :item="item"
                :settings="settings"
            />
        </div>
    </div>
</template>

<script setup>
    import Item from './Item.vue';
    import { set } from 'vue'
    const props = defineProps({
        item: Object,
        makeIsolation: Function,
        settings: Object
    });
    const expandItem = (item) => {
        set(item, 'expand', !item.expand);
    }
    const getBackground = (item) => {
        if(item.i_am == 'project') {
            return props.settings.projectColor || '#009688'
        }

        if(item.i_am == 'plan') {
            return props.settings.planColor || '#009688'
        }
        if(item.i_am == 'module') {
            return props.settings.moduleColor || '#3485AF'
        }

        if(item.child.length <= 0) {
            return props.settings.taskColor || item.background_color || '#009688';
        }
        return props.settings.periodColor || item.background_color || '#1C917C';
    }
</script>

<style scoped>
    .icon {
        display: none;
    }
    .item:hover .icon{
        display: block;
    }
</style>