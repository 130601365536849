<template>
    <Modal v-model="modalVisibility">
        <div style="width: 680px">
            <Header title="Task Assign" />
            <main class="p-6">
                <div class="flex gap-x-5">
                    <div class="w-full">
                        <P class="fs-12 mb-2">Select Team</P>
                        <InputSelect
                            class="w-full"
                            v-model="form.resource_team_id"
                            @change="form.team_member_id = null"
                            :options="teams"
                        />
                    </div>
                    <div class="w-full">
                        <p class="fs-12 mb-2">Select Member</p>
                        <InputSelect
                            class="w-full"
                            itemText="name"
                            v-model="form.team_member_id"
                            :options="geMembers(teams, form.resource_team_id)"
                        />
                    </div>
                </div>
                <Footer
                    submit-title="Submit"
                    :submit="submit"
                    :cancel="close"
                />
            </main>
        </div>
    </Modal>
</template>

<script setup>
    import Modal from '@/elements/atom/Modal';
    import Header from '@/components/planning/components/ModalHeader.vue';
    import Footer from '@/components/planning/components/ModalFooter.vue';
    import InputSelect from '@/components/planning/components/InputSelect.vue';
    import { ref, computed } from 'vue';
    const emit = defineEmits(['input', 'onSubmit']);
    const props = defineProps({
        value: Boolean,
        teams: Array,
        collectionId: [Number, String]
    });

    const form = ref({
        resource_team_id: null,
        team_member_id: null,
        scenario_collection_id: null,
        compliance: 0,
        monthly_efficiency: {}
    })

    const modalVisibility = computed({
        get() {
            return props.value
        },
        set(value) {
            emit('input', value);
        }
    });
    const submit = async () => {
        form.value.scenario_collection_id = props.collectionId
        emit('onSubmit', form.value)
        close();
    }
    const close = () => {
        modalVisibility.value = false;
    }

    const geMembers = (teams, teamId)=> teamId ? teams.find(({id}) => id == teamId).child : []
</script>