<template>
    <div>
        <HeaderForJira />
        <div style="height: 820px;">
            <PanzoomComponent :createInstance="createZoomInstance" :space-around="0" >
                <div 
                    class="gap-4 pt-4 flex overflow-x-auto"
                    v-if="!isEmpty(jiraColumns)"
                >
                    <div
                        v-for="(column, index) in jiraColumns" 
                        :key="index" 
                        class="border border-secondary-four overflow-hidden rounded-lg flex-shrink-0 h-fll"
                        style="width: 359px"
                    >
                        <div class="fs-14 fw-700 border-b border-secondary-four bg-white px-4 flex items-center sticky top-0 z-50" style="height: 42px;">
                            {{ column.name }}
                        </div>
                        <div class="h-full overflow-y-auto" style="padding: 10px; background: #F5F5F5; min-height: 796px;">
                            <template v-if="!isEmpty(jiraData)">
                                <template v-for="(item, index) in jiraData">
                                    <Jira
                                        v-if="showData(column, item)"
                                        :key="index"
                                        class="mb-2"
                                        :item="item"
                                    />
                                </template>
                            </template>
                            <div v-else class="py-4 text-center text-gray-400 text-sm">Please configure board.</div>
                        </div>
                    </div>
                </div>
                
                <div v-else class="bg-white py-4 text-center text-gray-400">Column not found. Please check jira.</div>
            </PanzoomComponent>
        </div>
    </div>
</template>

<script setup>
import Jira from '@/views/screens/projectManagement/fragments/taskBoard/board/Jira.vue'
import useJiraTaskBoard from '@/views/screens/projectManagement/services/useJiraTaskBoard.js'
import { onMounted, ref, watch, computed } from 'vue'
import axios from 'axios'
import { isEmpty, cloneDeep, get } from 'lodash'
import HeaderForJira from './header/HeaderForJira.vue'
import { filterData, planId, colors } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import PanzoomComponent from '@/views/screens/projectManagement/components/PanzoomComponent.vue'


const props = defineProps({
    planning: Object,
    createZoomInstance: Function,
    resetPanZoom: Function
})
const { jiraColumns, loadBulkJiraIssue, jiraData, showData, getLevelData } = useJiraTaskBoard()

const localLevelData = ref(null)
const loadData = async () => {
    let { data } = await axios.get(`project-management/plan/${props.planning.id}?timeline_view=1`)
    localLevelData.value = data.data
    loadBulkJiraIssue(getLevelData(cloneDeep(localLevelData.value)))
}

const plan = computed(() => props.planning)
onMounted(async () => {
    loadData()
    planId.value = plan.value.id
    if(get(plan.value, 'settings.colors')){
        colors.value = get(plan.value, 'settings.colors')
    }
})

watch(filterData, () => {
    loadBulkJiraIssue(getLevelData(cloneDeep(localLevelData.value)))
}, { deep : true })
</script>