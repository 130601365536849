<template>
    <div>
        <HeaderForCompletion />
        <PanzoomComponent :createInstance="createZoomInstance" :space-around="0" >
            <div class="gap-4 pt-4 grid grid-cols-5" style="height: 820px">
                <Column
                    v-for="index in 5" 
                    :key="index"
                >
                    <template #header>
                        <input 
                            class="fs-14 fw-700 w-full border-b border-secondary-four bg-white px-4 flex items-center" style="height: 42px;"
                            v-model="completionColumnTitle[index-1]"
                        >
                    </template>
                    <template #content>
                        <template v-for="(item, i) in levelData" >
                            <completion
                                v-if="showData(item, index)"
                                :key="i"
                                :item="item"
                                :planning="planning"
                                class="mb-2"
                            />
                        </template>
                    </template>
                </Column>
            </div>
        </PanzoomComponent>
    </div>
</template>
    
<script setup>
import Completion from '@/views/screens/projectManagement/fragments/taskBoard/board/Completion.vue'
import useTaskBoard from '@/views/screens/projectManagement/services/useTaskBoard.js'
import HeaderForCompletion from './header/HeaderForCompletion.vue'
import { onMounted, ref, watch, computed } from 'vue'
import { cloneDeep, get } from 'lodash'
import { filterData, planId, colors } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import Column from './Column.vue'
import axios from 'axios'
import PanzoomComponent from '@/views/screens/projectManagement/components/PanzoomComponent.vue'

let timeoutId = null
const props = defineProps({
    planning: Object,
    teams: Array,
    createZoomInstance: Function,
    resetPanZoom: Function
})
const { loadData, levelData, originalLevelData, getLevelData, completionColumnTitle } = useTaskBoard()
const showData = (item, index) => {
    if(!item.completion) item.completion = 0
    switch(index) {
        case 1: return (item.completion >= 0 && item.completion <= 20)
        case 2: return (item.completion > 20 && item.completion <= 40)
        case 3: return (item.completion > 40 && item.completion <= 60)
        case 4: return (item.completion > 60 && item.completion <= 80)
        case 5: return (item.completion > 80 && item.completion <= 100)
    }
    return ((item.completion/10) > 0 ? Math.round((item.completion/10)/2)*2 : 2) == (index) * 2
}

const plan = computed(() => props.planning)
onMounted(async () => {
    loadData(props.planning)
    planId.value = plan.value.id
    if(get(plan.value, 'settings.colors')){
        colors.value = get(plan.value, 'settings.colors')
    }
    if(get(plan.value, 'settings.completionColumnTitle')){
        completionColumnTitle.value = get(plan.value, 'settings.completionColumnTitle')
    }
})

watch(filterData, () => {
    levelData.value = getLevelData(cloneDeep(originalLevelData.value))
}, { deep: true })

watch(completionColumnTitle, () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
        axios.post(`planning/${planId.value}/update`, {
            settings: {
                completionColumnTitle: completionColumnTitle.value
            }
        })
    }, 1000)
}, { deep: true })
</script>