<template>
    <div class="flex gap-x-4">
        <button  
                v-for="{title, emit} in buttons" 
                :key="emit"
                @click="$emit(emit)"
                class="fs-14 fw-500 rounded shadow-one border border-primary-four"
                style="height: 38px; width: 100px"
            >
                {{ title }}
            </button>
    </div>
</template>

<script setup>
const buttons = [{title: 'Back', emit: 'back'}, {title: 'Next', emit: 'next'}]
</script>