<template>
    <div v-if="selectedItem.hasOwnProperty('parent_id')" class="px-6 mt-4">
        <div class="flex justify-between items-center pb-4 border-b border-secondary-four cursor-pointer">
          <h1 @click="commentToggle = !commentToggle" class="fw-700 fs-14 uppercase">Comments</h1>
          <button @click="commentToggle = !commentToggle" class="cursor-pointer">
            <svg class="transform" :class="commentToggle ? 'rotate-0' : 'rotate-180'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75098 12.5L10.001 6.25L16.251 12.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div v-if="commentToggle">
            <Comments
                :list-type="false"
                :wrapper-classes="''"
                :min-show-length="5"
                :comments="comments"
                :active-id="selectedItem.id"
            />
        </div>
    </div>
</template>

<script setup>
import { useRightPanel } from '@/components/planning/useRightPanel.js'
import Comments from "@/components/InstMenu/right-navigation-drawer/module/Comments"
import store from "@/store"
import { ref, computed, watchEffect } from 'vue'

const { selectedItem } = useRightPanel()
const commentToggle = ref(false)
const comments = computed(() => {
    return store.getters["drawer/getComments"]
})

function fetchComments() {
    store.dispatch("drawer/fetchComments", {
        id: selectedItem.value.id,
        key: 'scenario_collection_id'
    })
}

watchEffect(async ()=> {
  fetchComments()
})
</script>
