<template>
    <Header class="z-40 relative">
        <template #filter>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two uppercase">Sort By</span>
                <select v-model="filterData.sortType" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option value="progress">Progress</option>
                    <option value="completion">Completion</option>
                </select>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two uppercase">Filter By</span>
                <select v-model="filterData.groupType" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option value="last-level" selected>Last Level</option>
                    <option value="level-1">Group</option>
                </select>
            </div>
            <div v-click-outside="() => showColors = false" class="grid relative">
                <span class="fs-10 fw-800 text-secondary-two uppercase">Color</span>
                <span @click="showColors = !showColors" class="fs-13 fw-600 w-auto border-0 cursor-pointer flex items-center gap-1" style="width: fit-content;">
                    Select
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.375 5.62207L7 9.99707L2.625 5.62207" stroke="#1C1C1D" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>                        
                </span>

                <div 
                    v-if="showColors"
                    class="rounded bg-white absolute top-full left-0 w-full min-h-10 fs-12 fw-600 p-4 grid select-none" 
                    style="width: 196px; gap: 10px;"
                >
                    <label 
                        v-for="(item, index) in colors"
                        :key="index"
                        class="relative flex items-center justify-between px-6 rounded text-white" 
                        :style="{height: '36px', backgroundColor: item.color}"
                    >
                        <input type="color" v-model="item.color" class="absolute opacity-0">
                        <span>{{ item.range }}</span>
                        <span>{{ item.label }}</span>
                    </label>    
                </div>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two uppercase">SHOW</span>
                <select v-model="filterData.show" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option value="all-task" selected>All Task</option>
                    <option value="my-task">My Task</option>
                </select>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two uppercase">TIME</span>
                <select v-model="filterData.time" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option value="all">All</option>
                    <option value="start-date">Start Date</option>
                    <option value="end-date">End Date</option>
                </select>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two uppercase">TIME FILTER</span>
                <select v-model="filterData.timeFilter" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option :value="7">Next 7 Days</option>
                    <option :value="14">Next 14 Days</option>
                    <option :value="30">Next 30 Days</option>
                    <option :value="60">Next 60 Days</option>
                    <option :value="90">Next 90 Days</option>
                </select>
            </div>
        </template>
    </Header>
</template>

<script setup>
import { ref } from 'vue'
import Header from './Index.vue'
import { filterData, colors } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'

const showColors = ref(false)
</script>