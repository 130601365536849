<template>
    <apexchart
        :type="type"
        :options="options"
        :height="chartHeight"
        :series="series"
    />
</template>

<script setup>
    import apexchart from 'vue-apexcharts';
    import { computed } from 'vue'
    import { merge, cloneDeep } from 'lodash'
    import defaultOption from './chart.option.default'

    const props = defineProps({
        type: {
            type: String,
            required: true
        },
        series: {
            type: [Array, Object],
            required: true
        },
        chartOptions: {
            type: Object,
        }
    });
    const chartHeight = 375;

    const options = computed(() => merge(cloneDeep(defaultOption), props.chartOptions))

</script>