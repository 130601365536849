<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">Select Jira Project</h1>
        <h2 class="fw-500 fs-14 text-primary-one w-535px">
            Select or create a Jira project to synchronize with instrat360.  
            In the following steps we configure the integration in a few simple steps.
        </h2>

        <h2 @click="showModal = true" class="fw-800 fs-14 text-primary-four cursor-pointer mt-6">
            + Create a new project if no project exists in Jira
        </h2>

        <label class="grid gap-1 mt-5">
            Select your Jira project
            <select v-model="jiraForm.jira_connection_info.projectId" class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                <option :value="null">Select From Dropdown</option>
                <template v-if="jiraProjects && jiraProjects.length">
                    <option
                        v-for="{id, name} in jiraProjects"
                        :key="id"
                        :value="id">{{ name }}
                    </option>
                </template>
            </select>
        </label>
        <h3 class="italic fs-12 fw-500 text-secondary-two mt-3">Choose the Jira project that integrates with your project.</h3>

        <label class="grid gap-1 mt-6">
            Select your board
            <select v-model="jiraForm.jira_connection_info.boardId" class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded">
                <option :value="null">Select From Dropdown</option>
                <template v-if="jiraBoards && jiraBoards.values && jiraBoards.values.length">
                    <option
                        v-for="{id, name} in jiraBoards.values"
                        :key="id"
                        :value="id">{{ name }}
                    </option>
                </template>
            </select>
        </label>
        <h3 class="italic fs-12 fw-500 text-secondary-two mt-3">
            If you plan to sync sprint information you need to select a corresponding board.
        </h3>

        <div class="absolute left-10 bottom-5">
            <Buttons
                @next="updateJiraConfig('ConfigureMapping')"
                @back="switchModalComponent('ConfigureJira')"
            />  
        </div>

        <Modal  v-model="showModal" >
            <ProjectCreate @close="showModal = false" />
        </Modal>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Buttons from '@/components/planning/integration/Buttons.vue'
import Modal from '@/elements/atom/Modal'
import ProjectCreate from '@/components/planning/integration/jira/ProjectCreate.vue'
import useProjectCreate from '@/components/planning/integration/jira/useProjectCreate.js'

const props = defineProps({
    integrationMethods: Object
})
const {
    showModal
} = useProjectCreate()

const {
    switchModalComponent,
    jiraForm,
    getJiraProjects,
    jiraProjects,
    getJiraBoards,
    jiraBoards,
    updateJiraConfig
} = props.integrationMethods

onMounted(() => {
    getJiraProjects()
    getJiraBoards()
})
</script>