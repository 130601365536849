<template>
    <div>
        <h1 class="fw-600 fs-18 text-primary-one">Connect your JIRA account</h1>
        <ul class="fw-500 fs-14 text-primary-one gap-4 pl-5" style="list-style: disc">
            <li class="mb-4">
                If you are using Jira Cloud, you will add a Jira user's email address and that user will need to create an API token in Jira to use as your password. If they do not use an API token the integration will break with a 403 error.
            </li>
            <li>
                If you are using an on-premises installation of Jira, we recommend entering a username and generating a personal access token to authenticate. You can also use a username and password.
            </li>
        </ul>
            <label class="grid gap-1">
                Your Jira URL
                <input
                    type="text"
                    required
                    v-model="jiraForm.jira_url"
                    placeholder="https://xxxxx.atlassian.net"
                    class="w-580px fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
                />
            </label>
            <div class="fs-12 fw-500 text-secondary-two mt-3 flex items-center gap-5">
                <span class="italic">URL for the Jira server, without a trailing slash, </span>
                <span class="bg-primary-three py-1 px-3 rounded">e.g. https://xxxxx.atlassian.net</span>
            </div>
            <div class="flex gap-12">
                    <div class="w-580px">
                        <label class="grid gap-1">
                            Username
                            <input
                                type="text"
                                required="true"
                                placeholder="Username"
                                v-model="jiraForm.username"
                                class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
                            />
                        </label>
                        <h3 class="fs-12 fw-500 text-secondary-two mt-3 flex gap-5">
                            Use your email address or Jira user name from the Jira profile page (depending on the Jira version
                        </h3>
                    </div>
                    <div class="w-411px">
                        <form autocomplete="off" class="mt-0">
                            <label class="grid gap-1">
                                Password
                                <input
                                    id="dfdfd"
                                    type="password"
                                    required="true"
                                    placeholder="*****"
                                    autocomplete="off"
                                    v-model="jiraForm.api_token"
                                    class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 h-10 rounded"
                                />
                            </label>
                            <label class="hidden">
                                Password
                                <input
                                    id="dfdfd"
                                    type="password"
                                    required="true"
                                    autocomplete="off"
                                    v-model="jiraForm.api_token"
                                    class="fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded"
                                />
                            </label>
                            <div  class="fs-12 fw-500 text-secondary-two mt-3">
                                For Jira Cloud you must enter an 
                                <a class="underline" target="_blank" href="https://id.atlassian.com/manage-profile/security/api-tokens">API token.</a>
                                 For Jira on-premise a password can be used.
                            </div>
                        </form>
                    </div>
            </div>
            <label class="grid gap-1 mt-4">
                Validate certificate
                <Checkbox
                    class="fs-14 fw-500 text-color-616161 px-4 py-2"
                    label="Validate your server HTTPS/TLS certificate"
                    @checked="(value) => {}"
                    :value="true"
                />
            </label>

            <div class="absolute left-10 bottom-5">
                <Buttons
                    @back="switchModalComponent('SelectProjectManagementTools')"
                    @next="handleJiraConfig"
                />
            </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
import Buttons from '@/components/planning/integration/Buttons.vue'
import Checkbox from '../../../dashboard/sidebar/components/right-sidebar/Checkbox.vue';

const props = defineProps({
    integrationMethods: Object
})

const { switchModalComponent, handleJiraConfig, jiraForm, getJiraConfig } = props.integrationMethods

onMounted(() => {
    getJiraConfig()
})
</script>
