import moment from "moment"
import { ref, watch } from 'vue'
import axios from "axios"
import { cloneDeep, filter as lodashFilter } from 'lodash'

export const planId = ref(null)
export const filterData = ref({
    sortType: 'progress',
    groupType: 'last-level',
    show: 'all-task',
    time: 'all',
    timeFilter: '7',
    searchText: ''
})

export const colors = ref([
    {
        label: 'Very Low',
        range: '0-2',
        color: '#AD2B81'
    },
    {
        label: 'Low',
        range: '2-4',
        color: '#ABA62B'
    },
    {
        label: 'Medium',
        range: '4-6',
        color: '#8D8D8D'
    },
    {
        label: 'High',
        range: '6-8',
        color: '#2BAD7E'
    },
    {
        label: 'Extreme',
        range: '8-10',
        color: '#2B80AD'
    }
])

const getAssignedMembers = (item, cb) => {
    let flatten = ({ child = [], ...rest }) => [rest, ...child.flatMap(flatten)];
    return lodashFilter(flatten(item), cb)
}

export const filterByGroupType = (data) => {
    let filter = filterData
    if(filterData.value.groupType == 'last-level') {
        return data.filter(item => item.is_last_level)
    }
    if(filterData.value.groupType == 'level-1') {
        return data.filter(item => item.level == 1)
    }
}

const _checkMyExistence = (item) => {
    let teamMembers = getAssignedMembers(item, (item) => item.i_am == 'team_member')
    if(teamMembers){
        let userId = JSON.parse(localStorage._user).id
        return teamMembers.find(({team_member}) => {
            return (team_member?.user_id == userId)
        })
    }
}

export const filterByShow = (data) => {
    if(filterData.value.show == 'all-task') return data
    if(filterData.value.show == 'my-task'){
        let filteredData = data.filter(item => {
            return _checkMyExistence(item)
        })
        return filteredData
    }
}

export const filterByTime = (data) => {
    if(filterData.value.time == 'all') return data

    if(filterData.value.time == 'start-date'){
        let fromDate = moment().format('YYYY-MM-DD')
        let toDate   = moment().add(filterData.value.timeFilter, 'days').format('YYYY-MM-DD')
        
        return data.filter(item => {
            if(moment(item.start_date).format('YYYY-MM-DD') > fromDate && moment(item.start_date).format('YYYY-MM-DD') <= toDate){
                return item
            }
            return false
        })
    }
    if(filterData.value.time == 'end-date'){
        let fromDate = moment().format('YYYY-MM-DD')
        let toDate   = moment().add(filterData.value.timeFilter, 'days').format('YYYY-MM-DD')
        return data.filter(item => {
            if(moment(item.end_date).format('YYYY-MM-DD') > fromDate && moment(item.end_date).format('YYYY-MM-DD') <= toDate){
                return item
            }
            return false
        })
    }
}

export const sorting = (data) => {
    if(filterData.value.sortType == 'default') return data
    if(filterData.value.sortType == 'progress'){
        return data.sort((a, b) => b.progress - a.progress);
    }
    if(filterData.value.sortType == 'completion'){
        return data.sort((a, b) => b.completion - a.completion);
    }

    return data
}

export const searchFilter = (data, key='') =>{
    if(filterData.value.searchText == '') return data
    let searchValue = data.filter(item => {
        if(item[key].toLowerCase().indexOf(filterData.value.searchText.toLowerCase()) >= 0){
            return item
        }
    })
    return searchValue
}


let timeoutId = null
watch(colors, () => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
        axios.post(`planning/${planId.value}/update`, {
            settings: {
                colors: colors.value
            }
        })
    }, 1000)
}, {deep: true})