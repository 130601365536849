<template>
    <apexchart
        type="area"
        :options="options"
        :height="height"
        :series="series"
    />
</template>

<script setup>
    import apexchart from 'vue-apexcharts';
    import { computed } from 'vue'
    import { merge } from 'lodash';

    const props = defineProps({
        series: {
            type: [Array, Object],
            required: true
        },
        chartOptions: {
            type: Object,
            required: true
        },
        height: {
            default: 60,
            type: Number
        }
    });

    const options = computed(() => merge({
        chart: {
            toolbar: {
                show: false
            }
        },
        grid: {
            show: false,
        },
        yaxis: {
            show: false
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        stroke: {
            curve: 'smooth',
            lineCap: 'round',
            width: 1,
            colors: ['#2B80AD']
        },
        fill: {
            type: 'solid',
            colors: ['#2B80AD'],
            opacity: 0.2,
        },
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
            show: false
            }
        },
    }, props.chartOptions))
</script>