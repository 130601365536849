<template>
    <div :style="{width: `${chartWidth}px`}">
        <div class="flex justify-between fs-18 ">
            <slot name="header">
                <p class="fw-600">{{ title }}</p>
                <ChartDropdown v-model="_chartDropdown" />
            </slot>
        </div>
        <BaseChart
            :series="series"
            :type="type"
            :chartOptions="chartOptions"
        />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import BaseChart from '@/views/screens/projectManagement/fragments/chart/BaseChart.vue'
import ChartDropdown from '@/views/screens/projectManagement/components/ChartDropdown.vue'

const props = defineProps({
    title: String,
    chartDropdownId: [String, Number],
    series: Array,
    type: String,
    updateChartDropdownId: Function,
    dropdownOptions: Array,
    chartWidth: {
        default: 617
    },
    chartOptions: Object
})

const _chartDropdown = computed({
    get() {
        return props.chartDropdownId
    },
    set(val) {
        props.updateChartDropdownId(val)
    }
})
</script>