<template>
    <Header :hasMembers="false">
        <template #filter>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two">PROJECT</span>
                <select @change="handleModules" v-model="selectedProjectId" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option :value="null" selected>Select</option>
                    <option 
                        v-for="project in getProjects"
                        :key="project.id"
                        :value="project.id"
                    >{{ project.title }}</option>
                </select>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two">MODULE</span>
                <select @change="handleProperty" v-model="selectedModuleId" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option :value="null" selected>Select</option>
                    <option 
                        v-for="module in modules"
                        :key="module.id"
                        :value="module.id"
                    >{{ module.title }}</option>
                </select>
            </div>
            
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two">P.SET</span>
                <select @change="handleParameter" v-model="selectedPropertyId" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option :value="null" selected>Select</option>
                    <option 
                        v-for="property in properties"
                        :key="property.id"
                        :value="property.id"
                    >{{ property.title }}</option>
                </select>
            </div>
            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two">PARAMETER</span>
                <select @change="setSelectedParameter" v-model="selectedParameterId" class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option :value="null" selected>Select</option>
                    <option 
                        v-for="parameter in parameters"
                        :key="parameter.id"
                        :value="parameter.id"
                    >{{ parameter.title }}</option>
                </select>
            </div>

            <div class="grid">
                <span class="fs-10 fw-800 text-secondary-two">TIME FILTER</span>
                <select class="fs-13 fw-600 w-auto border-0  -ml-1 focus-within:ring-0" style="width: fit-content;">
                    <option value="All task" selected>All task</option>
                    <option value="My task">My task</option>
                </select>
            </div>
        </template>
    </Header>
</template>

<script setup>
import Header from './Index.vue'
import useInstratBoard from '@/views/screens/projectManagement/services/useInstratBoard.js'
import { ref, computed } from 'vue'

const { 
    planData, 
    filteredLevelData, 
    selectedProjectId, 
    selectedModuleId, 
    selectedModule, 
    selectedPropertyId, 
    selectedParameterId, 
    selectedParameter,
    systemParameters,
    parameterValues
} = useInstratBoard()

const modules  = ref([])
const properties = ref([])
const parameters = ref([])

const getProjects = computed(() => planData.value.child)

const handleModules = async () => {
    let project   = getProjects.value.find(item => item.id == selectedProjectId.value)
    modules.value = project.child
}
const handleProperty = async () => {
    selectedModule.value = modules.value.find(item => item.id == selectedModuleId.value)
    filteredLevelData.value = selectedModule.value
    properties.value = selectedModule.value.properties
}
const handleParameter = async () => {
    selectedModule.value = modules.value.find(item => item.id == selectedModuleId.value)
    systemParameters.value = selectedModule.value.system_parameters
    parameterValues.value = selectedModule.value.parameter_values
    parameters.value = selectedModule.value.system_parameters.filter(item => item.property_id == selectedPropertyId.value)
}
const setSelectedParameter = () => {
    selectedParameter.value = parameters.value.find(item => item.id == selectedParameterId.value)
}
</script>