<template>
    <div>
        <Header/>
        <Row
            v-for="item in data"
            :key="item.id"
            :item="item"
            :settings="settings"
            :shared="shared"
            :offset="offset"
            @createTask="showTask"
            :hideCheckbox="checkboxInvisibility"
            :showConfigurationModal="showConfigurationModal"
            :handleSprintChange="handleSprintChange"
            :jiraForm="jiraForm"
            :sprints="sprints"
            :jiraDropdownOptions="jiraDropdownOptions"
            :versions="versions"
        />
    </div>
</template>

<script setup>
    import Header from './Header';
    import Row from './Row';
    import { ref, onMounted } from 'vue';
    import useMapping from '@/components/planning/integration/jira/useMapping.js'

    const props = defineProps({
        data: Array,
        sprints: Array,
        jiraForm: Object,
        settings: Object,
        showConfigurationModal: Function,
        showTask: Function,
        loadData: Function,
        handleSprintChange: Function,
        versions: Array
    });
    const offset = ref(0);
    const checkboxInvisibility = ref(false);
    const { loadIssue, jiraDropdownOptions} = useMapping()
    // const collectModules = (module) => {
    //     if(maxParameters.value < module.parameters.length) {
    //         maxParameters.value = module.parameters.length;
    //     }
    // }
    const shared = {
        module: {
            parameters: []
        }
    }
    onMounted(()=> {
        if(props.jiraForm && props.jiraForm.project_id &&  props.jiraForm.jira_connection_info) {
            loadIssue(props.jiraForm.project_id, props.jiraForm.jira_connection_info.projectId)
        }
    })
</script>

<style scoped>
    @import '/assets/css/planning.css';
</style>