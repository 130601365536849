import { ref } from "vue"
import JiraBoard from '@/views/screens/projectManagement/fragments/taskBoard/JiraBoard.vue'
import CompletionBoard from '@/views/screens/projectManagement/fragments/taskBoard/CompletionBoard.vue'
import InstratParameters from '@/views/screens/projectManagement/fragments/taskBoard/InstratParameters.vue'
import { filter, isEmpty, cloneDeep, get } from "lodash"
import { searchFilter, sorting, filterByGroupType, filterByShow, filterByTime } from '@/views/screens/projectManagement/services/useTaskBoardFilter.js'
import { dateCalculation } from "@/composable/timeline/dateCalculation.js"
import { getFlattenCollections } from '@/store/modules/global/services.js'
import { setMembers } from '@/views/screens/projectManagement/fragments/taskBoard/header/useHeader.js'
import { generateProgressHistory, getAvgMonthlyEfficiency, handleMonthlyEfficiency } from './calculation'
import axios from "axios"


const completionColumnTitle = ref([
    'To Do',
    'Behind Schedule',
    'On Track',
    'Good Progress',
    'Completed'
])
const originalLevelData = ref([])
const levelData = ref(null)
const { getProgress  } = dateCalculation()
const selectedModule = ref({})
const boardStyle = ref('CompletionBoard')
const boards = {
    CompletionBoard,
    JiraBoard,
    InstratParameters
}

export default function useTaskBoard() 
{
    const loadData = async (planning) => {
        let { data } = await axios.get(`project-management/plan/${planning.id}?timeline_view=1`)
        originalLevelData.value = data.data
        levelData.value = getLevelData(cloneDeep(originalLevelData.value))
    }

    const getLevelData = (data) => {
        if(!data) {
            return [];
        }
        const flatData = getFlattenCollections([data])
        selectedModule.value = flatData.find(item => item.i_am == 'module')
        let efficiencies = [];
        let levelData = flatData.map(item => {

            if(item.is_last_level) {
                // All assigned members monthly efficiency sum up;
                efficiencies = handleMonthlyEfficiency(getAssignedMembers(item, i => i.i_am == 'team_member'), {viewable: false, key: 'monthly_efficiency'});
            }
            item.selectedParameter    = get(selectedModule.value, 'system_parameters[0]') || {}
            item.completion           = getCompletion(item);
            item.totalCompletion      = getCompletion(item);
            item.progress             = getProgress(item);
            item.progressHistory      = generateProgressHistory(item);
            item.AvgPlanCapacity      = getAvgMonthlyEfficiency(efficiencies, item) || 0;            
            return item
        })

        let filteredData = sorting(levelData)
        filteredData = filterByGroupType(filteredData)
        filteredData = filterByShow(filteredData)
        filteredData = filterByTime(filteredData)
        filteredData = searchFilter(filteredData, 'title')

        setMembers(filteredData)
        return filteredData
    }

    const getSampleValue = (itemId, parameters) => {
        if(!parameters) return 0
        let selectedParameter = parameters.find(item => item.id === itemId)
        return selectedParameter?.sample_value ? selectedParameter?.sample_value : 0
    }

    const getCompletion = (item) => {
        if(!item.level) return 0
        if(!isEmpty(item.child)){
            let member = getAssignedMembers(item, (i) => i.i_am == 'team_member')
            let totalCompletion = 0
            member.forEach((item) => {
                totalCompletion += item.completion
            })
            return totalCompletion
        }

        return item.completion
    }

    const getAssignedMembers = (item, cb) => {
        let flatten = ({ child = [], ...rest }) => [rest, ...child.flatMap(flatten)];
        return filter(flatten(item), cb)
    }
    
    const handleCompletion = (item, cb) => {
        if(item.is_last_level, isEmpty(item.child)){
            if((item.totalCompletion + 5) > 100) {
                item.totalCompletion = 0
                item.completion = 0
                _updateCompletion(item, cb)
                return
            }
            item.totalCompletion += 5
            item.completion += 5
            item.progress = getProgress(item);
            _updateCompletion(item, cb)
        }
    }

    const _updateCompletion = (item, cb) => {
        const { id, scenario_id, completion } = item
        axios.post(`projects/scenarios/${scenario_id}/update/${id}`, {completion})
        cb()
    }

    return {
        boards,
        boardStyle,
        getLevelData,
        getSampleValue,
        getCompletion,
        getAssignedMembers,
        handleCompletion,
        loadData,
        completionColumnTitle,
        selectedModule,
        originalLevelData,
        levelData
    }
}