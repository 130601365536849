<template>
    <div class="bg-primary-three">
        <div class="flex items-center border-b border-secondary-four h-6">
            <div
                v-for="({year, count}, index) in timeline.years"
                :key="index"
                :style="{width: `${count*100}px`}"
                class="h-full flex items-center justify-center border-r border-secondary-four fs-10"
            >{{ year }}</div>
        </div>
        <div class="flex items-center border-b border-secondary-four h-5">
            <div v-for="(quatre, index) in timeline.quatre" :key="index" class="w-quatre h-full flex items-center justify-center border-r border-secondary-four fs-10">{{ quatre.substring(quatre.indexOf("Q")) }}</div>
        </div>
        <div class="flex items-center border-b h-5">
            <div
                v-for="({month}, index) in timeline.months"
                :key="index"  class="w-month text-center fs-10"
                :class="{'border-r border-secondary-four h-full' : isLast(index)}"
            > {{ month }} </div>
        </div>
    </div>
</template>

<script setup>
    import { inject } from 'vue';
    const timeline = inject('timeline');
    const isLast = (index) => {
      return index === timeline.value.months.length - 1
    }
</script>

<style scoped>
    @import '../../../assets/css/planning.css';
</style>
