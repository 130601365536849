<template>
    <div class="w-full">
        <SelectComponent
            :items="items"
            @onChange="onChange"
        >
            <template v-slot:trigger="{ handleToggle }">
                <div class="px-2" :class="{'flex justify-between': title}">
                   <span v-if="title">{{ title }}</span>
                    <ChevronDownIcon @click.native="handleToggle" class="w-5 h-5 cursor-pointer opacity-50" />
                </div>
            </template>
            <template v-slot:option="{ item, onSelect }">
                <slot :option="item" :onSelect="onSelect">
                    <div @click="onSelect" class="flex items-center justify-between w-full hover:bg-secondary-three pr-4">
                        <span class="text-secondary-six hover:text-primary-one  block px-4 py-2 text-sm">{{ item.title }}</span>
                        <RightIcon v-if="title == item.title" class="w-3 h-3 text-green-500" />
                    </div>
                </slot>
                </template>
        </SelectComponent>
    </div>
</template>

<script setup>
    import SelectComponent from '@/elements/inputs/SelectComponent.vue';
    const props = defineProps({
        value: {
            required: false
        },
        title: {
            type: String,
        },
        idKey: {
            default: 'id'
        },
        items: Array
    });
    const emit = defineEmits(['input'])
    const onChange = (item) => {
        emit('input', parseInt(item[props.idKey]) || null)
    }
</script>