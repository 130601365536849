<template>
     <div class="mt-2 bg-white shadow-two p-3 rounded">
        <div class="flex justify-between">
          <h4 class="fs-12 fw-500 text-primary-one">Levels</h4>
          <h4 class="fs-12 fw-500 text-primary-one">Definition</h4>
        </div>
        <div v-click-outside="hidePicker">
          <div v-for="(definitionStyle, index) in styles" :key="index" class="flex justify-between items-center mt-2" :class="{'mt-4': index > 0}" >
            <div
                @click.prevent="openColorPicker(definitionStyle, index)"
                class="range-section relative fs-10 text-primary-three shadow-two rounded-sm flex justify-center items-center cursor-pointer"
                :style="{'background': definitionStyle.bg}"
            >
                {{ definitionStyle.range }}
                <div  class="absolute top-3 right-0 z-50" v-if="index == currentIndex && showPicker">
                    <div class="flex flex-col items-center justify-center mt-3">
                        <color-panel @change="value => definitionStyle.bg = value"></color-panel>
                    </div>
                </div>
            </div>
            <div
                class="range-section fs-10 shadow-two text-primary-three rounded-sm flex justify-center items-center ml-5"
                :style="{'background': definitionStyle.bg}">
              <input type="text" class="bg-transparent definition-section text-center" v-model="definitionStyle.definition" />
            </div>
          </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    defineProps({
        styles: Array
    });
    const showPicker = ref(false);
    const currentIndex = ref(0);

    const hidePicker = () => {
      showPicker.value = false;
    //   updateTemplate(this.template);
    }
    defineExpose({
        showPicker
    });
    const openColorPicker = (obj, index) => {
        currentIndex.value = index;
        showPicker.value = !showPicker.value;
    }

</script>

<style scoped>
.range-section {
    width: 64px;
    height: 20px;
}
</style>