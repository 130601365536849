<template>
    <div class="track-box relative" :title="getValue">
        <span v-if="isVisible" class="fs-10 w-3 absolute text-right" style="top: -7px; left: -20px; }">
            {{ getValue }}
        </span>
        <input 
            class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" 
            min="0" 
            max="10" 
            step=".1"
            type="range"
            :disabled='disabled'
            v-model="getValue"
            @mousedown="isVisible = true"
            @mouseup="() => {
                $emit('onMouseUp', getValue)
                isVisible = false
            }"
        />
        <div 
            class="progress-track absolute h-full left-0 top-0 bg-primary-five" 
            :style="{width: `${getValue * 10}%`}"
        ></div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    
    const isVisible = ref(false)
    const props = defineProps({
        value: [Number, String],
        disabled: {
            type: Boolean,
            default: true
        }
    })
    const getValue = ref(props.value)
</script>

<style scoped>
.range {
    outline: none;
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    background: #e2e2e2;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 10px;
    background: #02a7fe;
    cursor: pointer;
}

.range::-moz-range-thumb {
    width: 20px;
    height: 10px;
    background: #02a7fe;
    cursor: pointer;
}

.range.disabled::-webkit-slider-thumb,
.range.disabled::-moz-range-thumb
{
    -webkit-appearance: none;
    appearance: none;
    width: 0px;
    height: 0px;
    pointer-events: none;
}

.progress-track{
    background: #02a7fe;
    height: 2px;
}
</style>