<template>
    <div :class="itemWrapperClass">
        <p>{{title}}</p>
        <div class="flex items-center gap-x-2.5">
            <div @click="focusInput" class="w-14 h-7 cursor-text flex items-center justify-center bg-primary-three text-primary-one rounded-sm fs-12 fw-400 border border-secondary-four">
                <input
                    ref="input"
                    max="100"
                    min="1"
                    type="text"
                    v-model="item[model_key]"
                    class="w-5 h-full text-right bg-transparent"
                    @input="updatePlanDebounce(item.id, getPlanUpdateData(item))"
                >
                <span v-if="showPercent">%</span>
            </div>
            <LockIcon v-if="isLocked" class="w-4 h-4 opacity-40" />
            <UnlockIcon  v-else class="w-4 h-4" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const itemWrapperClass = 'flex items-center justify-between'
const props = defineProps({
    title: String,
    isLocked: Boolean,
    model_key: String,
    item: Object,
    showPercent: {
        default: true
    },
    updatePlanDebounce: Function
})
const input = ref()
const getPlanUpdateData = ({project_id, title, description, settings, dedication, weekend_holiday_adjustment, working_hours, materials_equipment, estimated_billable_hours, avg_hourly_rate}) => {
    return {
        project_id, title, description, settings, dedication, weekend_holiday_adjustment, working_hours, materials_equipment, estimated_billable_hours, avg_hourly_rate
    }
}
const focusInput = () => input.value.focus()
</script>