import axios from 'axios'
import { ref } from 'vue';
import router from "@/routes/routes.js";
import { pickUniqueItems } from '@/plugins/lodash.js'
import { sumBy, meanBy, isEmpty, last, takeRight } from 'lodash'

export const transformTeamData = (items) => {
    const again = (item) => {
        if (!isEmpty(item.child)) {
            let children = item.child.map((item) => again(item));
            item.totalEfficiency = sumBy(children, (item) => item.totalEfficiency || 0);
            item.avgHourlyRate = meanBy(children, (item) => (Number(item.avg_hourly_rate) || 0)) || 0
        }

        if (item.i_am == "team_member") {
            item.totalEfficiency = item.efficiency || 0;
        }

        return item;
    };
    takeRight(last(items).child, 2).forEach(member => member.isLast = true);
    
    let xx = items.map((item) => again(item));
    console.log(xx, '=====xxx')
    return xx;
};

const teams = ref([]);
export const useTeam = () => {
    const route = router.currentRoute;
    const systemUsers = ref([])

    const getTeamsByProject = async (projectId = null) => {
        let localProjectId = projectId ?? route.params.projectId;
        const data = await axios.get(`/projects/resource-teams/${localProjectId}`).then(({ data }) => transformTeamData(data.data));
        if (data) {
            teams.value = data
        }
        return data;
    }

    const createTeam = async (data) => {
        data.workspace_id = route.params.workspaceId
        await axios.post('projects/resource-teams/create', data);
        return await getTeamsByProject();
    }
    const updateTeam = async (data) => {
        data.workspace_id = route.params.workspaceId
        axios.post(`projects/resource-teams/${data.id}/update`, data)
    }
    const removeTeam = async (item) => {
        if (confirm('are you sure want to delete this?')) {
            await axios.delete(`projects/resource-teams/${item.id}`)
            getTeamsByProject()
        }
    }
    const updateTeamMember = async (item) => {
        await axios.post(`projects/resource-teams/members/${item.id}/update`, item)
    }
    //bulk-update
    const updateTeamMembersEfficiency = async (payload) => {
        await axios.post(`project-management/resources/bulk-update`, payload)
    }
    const removeTeamMember = async (id) => {
        await axios.delete(`projects/resource-teams/members/${id}`)
    }

    const sync = () => {
        teams.value = transformTeamData(teams.value)
    }

    const createTeamMember = async (data) => {
        axios.post('/projects/resource-teams/members/create', data);
        return await getTeamsByProject();
    }

    const getSystemUser = async (workspaceId) => {
        try {
            let { data } = await _fetchSystemUser(workspaceId)
            if (data.status) systemUsers.value = pickUniqueItems(data.data, 'w_user_id')
        } catch (err) {
            throw new Error(err.message)
        }
    }

    const _fetchSystemUser = workspaceId => axios.get(`workspaces/${workspaceId}/users`)

    return {
        teams,
        createTeamMember,
        updateTeamMember,
        updateTeamMembersEfficiency,
        removeTeamMember,
        updateTeam,
        removeTeam,
        sync,
        createTeam,
        getTeamsByProject,
        getSystemUser,
        systemUsers,
        transformTeamData
    }
}