<template>
    <div v-click-outside="() => toggleDropdown = false" class="relative w-full fs-14 fw-500 text-color-616161 border border-secondary-five px-4 py-2 rounded pl-8">
        <div @click="toggleDropdown = !toggleDropdown" class="selected flex gap-2 items-center cursor-pointer relative">
            <img v-if="mapItem.jira.icon" :src="mapItem.jira.icon" :alt="mapItem.jira.key" class="absolute -left-6 top-1/2 transform -translate-y-1/2">
            {{ mapItem.jira.key }}
        </div>
        <div v-if="toggleDropdown" class="absolute z-10 shadow border min-h-44 overflow-y-auto flex flex-col justify-start top-full left-0 bg-white rounded-b">
            <div 
                v-for="(option, i) in mapItem.jira.options"
                :key="i"
                :value="option.key"
                class="flex gap-2 items-center w-full px-3 py-1 cursor-pointer hover:bg-black hover:bg-opacity-5"
                @click="handleDropdown(mapItem, option, index)"
            >
                <img v-if="option.icon" :src="option.icon" :alt="option.key">
                {{ !isEmpty(option.key) ? option.key : 'Nothing Selected' }}
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue'
    import { isEmpty } from 'lodash'
    import useMapping from '@/components/planning/integration/jira/useMapping.js'

    const { cleanBottomMap } = useMapping()
    const props = defineProps({
        index: [Number, String],
        mapItem: Object
    })


    const selectedItem = ref({
        key: "Nothing Selected",
        icon: null
    })

    const toggleDropdown = ref(false)

    const handleDropdown = (mapItem, option, index) => {
        toggleDropdown.value = false
        mapItem.jira.id = option.id
        mapItem.jira.key = option.key
        mapItem.jira.hierarchyLevel = option.hierarchyLevel
        mapItem.jira.icon = option.icon

        selectedItem.value.key = option.key
        selectedItem.value.icon = option.icon

        cleanBottomMap(index)
    }
</script>

<style scoped>
    .shadow{
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
</style>