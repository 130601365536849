<template>
    <div>
        <div 
            class="flex items-end overflow-x-auto" 
            style="height: 48px;"
            :style="{gap: (parameterValues.length > 21) ? '2px' : '2px'}"
        >
            <span
                v-for="(item, index) in getChartData"
                :key="index"
                :title="item"
                class="bg-primary-four rounded-t block" 
                style="width: 14px;"
                :class="(getHighestValue == item) ? 'opacity-100' : 'opacity-60'"
                :style="{height: `${item * 10}%`}"
            ></span>
        </div>
        <div class="flex items-center gap-1">
            <span class="fs-18 fw-800 text-primary-one">{{ getHighestValue }}</span>                     
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    import useInstratBoard from '@/views/screens/projectManagement/services/useInstratBoard.js'

    const { systemParameters, parameterValues, getRangeValue } = useInstratBoard()
    const props = defineProps({
        item: Object
    })

    const getHighestValue = computed(() => {
        let values = getChartData.value.flat(Infinity)
        values = values.map(item => Number(item))
        let maxValue = Math.max(...values)
        return  maxValue ? Number(maxValue).toFixed(2) : 0.00
    })

    const getChartData = computed(() => {
        let data = systemParameters.value.map(parameter => {
            let sampleValue = getRangeValue(props.item, parameter.id, parameterValues.value)    
            return sampleValue
        })
        return data
    })
</script>