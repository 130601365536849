<template>
    <div style="width: 680px">
        <Header title="Add Module" />
        <main class="p-6">
            <InputSelect
                label="Scenario name"
                default-option="Select one"
                :options="scenarios"
                v-model="form.scenario_id"
            />
            <InputSelect
                label="Module name"
                default-option="Select one"
                :options="modules"
                v-model="form.module_id"
                @change="onChangeModule"
            />
            <div v-for="(item, i) in form.parameters" :key="i">
                <div class="flex">
                    <p class="fs-14 fw-500 pb-2">Parameter {{ i + 1 }}</p>
                    <button @click="remove(i)" class="ml-auto" style="rotate: 45deg">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#8D8D8D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square"></path>
                            <path d="M6.875 10H13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M10 6.875V13.125" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </button>
                </div>
                <div class="flex gap-2">
                    <InputSelect
                        max-width="190px"
                        default-option="Select Parameter Type"
                        v-model="item.type"
                        :options="parameterTypes"
                        @change="switchStyles(item)"
                    />
                    <InputSelect
                        max-width="200px"
                        :class="{'invisible': !item.type}"
                        default-option="Select Property"
                        :options="properties"
                        v-model="item.property_id"
                        @change="getParameterByProperty"
                    />
                    <InputSelect
                        max-width="190px"
                        :class="{'invisible': !item.type}"
                        default-option="Select Parameter"
                        v-model="item.id"
                        :options="parameters"
                    />
                    <div :class="{'invisible': !item.type}" class="relative items-end ml-auto">
                        <svg v-if="!item.colorPlate" @click.stop="item.colorPlate = true" class="mt-3 relative items-end" width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="11" width="10" height="5" fill="#19A77C"/>
                            <rect width="10" height="5" transform="matrix(1 0 0 -1 11 11)" fill="#0085FF"/>
                            <rect width="10" height="5" transform="matrix(-1 0 0 1 10 0)" fill="#D6D6D6"/>
                            <rect x="10" y="11" width="10" height="5" transform="rotate(-180 10 11)" fill="#FE5656"/>
                        </svg>
                        <svg v-else @click="item.colorPlate = false" class="flex-shrink-0 mt-2" style="rotate: 45deg" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.5 8H13.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            <path d="M8 2.5V13.5" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                        <div class="absolute right-0 z-50" v-if="item.colorPlate">
                            <StyleLogic :styles="item.styles" />
                        </div>
                    </div>
                </div>
            </div>
            <P style="flex-basis: 100%;" class="fs-14 inline-block font-medium text-primary-four" @click="addNewItem">+ Add Parameter</P>
            <Footer
                    :submit="handleModuleConfigure"
                    :cancel="() => $emit('close')"
                />
        </main>
    </div>
</template>

<script setup>
    import InputSelect from '@/components/planning/components/InputSelect.vue';
    import Header from '@/components/planning/components/ModalHeader.vue';
    import Footer from '@/components/planning/components/ModalFooter.vue';
    import { ref, onMounted } from 'vue';
    import StyleLogic from '@/components/planning/components/StyleLogic.vue';
    import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js';
    import axios from 'axios';
    import { cloneDeep, isEmpty } from 'lodash'
    const props = defineProps({
        project: {
            type: Object,
            required: false
        },
        module: {
            type: Object,
            required: false
        },
        updateColumnWidth: Function
    });
    const styles = ref([
        { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
        { range: '2-4', bg: '#ABA62B', definition: 'Low' },
        { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
        { range: '6-8', bg: '#2BAD7E', definition: 'High' },
        { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
    ]);

    const styles2 = ref([
            {range:"0-2.5", bg:"#AD2B81", definition:"Yes"},
            {range:"2.5-5", bg:"#ABA62B", definition:"Unsure"},
            {range:"5-7.5", bg:"#8D8D8D", definition:"No"},
            {range:"7.5-10", bg:"#2BAD7E", definition:"NA"}
    ]);
    const parameterTypes = [
        {id: 'RegularParameter', title: 'Regular'},
        {id: 'DynamicFieldParameter', title: 'Dynamic Field'},
        {id: 'YesNoStyleParameter', title: 'Yes/No Style'}
    ];
    const switchStyles = (item) => {
        item['styles'] = item.type != 'YesNoStyleParameter' ?
                        cloneDeep(styles.value) :
                        cloneDeep(styles2.value);
    }
    const emits = defineEmits(['close', 'done']);
    const { getScenariosByProject, scenarios, modules, getPropertiesByModule, properties, getParameterByProperty, parameters } = useProjectData();

    const form = ref({
        scenario_id: null,
        module_id: null,
        parameters: [
            {
                property_id: null,
                id: null,
                type: null,
                colorPlate: false,
            }
        ]
    });

    const addNewItem = () => {
        form.value.parameters.push({
            id: null,
            parameter_id: null,
            type: null,
            colorPlate: false
        })
    }
    const remove = (index) => {
        form.value.parameters.splice(index, 1);
    }
    const onChangeModule = (value) => {
        getPropertiesByModule(value);
        form.value.parameters = [{
            id: null,
            parameter_id: null,
            type: null,
            colorPlate: false
        }]
    }
    const handleModuleConfigure = async () => {
        if(!isEmpty(props.module)) {
            updateModule();
            return;
        }
        let data = {
            ...form.value,
            implementation_planning_project_id: props.project.id,
            settings: {
                project: {
                    id: props.project.project_id
                }
            }
        }
        props.updateColumnWidth('parameterColumn', form.value.parameters.length * 100 + 200, true);
        await axios.post('planning/projects/modules/store', data).then(({data}) => data.data);
        emits('done');
    }

    const updateModule = async () => {
        props.updateColumnWidth('parameterColumn', form.value.parameters.length * 100 + 200, true);
        await axios.post(`/planning/projects/modules/${props.module.id}/update`, form.value);
        emits('done');
    }

    onMounted(async () => {
        if(!isEmpty(props.project)) {
            await getScenariosByProject(props.project.project_id);
            return;
       }

       if(!isEmpty(props.module)) {
            await getScenariosByProject(props.module.settings.project.id);
            await getPropertiesByModule(props.module.module_id);

            let data = {
                module_id: props.module.module_id,
                scenario_id: props.module.scenario_id,
                parameters: props.module.parameters,
            }
            props.module.parameters.forEach(item => {
                getParameterByProperty(item.property_id);
            });
            form.value = data;
       }
    })

</script>

<style scoped>
    @import './planning.css';
</style>
